import { useSelector } from "react-redux";
import { selectUserInfo, selectCurrencySymbol, selectCurrency, CURRENCY, WalletData } from "../features/user/user";
import { BLOCKCHAIN, Token } from "../models/models";
import TypeTokenRow from "./TypeTokenRow";
import WalletsData from "./walletsData";
import { useTranslation } from "react-i18next";

type RowTableProps = {
    index: number
    wallet?: string;
    token: Token;
    iconBlockchain: any;
}

const getUrl = (token: Token, wallet?: string): string => {

    const address = wallet ? wallet : token.address;

    return address ?
        token.blockchain === BLOCKCHAIN.ETHEREUM ? 'https://etherscan.io/address/' + address :
            token.blockchain === BLOCKCHAIN.POLYGON ? 'https://polygonscan.com/address/' + address :
                'https://bscscan.com/address/' + address : '';

}

function RowTokenTable({ index, wallet, token, iconBlockchain }: RowTableProps) {


    const userInfo = useSelector(selectUserInfo);
    const currency = useSelector(selectCurrency);
    const currencySymbol = useSelector(selectCurrencySymbol);


    const { t } = useTranslation('common');

    const getAliasWallet = (wallet?: string) => {

        const wInfo = userInfo.walletsData?.find((wData: WalletData) => wData.wallet === wallet);
        return wInfo?.alias ? wInfo.alias : `${wallet?.slice(0, 6)}...${wallet?.slice(-4)}`;
    }

    const getLiteral = (pathLiteral: string): string => {

        return t(pathLiteral) ? t(pathLiteral) : '';
    }

    return (
        <>
            <td className="">
                <span data-toggle="tooltip" data-placement="top" title={token.wallet}>{getAliasWallet(token?.wallet)} &nbsp;&nbsp; </span>
                {token?.wallet && (<i className="bi bi-box-arrow-up-right pointer" data-toggle="tooltip" data-placement="top" title={getLiteral('views.balance.linkButton')} onClick={() => window.open(getUrl(token, token.wallet), '_blank')}></i>)}&nbsp;&nbsp;
                {token?.wallet && (<i className="bi bi-clipboard-check-fill pointer" data-toggle="tooltip" data-placement="top" title={getLiteral('views.balance.copyWalletButton')} onClick={() => navigator.clipboard.writeText(getUrl(token, token.wallet))}></i>)}
            </td>
            <td className="">
                <span className="btn-image-md">
                    {/* {index}) - */}
                    <img className="m-1" src={iconBlockchain(token?.blockchain)} alt="Imagen" />
                </span>
            </td>
            <td className="" style={{ maxWidth: '50px' }} data-toggle="tooltip" data-placement="top" title={token.symbol} >
                {token.symbol?.substring(0, 5)}{token.symbol.length > 5 ? '...' : ''}
            </td>
            <td className="" style={{ maxWidth: '50px' }} data-toggle="tooltip" data-placement="top" title={token.name} >
                {token.name?.substring(0, 8)}{token.name.length > 10 ? '...' : ''}
            </td>
            <td className="" style={{ maxWidth: '50px' }} data-toggle="tooltip" data-placement="top" title={token.address} >
                {token.address && token.address.length > 20
                    ? `${token.address?.slice(0, 6)}. . .${token.address?.slice(-4)}`
                    : '-'}&nbsp;&nbsp;
                {token.address && (<i className="bi bi-box-arrow-up-right pointer" data-toggle="tooltip" data-placement="top" title={getLiteral('views.balance.linkButton')} onClick={() => window.open(getUrl(token), '_blank')}></i>)}&nbsp;&nbsp;
                {token.address && (<i className="bi bi-clipboard-check-fill pointer" data-toggle="tooltip" data-placement="top" title={getLiteral('views.balance.copyButton')} onClick={() => navigator.clipboard.writeText(getUrl(token, token.wallet))}></i>)}
            </td>
            <TypeTokenRow id={token._id} trustLevel={token.trustLevel} userType={userInfo.userType} setIdToken={() => { }} setShowTokenModal={() => { }}></TypeTokenRow>
            <td className="balances-amounts">
                {token.balance ? token.balance.toLocaleString('es-ES', {
                    minimumFractionDigits: 6,
                    maximumFractionDigits: 6
                }) : '-'}
            </td>
            <td className="balances-amounts">
                {token?.balanceFiat || token?.balanceFiatEur ? String(currency === CURRENCY.USD ? token.balanceFiat?.toLocaleString('es-ES', {
                    minimumFractionDigits: 6,
                    maximumFractionDigits: 6
                }) + ' ' + currencySymbol : token.balanceFiatEur?.toLocaleString('es-ES', {
                    minimumFractionDigits: 6,
                    maximumFractionDigits: 6
                }) + ' ' + currencySymbol) : '-'}&nbsp;
            </td>
        </>
    )
}

export default RowTokenTable;