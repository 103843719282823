import BalanceTable from "../components/BalanceTable";
import { BLOCKCHAIN } from "../models/models";
import { useNavigate, useParams } from "react-router-dom";
import WalletsData from "../components/walletsData";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { selectCollapsed } from "../features/user/user";
import { useSelector } from "react-redux";


function InquiryDetail () {

    const { inquiryId } = useParams<{ inquiryId: string }>();
    const navigate = useNavigate();
    const { t } = useTranslation('common');
	const collapsed = useSelector(selectCollapsed);
    

    const iconBlockchain = (blockchain?: BLOCKCHAIN) => {
        return blockchain === BLOCKCHAIN.ETHEREUM ? './assets/img/blockchains/ethereum.png' :
            blockchain === BLOCKCHAIN.POLYGON ? './assets/img/blockchains/polygon.png' : './assets/img/blockchains/binance.png'
    }

    return (
    <>
        <div id="dashboard" className={collapsed ? " d-grid align-content-start vh-100 pt-4 px-4 pb-1 overflow-auto main-content-collapsed" : " d-grid align-content-start vh-100 pt-4 px-4 pb-1 overflow-auto main-content-expanded"  }>
            <WalletsData setInquiryId={() => { }} />
            <div className="d-flex justify-content-start m-2">
                    <button type="button" className="btn btn-primary btn-sm px-3 text-nowrap" onClick={() => { navigate('/historic') }} aria-label="Details">
                        <i className="bi bi-arrow-bar-left"></i>&nbsp;&nbsp;{t('views.inquiryDetail.historic')}
                    </button>
                    </div>
            <div className="mt-5">
            {inquiryId && (<BalanceTable inquiryId={inquiryId} historic={true} setShow={() => {}} iconBlockchain={iconBlockchain} />)}
            </div>
        </div>
    </>
    )
}

export default InquiryDetail;