import { useEffect, useState } from "react";
import { Balance, BLOCKCHAIN, Inquiry } from "../models/models"
import { useAppDispatch } from "../app/hooks";
import { selectJWT, setError, setInquiryUpdated, setSuccess } from "../features/user/user";
import { useTranslation } from "react-i18next";
import { Form, Modal } from "react-bootstrap";
import BalanceService from "../services/balance.service";
import BalanceLoader from "../loaders/BalanceLoader";

export type WalletSelected = {
    wallet: string,
    selected: boolean
}

type FiltersHistoricProps = {
    iconBlockchain: any
    inquiries: Inquiry[];
    setHistoricInquiriesFiltered: any;
    wallets: WalletSelected[],
    enableAllFilters: boolean,
    setEnableAllFilters: any,
    jwt : string
}

type FilterHistoricValues = {
    dateFrom?: Date,
    dateTo?: Date,
    ethereum: boolean;
    bsc: boolean;
    polygon: boolean;
    wallets: WalletSelected[];
    text: string;
}

function FiltersHistoric({ iconBlockchain, inquiries, setHistoricInquiriesFiltered, wallets, enableAllFilters, setEnableAllFilters, jwt }: FiltersHistoricProps) {


    const [filterValues, setFilterValues] = useState<FilterHistoricValues>({ ethereum: true, bsc: true, polygon: true, wallets, text: '' });
    const dispatch = useAppDispatch();
    const { t } = useTranslation('common');
    const [allWallets, setAllWallets] = useState<boolean>(true);
    const [allBlockchains, setAllBlockchains] = useState<boolean>(true);
    const [loadingRegenerate, setLoadingRegenerate ] = useState<boolean>(false);

    const handleWallets = (wallet: WalletSelected) => {
        const walletsCopy: WalletSelected[] = [];

        filterValues.wallets.forEach(wall => {
            if (wall.wallet === wallet.wallet) {
                walletsCopy.push({ wallet: wall.wallet, selected: !wall.selected })
            }
            else {
                walletsCopy.push(wall);
            }
        })

        setFilterValues({ ...filterValues, wallets: walletsCopy });
        // setWallets(walletsCopy);
    }

    const changeFilterWallets = (e: React.SyntheticEvent) => {
        const walletsCopy: WalletSelected[] = [];

        filterValues.wallets.forEach(wall => {
            walletsCopy.push({ wallet: wall.wallet, selected: !allWallets })
        })

        setFilterValues({ ...filterValues, wallets: walletsCopy });
        setAllWallets(!allWallets);
    }

    const changeFilterBlockchains = (e: React.SyntheticEvent) => {
        setFilterValues({ ...filterValues, ethereum: !allBlockchains, bsc: !allBlockchains, polygon: !allBlockchains });
        setAllBlockchains(!allBlockchains);
    }

    const handleDate = (dateFrom?: string, dateTo?: string) => {

        if (dateFrom && dateTo) { setFilterValues({ ...filterValues, dateFrom: new Date(dateFrom), dateTo: new Date(dateTo) }) }
        if (dateFrom) { setFilterValues({ ...filterValues, dateFrom: new Date(dateFrom) }) }
        if (dateTo) { setFilterValues({ ...filterValues, dateTo: new Date(dateTo) }) }
    }

    const regenerateCertificates = async () => {

        setLoadingRegenerate(true)
        const responses = inquiries.map( async (inquiry : Inquiry) => {
            try {
                const response  = await BalanceService.regenerateBalance({inquiryId : inquiry._id}, jwt);
                dispatch(setInquiryUpdated({id : inquiry._id,updated : response.updatedAt, balances : response.balances }))
            } catch (error) {
                return {}
            }
        })

        const finished = await Promise.all(responses);

        if(finished){
            setLoadingRegenerate(false);
            dispatch(setSuccess({ title: "Balances regenerados correctamente", desc: 'Se han actualizado los balances con los nuevos valores de confianza' }));
        }

    }


    useEffect(() => {


        const filterInquiries = (inquiries: Inquiry[]) => {

            const inquiriesFiltered: Inquiry[] = []
            inquiries.forEach((inquiry: Inquiry) => {

                if (filterValues.text !== '' && !inquiry.alias?.toUpperCase()?.includes(filterValues.text?.toUpperCase())) {
                    return false;
                }

                if (!filterValues.wallets.find(wall => inquiry.configuration.wallets.includes(wall.wallet) && wall.selected)) {
                    return false;
                }


                if (!filterValues.ethereum && !filterValues.polygon && !filterValues.bsc) {
                    return;
                }

                if (filterValues.dateFrom && new Date(inquiry.configuration.dateSelected) <= filterValues.dateFrom) {
                    return;
                }

                if (filterValues.dateTo && filterValues.dateTo <= new Date(inquiry.configuration.dateSelected)) {
                    return;
                }

                const allBlockchainsSelected = filterValues.ethereum && filterValues.polygon && filterValues.bsc;

                // Si todas las blockchains están seleccionadas, agregar la consulta y continuar
                if (allBlockchainsSelected) {
                    inquiriesFiltered.push(inquiry);
                    return;
                }

                if (filterValues.ethereum && !inquiry.configuration?.blockchains?.includes(BLOCKCHAIN.ETHEREUM)) {
                    return false;
                }
                if (filterValues.polygon && !inquiry.configuration?.blockchains?.includes(BLOCKCHAIN.POLYGON)) {
                    return false;
                }
                if (filterValues.bsc && !inquiry.configuration?.blockchains?.includes(BLOCKCHAIN.BSC)) {
                    return false;
                }


                inquiriesFiltered.push(inquiry);
            });

            return inquiriesFiltered;
        }

        const enableFilters = () => {
            const walletsCopy: WalletSelected[] = [];

            filterValues.wallets.forEach(wall => {
                walletsCopy.push({ wallet: wall.wallet, selected: true })
            })

            setFilterValues({ ethereum: true, bsc: true, polygon: true, wallets: walletsCopy, text: '' });
        }

        const disableAllFilters = () => {
            const walletsCopy: WalletSelected[] = [];

            filterValues.wallets.forEach(wall => {
                walletsCopy.push({ wallet: wall.wallet, selected: false })
            })

            setFilterValues({ ethereum: false, bsc: false, polygon: false, wallets: walletsCopy, text: '' });
        }

        try {
            // console.log("Enable : ", enableAllFilters);
            if (enableAllFilters) {
                // enableFilters();
                // setEnableAllFilters(false);
            }
            else if (inquiries) {
                // disableAllFilters();
                // console.log(inquiries);
                // getWallets(inquiries);
                const inquiriesFiltered = filterInquiries(inquiries);
                // console.log(inquiriesFiltered);
                setHistoricInquiriesFiltered(inquiriesFiltered)
                // console.log("inquiries FILTERED: ", inquiriesFiltered);
            }
        } catch (error) {
            dispatch(setError({ title: 'Historic error', desc: 'Error trying apply your filters' }));
        }


    }, [enableAllFilters, filterValues, dispatch, inquiries, setHistoricInquiriesFiltered, allBlockchains, allWallets]);

    return (<>
        <div className="row px-1 py-4 rounded-top-4 mt-auto text-light-emphasis bg-light-subtle back-filters" >
            <div className="mt-2 col-12 d-flex justify-content-center">
                <span className="h2 text-nowrap  align-middle-lg ">{t('components.filtersHistoric.selectFilter')}</span>
            </div>
            <div className="row mt-4 row-gap-3 me-1 " style={{ maxWidth: '100%' }} >
                <div className="row d-flex justify-content-center align-items-center" >


                    <div className="col-1 d-flex justify-content-start">
                        <span className="m-2"><h5>{t('views.historicView.wallets')}</h5>&nbsp;&nbsp;&nbsp;</span>
                        {/* <button onClick={() => setEnableAllFilters(true)} className="btn btn-primary ms-3 px-3 text-nowrap" aria-current="page">
                            <span className="m-1">{t('views.historicView.selectWallets')}</span>
                        </button> */}
                        {/* <div className="form-check form-switch form-check-reverse text-nowrap m-2">
                            <input className="form-check-input " type="checkbox" role="switch" onClick={() => { setAllWallets(!allWallets) }} checked={allWallets} />
                            <label className="form-check-label" >{t('views.historicView.selectWallets')}{allWallets}</label>
                        </div> */}
                        {/* <Form>
                        <Form.Group  className="ms-3 d-flex" onChange={(e) => changeFilterWallets(e)}>
                            <Form.Label>{t('views.historicView.selectWallets')}</Form.Label>&nbsp;&nbsp;&nbsp;
                            <Form.Check type="switch" checked={allWallets}  />
                        </Form.Group>
                        <Form>
                        <Form.Group  className="ms-3 d-flex" onChange={(e) => changeFilterBlockchains(e)}>
                            <Form.Label >{t('views.historicView.selectBlockchains')}</Form.Label>&nbsp;&nbsp;&nbsp;
                            <Form.Check type="switch" checked={allBlockchains}  />
                        </Form.Group>
                        </Form>
                        </Form>
                         */}
                    </div>

                    <div className="col-11 justify-content-center" style={{ maxHeight: '150px', overflow: 'auto' }} >
                        {filterValues.wallets?.map((walletChoosen: WalletSelected, index: number) => (
                            (walletChoosen.wallet && (<div key={index} onClick={() => { handleWallets(walletChoosen) }}
                                className={walletChoosen.selected ? "m-2 btn btn-primary btn-wallets-2 w-100 my-1 text-center selected" : "m-2 btn btn-light btn-wallets-2 w-100 my-1 text-center selected"}>
                                {walletChoosen.wallet?.length > 20
                                    ? `${walletChoosen.wallet?.slice(0, 6)}...${walletChoosen.wallet?.slice(-6)}`
                                    : walletChoosen.wallet}
                            </div>))
                        ))}

                    </div>

                </div>
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-1 d-flex justify-content-start">
                        <span className="m-2"><h5>{t('views.historicView.blockchains')}</h5>&nbsp;&nbsp;&nbsp;</span>
                        <>

                        </>

                    </div>
                    <div className="col-11 d-flex justify-content-center ">
                        <div className={"" + (filterValues.ethereum ? "m-2 btn btn-primary btn-bcs selected" : "m-2 btn btn-light btn-bcs text-center selected")}
                            onClick={() => setFilterValues({ ...filterValues, ethereum: !filterValues.ethereum })}>
                            <span className="btn-image-md">
                                <img className="m-1" src={iconBlockchain(BLOCKCHAIN.ETHEREUM)} alt="Imagen" />
                            </span>
                            {t('components.filtersHistoric.eth')}
                        </div>
                        <div className={"" + (filterValues.polygon ? "m-2 btn btn-primary btn-bcs selected" : "m-2 btn btn-light btn-bcs text-center selected")}
                            onClick={() => setFilterValues({ ...filterValues, polygon: !filterValues.polygon })}>
                            <span className="btn-image-md">
                                <img className="m-1" src={iconBlockchain(BLOCKCHAIN.POLYGON)} alt="Imagen" />
                            </span>
                            {t('components.filtersHistoric.poly')}
                        </div>
                        <div className={"" + (filterValues.bsc ? "m-2 btn btn-primary btn-bcs selected" : "m-2 btn btn-light btn-bcs text-center selected")}
                            onClick={() => setFilterValues({ ...filterValues, bsc: !filterValues.bsc })}>
                            <span className="btn-image-md">
                                <img className="m-1" src={iconBlockchain(BLOCKCHAIN.BSC)} alt="Imagen" />
                            </span>
                            {t('components.filtersHistoric.bsc')}
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-center ">
                    <div className="col-2 d-flex justify-content-start">
                        <Form>
                            <Form.Group className=" mt-2 d-flex" onChange={(e) => changeFilterWallets(e)}>
                                <Form.Label>{t('views.historicView.selectWallets')}</Form.Label>&nbsp;&nbsp;&nbsp;
                                <Form.Check type="switch" checked={allWallets} onChange={() => { }} />
                            </Form.Group>

                        </Form>

                    </div>
                    <div className="col-2 d-flex justify-content-center">
                        <Form>
                            <Form.Group className=" mt-2 d-flex" onChange={(e) => changeFilterBlockchains(e)}>
                                <Form.Label >{t('views.historicView.selectBlockchains')}</Form.Label>&nbsp;&nbsp;&nbsp;
                                <Form.Check type="switch" checked={allBlockchains} onChange={() => { }} />
                            </Form.Group>
                        </Form>
                    </div>

                    <div className="col-2 d-flex justify-content-center ">
                        <Form.Label className="mt-2" >{t('views.historicView.from')}</Form.Label>&nbsp;&nbsp;&nbsp;
                        <input id="formDate" className="form-control" onChange={(e) => { handleDate(e.target.value) }}
                            type="date" step="1" min="2015-07-30" placeholder="Set Valid Date" />
                    </div>
                    <div className="col-2 d-flex justify-content-center ">
                        {/* <label className="form-label">{t('components.formBalance.date')}</label> */}
                        <Form.Label className="mt-2" >{t('views.historicView.to')}</Form.Label>&nbsp;&nbsp;&nbsp;
                        <input id="formDate" className="form-control" onChange={(e) => { handleDate(undefined, e.target.value) }}
                            type="date" step="1" min="2015-07-30" placeholder="Set Valid Date" />

                    </div>
                    <div className="col-2 d-flex justify-content-end">
                        <Form.Group className="" controlId="alias" >
                            {/* <Form.Label><b>Alias</b></Form.Label> */}
                            <Form.Control type="text" placeholder="Filtro por texto" onChange={(e) => { setFilterValues({ ...filterValues, text: e.target.value }) }} />
                        </Form.Group>
                    </div>
                    <div className=" col-2 d-flex">
                        <button type="button" onClick={() => { regenerateCertificates() }}
                            className="btn btn-primary text-start">{t('components.historicTable.requestAllCertificates')}</button>
                    </div>
                </div>
            </div>
        </div>
        <Modal
            onHide={() => setLoadingRegenerate}
            show={loadingRegenerate}
            size="lg"
            centered
        >
            <Modal.Header>
                <Modal.Title className="">{t('views.historicView.regenerating')}</Modal.Title>
                <button type="button" className="btn-close position-absolute top-0 end-0 me-3 mt-3" onClick={() => { setLoadingRegenerate(false) }} aria-label="Close"></button>
                
            </Modal.Header>

            <Modal.Body>
                <div className="d-flex justify-content-end m-2">
                <BalanceLoader />
                </div>
            </Modal.Body>
        </Modal>
    </>)
}

export default FiltersHistoric;