

function Spinner() {

    return (
        <div className=" d-flex justify-content-center">
            <div className="wrapper m-5">
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
            </div>
        </div>
    )
}

export default Spinner;