import { useSelector } from "react-redux";
import { USER_TYPE, selectCollapsed, selectJWT, selectPlans, selectUserInfo, setError, setNewPlan, setPlans, setSuccess } from "../features/user/user";
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import WalletsData from "../components/walletsData";
import { Plan, STATUS_PLAN } from "../models/models";
import Spinner from "../components/Spinner";
import { useAppDispatch } from "../app/hooks";
import { AdminService } from "../services/admin.service";
import { UserService } from "../services/user.service";
import PaymentMethod from "../components/PaymentMethod";
import { useTranslation } from "react-i18next";



function Plans() {


    const jwt = useSelector(selectJWT);
    const userInfo = useSelector(selectUserInfo);
    const plansStore = useSelector(selectPlans);
    const [plans, setPlansLocal] = useState<Plan[]>([]);
    const [planChoosen, setPlanChoosen] = useState<Plan>();
    const [modalPayment, setModalPayment] = useState<boolean>(false);
    const [correctPayment, setCorrectPayment] = useState<boolean>(false);
    const [addPlan, setAddPlan] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();
    const { t } = useTranslation('common');
	const collapsed = useSelector(selectCollapsed);

    const classes = ["border-primary", "border-warning", "border-light"];
    const classesTitles = ["bg-primary text-white", "bg-warning text-white", "bf-light"];


    const savePlan = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        setLoading(true);

        const target = event.target as typeof event.target & {
            name: { value: string };
            publicName: { value: string };
            description: { value: string };
            credits: { value: number };
            price: { value: number };
            status: { value: boolean };
        };


        const plan: Plan = {
            name: target.name.value,
            publicName: target.publicName.value,
            description: target.description.value,
            credits: target.credits.value,
            price: target.price.value,
            status : target.status.value ? STATUS_PLAN.ACTIVE : STATUS_PLAN.INACTIVE
        }
        try {
            const resp : Plan[] = await AdminService.createPlan([plan], jwt);
            setLoading(false);
            setAddPlan(false);
            dispatch(setNewPlan(resp));
            dispatch(setSuccess({ title: "Plan Created correctly!", desc: "Your plan has been created correctly" }));
        } catch (error: any) {
            dispatch(setError({ title: "Plan error", desc: "There was a problem creating the pack" }));
            return undefined;
        }
    }

    useEffect(() => {

        const callPlans = async () => {

            try {
                
                const planList : Plan[] = await UserService.getPlans(jwt);
                dispatch(setPlans(planList));
                setPlansLocal(planList);
                return planList;
            } catch (error: any) {
                console.error('Error captured:', error);
                return undefined;
            }

        }

        if(plansStore.length > 0){
            setPlansLocal(plansStore);
        }
        else{
            callPlans();
        }   

    }, [plansStore, dispatch, jwt]);

    return (
        <>
            <div id="dashboard" className={collapsed ? " d-grid align-content-start vh-100 pt-4 px-4 pb-1 overflow-auto main-content-collapsed" : " d-grid align-content-start vh-100 pt-4 px-4 pb-1 overflow-auto main-content-expanded"  }>
                <WalletsData setInquiryId={() => { }} />
                <div className="row col-12 d-flex justify-content-center">
                    <div className="col-12">
                    </div>
                </div>
                <div className="container my-5">
                    <div className="row mb-4 mx-5">
                        <div className="col-lg-12 text-center">
                            <h2 className="font-weight-bold mb-3">{t('views.plans.plans')}</h2>
                            <p className="lead mb-4">{t('views.plans.gainAccess')}</p>
                            <p className="text-muted">{t('views.plans.ourPlatform')}</p>
                            {userInfo.userType === USER_TYPE.ADMIN && (<Button onClick={() => setAddPlan(true)}> {t('views.plans.addPlan')}</Button>)}
                        </div>
                    </div>

                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                        {plans.length > 0 && plans.map((plan: Plan, index: number) => (
                            <div key={index} className="col">
                                <div className={"card h-100 mb-4 " + classes[(index) % 3]}>
                                    <div className={"card-header text-center " + classesTitles[(index) % 3]}>
                                        <h5 className="my-0 py-2">{plan.publicName}</h5>
                                    </div>
                                    <div className="card-body">
                                        <h3 className="card-title pricing-card-title text-center">{plan.description}</h3>
                                        <ul className="mt-3 mb-4 list-group list-group-flush text-center">
                                            <li className="list-group-item"><span className="text-light-emphasis">{plan.credits} {t('views.plans.creditsNumber')}</span></li>
                                            <li className="list-group-item"><span className="text-light-emphasis">{t('views.plans.consultBlocks')}</span></li>
                                            <li className="list-group-item"><span className="text-light-emphasis">{t('views.plans.certificates')}</span></li>
                                        </ul>
                                        <div className="text-center">
                                            <h2 className="text-light-emphasis mb-4">{plan.price} €</h2>
                                            <button onClick={ () => {setPlanChoosen(plan); setModalPayment(true)}} type="button" className="btn btn-lg btn-primary btn-block">{t('views.plans.select')}</button>
                                        </div>
                                    </div>
                                    <div className="card-footer text-muted text-center">
                                        <i className="bi bi-star-fill text-warning"></i>
                                        <i className="bi bi-star-fill text-warning ms-1"></i>
                                        <i className="bi bi-star-fill text-warning ms-1"></i>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Modal
                onHide={() => { setAddPlan(false) }}
                show={addPlan}
                size="lg"
                centered
            >
                <Modal.Header className="d-flex justify-content-center">
                    <Modal.Title>{t('views.plans.addPlan')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {!loading ? (
                        <Form className="mt-4 d-grid gap-3 text-start needs-validation" onSubmit={savePlan}>
                            <div className="row g-2">
                                <div className="col-md">
                                    <Form.Group className="form-floating mb-4" controlId="name">
                                        <Form.Control className="form-control" type="text" placeholder='' required />
                                        <Form.Label className="">{t('views.plans.namePlan')}</Form.Label>
                                        <Form.Text className="text-muted">
                                        </Form.Text>
                                    </Form.Group>
                                </div>
                                <div className="col-md">
                                    <Form.Group className="form-floating mb-4" controlId="publicName">
                                        <Form.Control className="form-control" type="text" placeholder='' required />
                                        <Form.Label className="">{t('views.plans.publicName')}</Form.Label>
                                        <Form.Text className="text-muted">
                                        </Form.Text>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row g-2">
                                <Form.Group className="form-floating mb-4" controlId="description">
                                    <Form.Control className="form-control" type="text" placeholder='' required />
                                    <Form.Label>{t('views.plans.description')}</Form.Label>
                                    <Form.Text className="text-muted">
                                    </Form.Text>
                                </Form.Group>
                            </div>
                            <div className="row g-2">
                                <div className="col-md">
                                    <Form.Group className="form-floating mb-4" controlId="credits">
                                        <Form.Control className="form-control" type="number" placeholder='' required />
                                        <Form.Label>{t('views.plans.credits')}</Form.Label>
                                        <Form.Text className="text-muted">
                                        </Form.Text>
                                    </Form.Group>
                                </div>
                                <div className="col-md">
                                    <Form.Group className="form-floating mb-4" controlId="price">
                                        <Form.Control className="form-control" type="number" placeholder='' required />
                                        <Form.Label>{t('views.plans.price')}</Form.Label>
                                        <Form.Text className="text-muted">
                                        </Form.Text>
                                    </Form.Group>
                                </div>
                                <div className="col-md">
                                <Form.Group className="form-floating mb-4" controlId="statusGroup">
                                    <select className="form-select" id="status" defaultValue="true" required>
                                        <option value="true">{t('views.plans.available')}</option>
                                        <option value="false">{t('views.plans.unavailable')}</option>
                                    </select>
                                    <label id="validationDefault04">{t('views.plans.status')}</label>
                                    </Form.Group>
                                    
                                </div>
                                <div className="d-flex justify-content-end">
                                        <Button variant="primary" type="submit">
                                            {t('views.plans.saveButton')}
                                        </Button>
                                    </div>
                            </div>


                        </Form>
                    ) :
                        <Spinner/>
                    }
                </Modal.Body>
            </Modal>
            <Modal
                onHide={() => { setModalPayment(false) }}
                show={modalPayment}
                size="lg"
            >
                <Modal.Body>
                    <PaymentMethod planId={planChoosen?._id} credits={planChoosen ? planChoosen?.credits : 0} setModalPayment={setModalPayment} setCorrectPayment={() => { setCorrectPayment(true);}} setInquiryId={() => {}} isPayingInquiry/>
                </Modal.Body>
            </Modal>
            <Modal
                onHide={() => { setCorrectPayment(false); setLoading(false); }}
                show={correctPayment}
                size="sm"
                centered
            >
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="pagoRealizadoModalLabel">{t('views.plans.realizedPayment')}</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body mb-3 me-4">
                    {t('views.plans.donePayment')}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={() => { setCorrectPayment(false); setLoading(false); }}>{t('views.plans.close')}</button>
                </div>
            </Modal>
        </>
    );

}

export default Plans;