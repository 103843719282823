import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import userReducer from '../features/user/user';
import { saveState } from './sessionHelper';


const appCombinedReducer = combineReducers({
  user: userReducer
});


const rootReducer = (state: any, action: Action) => {
  if(action.type === 'user/logout') {
    state = undefined;
  }
  return appCombinedReducer(state, action);
}

export const store = configureStore({
  reducer: rootReducer
});

store.subscribe(() => {
  saveState(store.getState().user);
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
