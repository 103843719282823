
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../app/hooks";
import { USER_TYPE, logout, selectCollapsed, selectUserInfo, setCollapsed } from "../features/user/user";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useEffect, useState, useTransition } from "react";
// import { useState } from "react";

function Header() {


	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const userInfo = useSelector(selectUserInfo);
	const collapsed = useSelector(selectCollapsed);
	const { i18n } = useTranslation('common');
	const { t } = useTranslation('common');

	const changeLang = () => {
		i18n.changeLanguage(i18n.language === 'es' ? 'en' : 'es');
	}
	// const [showNotifications, setShowNotifications] = useState(false);

	const switchTheme = () => {
		if (document.documentElement.getAttribute('data-bs-theme') === 'dark') {
			document.documentElement.setAttribute('data-bs-theme', 'light')
		}
		else {
			document.documentElement.setAttribute('data-bs-theme', 'dark')
		}
	}

	const getLiteral = (lit : string) => {
		return t(lit) ? t(lit) : '';
	}
	

	useEffect(() => {
		// const userAgent = navigator.userAgent;
		// const checkMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
		// const checkTablet = () => /iPad|Tablet|PlayBook|Silk/i.test(userAgent);

		// if (checkMobile() || checkTablet()) {
		// 	setCollapsed(true);
		// }
        
    }, []);

	// min-width: '280px'
	return (
		<div className="col-auto m-0 p-0">
			<nav className="d-flex align-items-start flex-column vh-100 text-light-emphasis bg-light-subtle px-1" style={{ minWidth: collapsed ? '' : '250px', width: collapsed ? "70px" : "250px", overflow: 'overlay' }} data-bs-theme="dark">

				<div className="mb-auto container-fluid">
					<div className="row pt-3 d-flex justify-content-between align-items-center">
						<a className="navbar-brand col d-flex justify-content-center" href="/">
							<img src="./assets/img/bswhite.png" style={{ maxWidth: '30px' }} alt="logo" />
						</a>
						<div className="col d-flex justify-content-center">
							<button className="m-2 btn btn-outline-primary text-nowrap" onClick={changeLang}>{i18n.language.toUpperCase()}</button>
						</div>
						<div className="col d-flex justify-content-center">
							<span className="nav-item" onClick={switchTheme} id="btnSwitch">
								<i className="bi bi-sun icon-lg"></i>
								<i className="bi bi-moon icon-lg"></i>
							</span>
						</div>
						<span className="col d-flex justify-content-center">
							<i className="bi bi-list icon-lg" onClick={() => dispatch(setCollapsed(!collapsed))}></i>
						</span>
						{/* <li className="dropdown leng-drop " onClick={ (e) => e.preventDefault()}>
							<div className="nav-link dropdown-toggle dd-item-lenguage">{i18n.language.toUpperCase()}</div>
							<ul className="dropdown-menu signledropdown lenguage-style">
								<li><div className="dd-item" onClick={ () => changeLang('en')}>EN</div></li>
								<li><div className="dd-item" onClick={() => changeLang('es')}>ES</div></li>
							</ul>
						</li> */}
					</div>
					<hr />
					<ul className="navbar-nav mb-2 ps-1">
						<li className="nav-item" >
							<Link to="/balance" className="nav-link w-100">
								<i className="bi bi-wallet2 icon-lg" data-toggle="tooltip" data-placement="top" title={getLiteral('components.header.balance')}></i>
								{!collapsed && (
									<>
										<span className="align-middle-lg ms-3">{t('components.header.balance')}</span>
										<i className="bi bi-chevron-right icon-lg float-end"></i>
									</>
								)}
							</Link>
							{/* <a className="nav-link active w-100" aria-current="page" >
							<i className="bi bi-wallet2 icon-lg"></i><span className="align-middle-lg ms-3">Balance XXX</span>
							<i className="bi bi-chevron-right icon-lg float-end"></i>
						</a> */}
						</li>
						<li className="nav-item">
							<Link to="/inquiry" className="nav-link w-100">
								<i className="bi bi-search icon-lg" data-toggle="tooltip" data-placement="top" title={getLiteral('components.header.inquiry')}></i>

								{!collapsed && (<>
									<span className="align-middle-lg ms-3">{t('components.header.inquiry')}</span>
									<i className="bi bi-chevron-right icon-lg float-end"></i>
								</>)}
							</Link>
						</li>
						<li className="nav-item">
							<Link to="/historic" className="nav-link w-100">
								<i className="bi bi-clock-history icon-lg" data-toggle="tooltip" data-placement="top" title={getLiteral('components.header.historic')}></i>

								{!collapsed && (<>
									<span className="align-middle-lg ms-3">{t('components.header.historic')}</span>
									<i className="bi bi-chevron-right icon-lg float-end"></i>
								</>)}
							</Link>
						</li>
						{userInfo.userType === USER_TYPE.ADMIN && (<li className="nav-item">
							<Link to="/tokens" className="nav-link w-100">
								<i className="bi bi-coin icon-lg" data-toggle="tooltip" data-placement="top" title={getLiteral('components.header.tokens')}></i>
								{!collapsed && (<>
									<span className="align-middle-lg ms-3">{t('components.header.tokens')}</span>
									<i className="bi bi-chevron-right icon-lg float-end"></i>
								</>)}
							</Link>
							<Link to="/users" className="nav-link w-100">
								<i className="bi bi-people icon-lg" data-toggle="tooltip" data-placement="top" title={getLiteral('components.header.users')}></i>
								{
									!collapsed && (
										<>
											<span className="align-middle-lg ms-3">{t('components.header.users')}</span>
											<i className="bi bi-chevron-right icon-lg float-end"></i>
										</>
									)}
							</Link>
						</li>)}
						<li className="nav-item">
							<Link to="/plans" className="nav-link w-100">
								<i className="bi bi-currency-dollar icon-lg" data-toggle="tooltip" data-placement="top" title={getLiteral(userInfo.userType === USER_TYPE.ADMIN ? 'components.header.plansAndPricing' : 'components.header.buyCredits')}></i>
								{!collapsed && (<><span className="align-middle-lg ms-3">{userInfo.userType === USER_TYPE.ADMIN ? t('components.header.plansAndPricing') : t('components.header.buyCredits')}</span>
									<i className="bi bi-chevron-right icon-lg float-end"></i></>)}
							</Link>
						</li>

						<li className="nav-item">
							<Link to="/profile" className="nav-link w-100">
								<i className="bi bi-gear icon-lg" data-toggle="tooltip" data-placement="top" title={getLiteral('components.header.configuration')}></i>
								{!collapsed && (<><span className="align-middle-lg ms-3">{t('components.header.configuration')}</span>
									<i className="bi bi-chevron-right icon-lg float-end"></i></>)}
							</Link>
						</li>
						{userInfo.userType === USER_TYPE.ADMIN && (
							<li className="nav-item">
								<Link to="/admin-configuration" className="nav-link w-100">
									<i className="bi bi-gear icon-lg" data-toggle="tooltip" data-placement="top" title={getLiteral('components.header.adminConfiguration')}></i>
									{!collapsed && (<><span className="align-middle-lg ms-3">{t('components.header.adminConfiguration')}</span>
										<i className="bi bi-chevron-right icon-lg float-end"></i></>)}
								</Link>
							</li>
						)}
					</ul>
					{/* <div className="accordion" id="accordion_notifications">
					<div className="accordion-item text-light-emphasis bg-info-subtle">
						<h2 className="accordion-header" id="notifications">
							<button className={showNotifications ? "accordion-button text-light-emphasis bg-info-subtle" : "accordion-button text-light-emphasis bg-info-subtle collapsed"} onClick={() => {setShowNotifications(!showNotifications)}} type="button" 
									data-bs-toggle="collapse" aria-expanded={showNotifications ? "true" : "false"} aria-controls="collapseOne" >
								<small><strong>NOTIFICACIONES</strong></small>
								<span className="badge text-bg-danger ms-3">3</span>
							</button>
						</h2>
						<div id="collapse_notifications" className={showNotifications ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby="notifications" data-bs-parent="#accordion_notifications">
							<div className="accordion-body">
								<ul className="nav flex-column">
									<li className="nav-item">
										<a className="nav-link py-1 w-100 active" aria-current="page" href="/">
											<small><i className="bi bi-file-earmark-check"></i><span className="ms-2">Nombre del certificado</span></small>
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link py-1 w-100" aria-current="page" href="/">
											<small><i className="bi bi-file-earmark-check"></i><span className="ms-2">Nombre del certificado</span></small>
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link py-1 w-100 active" aria-current="page" href="/">
											<small><i className="bi bi-menu-button-wide"></i><span className="ms-2">Datos de la notificación</span></small>
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link py-1 w-100 active" aria-current="page" href="/">
											<small><i className="bi bi-menu-button-wide"></i><span className="ms-2">Datos de la notificación</span></small>
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link py-1 w-100" aria-current="page" href="/">
											<small><i className="bi bi-file-earmark-check"></i><span className="ms-2">Nombre del certificado</span></small>
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link py-1 w-100 active" aria-current="page" href="/">
											<small><i className="bi bi-menu-button-wide"></i><span className="ms-2">Datos de la notificación</span></small>
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link py-1 w-100 active" aria-current="page" href="/">
											<small><i className="bi bi-menu-button-wide"></i><span className="ms-2">Datos de la notificación</span></small>
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link py-1 w-100" aria-current="page" href="/">
											<small><i className="bi bi-file-earmark-check"></i><span className="ms-2">Nombre del certificado</span></small>
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link py-1 w-100 active" aria-current="page" href="/">
											<small><i className="bi bi-menu-button-wide"></i><span className="ms-2">Datos de la notificación</span></small>
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link py-1 w-100 active" aria-current="page" href="/">
											<small><i className="bi bi-menu-button-wide"></i><span className="ms-2">Datos de la notificación</span></small>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div> */}
				</div>
				<div className="container-fluid">
					{/* <hr /> */}
					{/* <ul className="navbar-nav mb-2 ps-1">
						<li className="nav-item">
							<a className="nav-link w-100" aria-current="page" href="faqs.html">
								<i className="bi bi-question-circle icon-lg"></i><span className="align-middle-lg ms-3">Faqs</span>
								<i className="bi bi-chevron-right icon-lg float-end"></i>
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link w-100" aria-current="page" href="soporte.html">
								<i className="bi bi-life-preserver icon-lg"></i><span className="align-middle-lg ms-3">Soporte</span>
								<i className="bi bi-chevron-right icon-lg float-end"></i>
							</a>
						</li>
					</ul> */}
					<hr />
					<div className="mb-2 ps-1 row">
						
						<div className="col-10 d-flex" onClick={() => { navigate("/inquiry"); dispatch(logout()) }}>
							<a className="nav-link w-100" aria-current="page" href="/">
								<i className="bi bi-box-arrow-right icon-lg"></i>
								{!collapsed && (
									<span className="align-middle-lg ms-3">{t('components.header.logOut')}</span>
								)}
							</a>
						</div>
						
					</div>
				</div>
			</nav>
		</div>
	)
}

export default Header;