import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import WalletsData from "../components/walletsData";
import { modifyUserType, selectCollapsed, selectHistoric, selectJWT, selectTokens, selectUserInfo, selectUsers, setError, setSuccess, setUsers, USER_TYPE, UserInfo, WalletData } from "../features/user/user";
import { useAppDispatch } from "../app/hooks";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BalanceLoader from "../loaders/BalanceLoader";
import { Modal, Button, Spinner, Form, Accordion, Dropdown } from "react-bootstrap";
import { AdminService } from "../services/admin.service";
import { BLOCKCHAIN, Balance, Inquiry, TRUST_LEVEL_TOKEN, Token } from "../models/models";


function Users() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const jwt = useSelector(selectJWT);
    const userData = useSelector(selectUserInfo);
    const usersStore = useSelector(selectUsers);
    const [users, setUsersLocal] = useState<UserInfo[]>([]);
    const [loading, setLoading] = useState(true);
    const [showModalUserType, setShowModalUserType] = useState(false);
    const [userEdit, setUserEdit] = useState<UserInfo>();
    const [editCredits, setEditCredits] = useState<boolean>(false);
    const [showChecks, setShowChecks] = useState<boolean>(true);
    const historicInquiries = useSelector(selectHistoric);
    const [walletCopied, setWalletCopied] = useState<string>('');
    const classTrust = ["indefinido", "desarrollo", "depravado", "enganioso", "valido"];
    const trustLevels = ["No definido", "Pendiente", "Sospechoso", "Scam", "Válido"];
    const iconsTrust = ["bi-question-circle", "bi-wrench-adjustable-circle 1", "bi-exclamation-circle", "bi-x-octagon", "bi-check-circle"]
    const [filterText, setFilterText] = useState<string>('');
    const [someUser, setSomeUser] = useState<boolean>(true);
    const tokensStore = useSelector(selectTokens);
    const collapsed = useSelector(selectCollapsed);

    const getUserType = (userType?: USER_TYPE) => {
        return userType === USER_TYPE.FREE ? 'Usuario' : userType === USER_TYPE.ADMIN ? 'Admin' : 'Empresa';
    }

    const saveUserData = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        try {
            if (userEdit) {
                const target = event.target as typeof event.target & {
                    userType: { value: USER_TYPE };
                    alias: { value: string };
                    credits: { value: number };
                };

                // console.log("DATA: ", target.credits.value, " sure: ", target.sureCredits.checked);

                const user = editCredits ? { _id: userEdit._id, addCredits: Number(target.credits.value) } :
                    { _id: userEdit._id, userType: Number(target.userType.value), alias: target.alias.value };

                await AdminService.saveUserData(user, jwt);
                dispatch(modifyUserType(user));
                dispatch(setSuccess({ title: "Save data", desc: "Data saved correctly" }));
                setShowModalUserType(false);
                return;
            }
            else {
                throw new Error("Error saving data");
            }

        } catch (error) {
            setLoading(false);
            console.error('Error save user type', error);
            dispatch(setError({ title: 'User error', desc: 'Error save user type' }))
            setShowModalUserType(false);
        }


    }

    const iconBlockchain = (blockchain?: BLOCKCHAIN) => {
        return blockchain === BLOCKCHAIN.ETHEREUM ? './assets/img/blockchains/ethereum.png' :
            blockchain === BLOCKCHAIN.POLYGON ? './assets/img/blockchains/polygon.png' : './assets/img/blockchains/binance.png'
    }

    const getTokenChanges = (token: any) => {
        return tokensStore?.find((tok: Token) => tok._id === token._id && (tok.versions?.length || tok.edited));
        // console.log("busco : " , token);
        // tokensStore?.forEach( (tok : Token) => {
        //     if(tok._id === token._id && tok.versions?.length ) 
        //         console.log("holi: ", token, ' v -> ');
        //     }
        // )

        // return true;
    }

    const getLiteral = (pathLiteral: string): string => {
        return t(pathLiteral) ? t(pathLiteral) : '';
    }

    const getUrl = (bc: BLOCKCHAIN, wallet: string) => {

        return bc === BLOCKCHAIN.ETHEREUM ? 'https://etherscan.io/address/' + wallet :
            bc === BLOCKCHAIN.POLYGON ? 'https://polygonscan.com/address/' + wallet :
                'https://bscscan.com/address/' + wallet;

    }

    const filterUsers = (user: UserInfo) => {
        return user?.userData?.name?.toUpperCase().includes(filterText.toUpperCase()) ||
            user?.userData?.lastName?.toUpperCase().includes(filterText.toUpperCase()) ||
            user?.wallets?.find((wall: any) => wall.wallet?.toUpperCase().includes(filterText.toUpperCase())) ||
            user?.wallets?.find((wall: any) => wall?.alias?.toUpperCase().includes(filterText.toUpperCase()))

    }
    const countUsersFiltered = (filter: string) => {

        const usersFiltered = users.filter((user: UserInfo) => filter !== '' && (
            user?.userData?.name?.toUpperCase().includes(filter.toUpperCase()) ||
            user?.userData?.lastName?.toUpperCase().includes(filter.toUpperCase()) ||
            user?.wallets?.find((wall: any) => wall.wallet?.toUpperCase().includes(filter.toUpperCase())) ||
            user?.wallets?.find((wall: any) => wall?.alias?.toUpperCase().includes(filter.toUpperCase()))

        ));
        if (usersFiltered?.length) setSomeUser(true);
        else setSomeUser(false);
    }
    useEffect(() => {

        const getBlockchainsConsulted = (users: UserInfo[]) => {

            if (!historicInquiries.length) return users;
            const usersUpdated: UserInfo[] = [];

            users.forEach((user: UserInfo) => {
                let bcsConsulted: Array<BLOCKCHAIN> = [];
                let tokensInquiry: Array<any> = [];
                const wallets: WalletData[] = [];
                user?.wallets?.forEach((wallet: WalletData) => {

                    const inquiriesUser = historicInquiries.filter((inq: Inquiry) => inq.userId === user._id && wallet?.wallet && inq.configuration.wallets.includes(wallet.wallet))

                    inquiriesUser?.forEach((inq: Inquiry) => { bcsConsulted = bcsConsulted.concat(inq.configuration.blockchains) });


                    inquiriesUser?.forEach((inq: Inquiry) => {
                        inq.balanceResponse?.forEach((bal: Balance) => {
                            bal.tokens?.forEach(
                                (token: Token) => {
                                    if (token?.trustLevel !== TRUST_LEVEL_TOKEN.VALID) {
                                        const myTok = { symbol: token.symbol, name: token.name, trustLevel: token?.trustLevel, wallet: wallet.wallet, _id: token._id, updatedAt: token.updatedAt, versions : token.versions };
                                        // console.log("TOK  INQUIRY: ",myTok.symbol, ' - ' , myTok.versions , ' inq date: ', inq.updatedAt)
                                        const tokStore = tokensStore?.find( t => t._id === token._id);
                                        if (!tokensInquiry.some(tok => tok._id === token._id || tok.name === token.name) && tokStore?.versions?.some( (vers : Token) => vers.updatedAt && inq.updatedAt < vers.updatedAt)) {
                                            // console.log("SAVED");
                                            tokensInquiry.push(myTok)
                                        }
                                    }
                                }
                            )
                        })
                    })

                    // tokensInquiry.concat
                    // console.log("BCS: ", bcsConsulted);
                    const saveBCs: Array<BLOCKCHAIN> = [];
                    bcsConsulted.forEach(bc => {
                        if (!saveBCs.includes(bc)) {
                            saveBCs.push(bc);
                        }
                    })

                    const walletNew = { ...wallet, blockchainsConsulted: saveBCs }
                    wallets.push(walletNew);
                    // console.log("BC saved: ", saveBCs);
                });
                const userNew: UserInfo = { ...user, walletsData: wallets, tokensInquiry: tokensInquiry };
                usersUpdated.push(userNew);
            });
            return usersUpdated;
        }

        const callUsers = async () => {

            try {
                let users: UserInfo[] = await AdminService.getUsers(jwt);
                if (userData?.userType && [USER_TYPE.ADMIN, USER_TYPE.ENTERPRISE].includes(userData.userType)) {
                    users = getBlockchainsConsulted(users);
                }
                dispatch(setUsers(users));
                setUsersLocal(users);
                setLoading(false)
            } catch (error: any) {
                setLoading(false);
                console.error('Error get users', error);
                dispatch(setError({ title: 'Users error', desc: 'Error has ocurred trying get users' }))
            }

        }

        if (userData.userType !== USER_TYPE.ADMIN) {
            navigate('/balance');
        }

        if (usersStore?.length > 0) {
            setLoading(false);
            let users: UserInfo[] = [];
            if (userData?.userType !== undefined && [USER_TYPE.ADMIN, USER_TYPE.ENTERPRISE].includes(userData.userType)) {
                users = getBlockchainsConsulted(usersStore);
                // dispatch(setUsers(users));
            }
            setUsersLocal(users);
        }
        else {
            callUsers();
        }


    }, [jwt, usersStore, userData, dispatch, navigate, historicInquiries]);

    const getClass = (userType?: USER_TYPE) => {
        let classWallet = "ml-2 btn btn-secondary text-nowrap ";
        return userType === USER_TYPE.ADMIN ? classWallet + '' : userType === USER_TYPE.FREE ? classWallet + '' : classWallet + '';
    }

    return (<>
        <div id="dashboard" className={collapsed ? " d-grid align-content-start vh-100 pt-4 px-4 pb-1 overflow-auto main-content-collapsed" : " d-grid align-content-start vh-100 pt-4 px-4 pb-1 overflow-auto main-content-expanded"}>
            <WalletsData setInquiryId={() => { }} />
            <div className="row px-1 py-4 rounded-top-4 mt-auto pt-5 pb-5 text-light-emphasis bg-light-subtle">
                {/* <div className="row row-gap-3 mt-5 mb-5"> </div> */}
                <div className="col-lg-3 col-md-6 d-flex justify-content-start">
                    <label className={"m-2 btn btn-primary ps-3 align-middle-lg"}
                        onClick={() => { setShowChecks(!showChecks) }} >
                        <span className="btn-image-md">

                        </span>
                        {t('views.users.vinculateUsers')}
                    </label>
                </div>
                <div className="col-3 row row-gap-3 d-flex justify-content-end">
                    <div className="col-12 my-auto">

                        <div className="form-check form-switch form-check-reverse text-nowrap mb-0">
                            {/* <label className="form-control" >Buscar&nbsp;&nbsp;</label>  
                            <input className="" type="text"  onChange={(e) => {handleSearchText(e.target.value) }} /> */}
                            <div className="form-floating">
                                <input type="text" className="form-control" value={filterText} onChange={(e) => { setFilterText(e.target.value); countUsersFiltered(e.target.value) }} />
                                <label>{t('views.users.filterText')}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 row row-gap-3 d-flex justify-content-end">
                    <div className="col-3 my-auto">
                        <p>Nivel de confianza: </p>
                    </div>
                    <div className="col-9 my-auto">

                        <div className="form-check form-switch form-check-reverse text-nowrap mb-3">

                            <span className={"text-" + classTrust[0] + "-ranking"}>
                                <i className={"bi " + iconsTrust[0] + " icon-md"}></i>&nbsp;&nbsp;{trustLevels[0]}&nbsp;&nbsp;
                            </span>
                            <span className={"text-" + classTrust[1] + "-ranking"}>
                                <i className={"bi " + iconsTrust[1] + " icon-md"}></i>&nbsp;&nbsp;{trustLevels[1]}&nbsp;&nbsp;
                            </span>
                            <span className={"text-" + classTrust[2] + "-ranking"}>
                                <i className={"bi " + iconsTrust[2] + " icon-md"}></i>&nbsp;&nbsp;{trustLevels[2]}&nbsp;&nbsp;
                            </span>
                            <span className={"text-" + classTrust[3] + "-ranking"}>
                                <i className={"bi " + iconsTrust[3] + " icon-md"}></i>&nbsp;&nbsp;{trustLevels[3]}&nbsp;&nbsp;
                            </span>
                            <span className={"text-" + classTrust[4] + "-ranking"}>
                                <i className={"bi " + iconsTrust[4] + " icon-md"}></i>&nbsp;&nbsp;{trustLevels[4]}&nbsp;&nbsp;
                            </span>
                        </div>
                    </div>
                    <div className="col-9 my-auto form-check-reverse">
                        <p>Leyenda: </p>
                    </div>
                    <div className="col-3 my-auto">

                        <div className="form-check form-switch text-nowrap mb-3">

                            <span className={"text-ranking"}>
                                <i className={"bi bi-eyeglasses icon-md"}></i>&nbsp;&nbsp;{'Revisado'}&nbsp;&nbsp;
                            </span>

                        </div>
                    </div>
                </div>

            </div>
            {loading && (<BalanceLoader />)}
            {!loading && (
                <Accordion>
                    {users?.map((user: UserInfo, index: number) => (
                        <>
                            { ((filterText !== '' && filterUsers(user)) || filterText === '') &&
                                (
                                    <Accordion.Item key={user._id} eventKey={user._id ? user._id : index.toString()}>
                                        <Accordion.Header className="text-center">
                                            <div className="col-1 m-2">
                                                <button className={getClass(user.userType)}>
                                                    <i className="bi bi-pencil-square " onClick={() => { setUserEdit(user); setShowModalUserType(true) }}>&nbsp;&nbsp;</i>
                                                    <span className="ms-2">{getUserType(user.userType)}&nbsp;&nbsp;</span>
                                                    <i className={user.userType === USER_TYPE.ADMIN ? "bi bi-gear-fill" : user.userType === USER_TYPE.FREE ? "bi bi-person-circle" : "bi bi-people-fill"}></i>
                                                </button>
                                            </div>
                                            <div className="col-2 m-2">
                                                <button className="m-2 btn btn-light text-nowrap">
                                                    {/* <i className="bi bi-card-heading"></i> */}
                                                    <i className="bi bi-pencil-square " onClick={() => { setUserEdit(user); setShowModalUserType(true) }}>&nbsp;&nbsp;</i>
                                                    <span className="ms-2">{user.alias ? user.alias : "- Alias -"}</span>
                                                </button>
                                            </div>
                                            <div className="col-3 m-2">
                                                <button className="m-2 btn btn-primary text-nowrap">
                                                    <i className="bi bi-card-heading"></i>
                                                    <span className="ms-2">{user.userData?.name ? user.userData.name + ' ' + (user.userData?.lastName ? user.userData?.lastName : '') : "---"}&nbsp;({user._id})</span>
                                                </button>
                                            </div>
                                            <div className="col-1 m-2">
                                                <button className="m-2 btn btn-primary text-nowrap">
                                                    <i className="bi bi-envelope-at"></i>
                                                    <span className="ms-2">{user.email ? user.email : "---"}</span>
                                                </button>
                                            </div>

                                            <div className="col-2 m-2">
                                                <button className="m-2 btn btn-primary text-nowrap">
                                                    {/* <i className="bi bi-card-heading"></i> */}
                                                    <i className="bi bi-pencil-square " data-toggle="tooltip" data-placement="top" title='Añadir créditos' onClick={() => { setEditCredits(true); setUserEdit(user); setShowModalUserType(true) }}>&nbsp;&nbsp;</i>
                                                    <span className="ms-2">{t('views.users.credits')}:&nbsp;{user.userType === USER_TYPE.ADMIN ? "∞" : user.creditsTotal !== undefined && user.creditsConsumed !== undefined ? user.creditsTotal - user.creditsConsumed : '-'}</span>
                                                </button>
                                            </div>
                                            <div className="col-2 m-2">
                                                <button className="m-2 btn btn-primary text-nowrap">
                                                    <i className="bi bi-clock"></i>
                                                    <span className="ms-2">{user.userData?.timeZoneDefault ? user.userData.timeZoneDefault.substring(0, 20) : 'Time zone not choosen'}</span>
                                                </button>
                                            </div>


                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <table className=" table table-striped table-hover table-borderless align-middle">
                                                <thead className="sticky-top">
                                                    <tr className="text-light-emphasis">
                                                        {/* <th scope="col">{t('views.users.ids')}</th>*/}
                                                        <th scope="col">{t('views.users.alias')}</th>
                                                        <th scope="col">{t('views.users.wallet')}</th>
                                                        <th scope="col">{t('views.users.bcsConsulted')}</th>
                                                        <th scope="col">{t('views.users.tokenPending')}</th>
                                                        <th scope="col">{t('views.users.edit')}</th>
                                                        {/* <th scope="col">{t('views.users.credits')}</th>
                                            <th scope="col">{t('views.users.userType')}</th> */}
                                                        {/* <th scope="col">{t('views.users.mainAsset')}</th>
                            <th scope="col">{t('views.users.trustLevel')}</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {user.walletsData?.map((wallet: WalletData, indexWallet: number) => (
                                                        <tr key={index + "_" + indexWallet}>
                                                            <td>
                                                                <span className="btn-image-md">
                                                                    <b><span className="ms-2" data-toggle="tooltip" data-placement="top" title={wallet.wallet}>{wallet.alias ? wallet.alias : '---'} </span></b>

                                                                </span>
                                                            </td>
                                                            <td className="text-nowrap" style={{ maxWidth: '200px' }}>
                                                                {showChecks && (
                                                                    <div className="btn-group">
                                                                        <Form.Check
                                                                            className=""
                                                                            type="checkbox"
                                                                            id="default-checkbox"
                                                                            label=""
                                                                        />
                                                                    </div>
                                                                )}

                                                                {/* <span className="btn-image-md">
                                                        <span className="ms-2">
                                                            {user.alias ? user.alias : user._id}
                                                        </span>
                                                    </span> */}
                                                                <span className="btn-image-md">
                                                                    <b><span className="ms-2" data-toggle="tooltip" data-placement="top" title={wallet.wallet}>{`${wallet.wallet?.slice(0, 10)}. . .${wallet.wallet?.slice(-10)}`} </span></b>
                                                                    <i className={wallet?.wallet && wallet?.wallet === walletCopied ? "bi bi-clipboard-check-fill" : "bi bi-clipboard-plus pointer"} data-toggle="tooltip" data-placement="top"
                                                                        title={getLiteral('views.balance.copyWalletButton')} onClick={() => { setWalletCopied(wallet?.wallet ? wallet?.wallet : ''); navigator.clipboard.writeText(wallet?.wallet ? wallet.wallet : ''); setTimeout(() => setWalletCopied(''), 1000); }}>
                                                                    </i>
                                                                </span>

                                                            </td>
                                                            <td>
                                                                <span className="btn-image-md">
                                                                    {wallet.blockchainsConsulted?.map((bc: BLOCKCHAIN, index) => (
                                                                        <span key={wallet + '-' + indexWallet.toString() + '-' + index.toString()} onClick={() => window.open(getUrl(bc, wallet.wallet ? wallet.wallet : ''))} className="btn-image-md pointer" data-toggle="tooltip" data-placement="top" title={'Ir a ' + (bc === BLOCKCHAIN.BSC ? 'BcsScan' : bc === BLOCKCHAIN.ETHEREUM ? 'EtherScan' : 'PolygonScan')}>
                                                                            <img className="m-1" src={iconBlockchain(bc)} alt="Imagen" />
                                                                        </span>
                                                                    ))}
                                                                </span>
                                                            </td>
                                                            <td className="text-nowrap" style={{ maxWidth: '100px' }}>
                                                                {user.tokensInquiry?.length !== 0 && (
                                                                    <div className="btn-group pointer" role="group" aria-label="User" >&nbsp;&nbsp;
                                                                        {/* <button className="btn btn-outline-primary pe-none" > */}

                                                                        {/* </button> */}
                                                                        &nbsp;
                                                                        <Dropdown data-bs-theme="dark">
                                                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                                                <i className="bi bi-radioactive "></i>
                                                                                <span className={"text-nowrap text-" + classTrust[user?.tokensInquiry ? user?.tokensInquiry.filter(tok => tok.wallet === wallet?.wallet)[0]?.trustLevel : ''] + "-ranking"}>
                                                                                    &nbsp;&nbsp; {user?.tokensInquiry ? user?.tokensInquiry.filter(tok => tok.wallet === wallet?.wallet)[0]?.name : ''}
                                                                                </span>
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                {
                                                                                    user.tokensInquiry?.map((token, index) => (
                                                                                        [TRUST_LEVEL_TOKEN.PENDING, TRUST_LEVEL_TOKEN.SUSPICIOUS, TRUST_LEVEL_TOKEN.NOT_DEFINED].includes(token?.trustLevel) && token.wallet === wallet?.wallet && (
                                                                                            <Dropdown.Item >
                                                                                                <i className={"bi " + iconsTrust[getTokenChanges(token) ? getTokenChanges(token)?.trustLevel : token.trustLevel] + " icon-md text-" + classTrust[getTokenChanges(token) ? getTokenChanges(token)?.trustLevel : token.trustLevel] + "-ranking"}></i>&nbsp;&nbsp;
                                                                                                <span className={"text-nowrap text-" + classTrust[getTokenChanges(token) ? getTokenChanges(token)?.trustLevel : token.trustLevel] + "-ranking"} data-toggle="tooltip" data-placement="top" title={trustLevels[token?.trustLevel]}>{token.symbol}&nbsp;{token.name}</span>&nbsp;&nbsp;
                                                                                                <i className={"bi bi-eyeglasses icon-md"} style={{ visibility: getTokenChanges(token)?.edited ? 'visible' : 'hidden', color: 'white' }}></i>&nbsp;&nbsp;
                                                                                                <i className="bi bi-box-arrow-up-right pointer" onClick={() => { window.open("#/tokens?filter=" + token.name + '&id=' + token._id, '_blank'); }}></i>
                                                                                            </Dropdown.Item>
                                                                                        )
                                                                                    ))
                                                                                }
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>

                                                                    </div>
                                                                )
                                                                }

                                                            </td>
                                                            <td className="text-nowrap">
                                                                <div className="btn-group pointer" role="group" aria-label="User" onClick={() => { setUserEdit(user); setShowModalUserType(true) }}>&nbsp;&nbsp;

                                                                    <button className="btn btn-outline-primary pe-none" >
                                                                        <i className="bi bi-pencil-square "></i>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                            {/* <td className="text-nowrap">
                                                    <span className="btn-image-md">
                                                        <span className="ms-2">
                                                            {user.userData?.name ? user.userData.name : 'Without name'}&nbsp;
                                                            {user.userData?.lastName ? user.userData.lastName : ''}
                                                        </span>
                                                    </span>
                                                </td>
                                                <td className="text-nowrap">
                                                    <span className="btn-image-md">
                                                        <span className="ms-2" data-toggle="tooltip" data-placement="top" title={wallet.wallet}>{wallet.alias ? `${wallet.alias} ${wallet.wallet?.slice(0, 6)}. . .${wallet.wallet?.slice(-6)}` : `${wallet.wallet?.slice(0, 10)}. . .${wallet.wallet?.slice(-10)}`} </span>
                                                    </span>
                                                </td>
                                                <td className="text-nowrap" style={{ maxWidth: '50px' }}>
                                                    <span className="btn-image-md">
                                                        <span className="ms-2">{user.userData?.timeZoneDefault ? user.userData.timeZoneDefault.substring(0, 20) : 'Time zone not choosen'}</span>

                                                    </span>
                                                </td>
                                                <td className="text-nowrap" style={{ maxWidth: '50px' }}>
                                                    <span className="btn-image-md">
                                                        <span className="ms-2">{user.creditsTotal !== undefined && user.creditsConsumed !== undefined ? user.creditsTotal - user.creditsConsumed : '-'}</span>
                                                    </span>
                                                    <div className="btn-group pointer" role="group" aria-label="User" data-toggle="tooltip" data-placement="top" title='Añadir créditos' onClick={() => { setEditCredits(true); setUserEdit(user); setShowModalUserType(true) }}>&nbsp;&nbsp;
                                                        <button type="button" className="btn btn-outline-primary pe-none">
                                                            <i className="bi bi-pencil-square "></i>
                                                        </button>
                                                    </div>
                                                </td>

                                                <td className="text-nowrap">
                                                    <div className="btn-group pointer" role="group" aria-label="User" onClick={() => { setUserEdit(user); setShowModalUserType(true) }}>&nbsp;&nbsp;
                                                        <button type="button" className="btn btn-outline-primary pe-none">
                                                            <i className="bi bi-pencil-square "></i>
                                                        </button>
                                                    </div>
                                                    <span className="btn-image-md">
                                                        <span className="ms-2">{getUserType(user.userType)}</span>
                                                    </span>
                                                </td> */}

                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            }
                        </>

                    ))}
                </Accordion>
            )}
            {!loading && !someUser && filterText !== '' && (

                <div className="row row-gap-3 d-flex justify-content-end">
                    <div className="col-12 my-auto text-center mt-5">
                        <h3>{t('views.users.noUsersFilters')}</h3>
                        <button className="m-2 btn btn-light text-nowrap" onClick={() => { setFilterText(''); countUsersFiltered('') }}>
                            <i className="bi bi-person-dash ">&nbsp;&nbsp;</i>
                            <span className="ms-2">{t('views.users.buttonNoFilter')}</span>
                        </button>
                    </div>
                </div>
            )}
        </div>
        <Modal
            onHide={() => { setShowModalUserType(false); setEditCredits(false) }}
            show={showModalUserType}
            size="lg"
            centered
        >
            <Modal.Header>
                <Modal.Title>{t('views.users.userInfo')}</Modal.Title>
            </Modal.Header>

            <Modal.Body >
                {/* <div className="col-3"></div> */}
                <Form className="row d-flex justify-content-center m-2" onSubmit={saveUserData}>
                    {userEdit && (
                        <>
                            {editCredits && (<>
                                <div className="col-12 form-floating">
                                    <input type="number" min="1" className="form-control  rounded-top-2" id="credits" required />
                                    <label>{t('views.users.setCredits')}</label>

                                </div>
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <input className="form-check-input" type="checkbox" id="sureCredits" required />
                                    </div>
                                    <div className="col-auto m-0 p-0">
                                        <label className="form-check-label">
                                            {t('views.users.sureCredits')}
                                        </label>
                                    </div>
                                </div>


                            </>

                            )}
                            {!editCredits && (
                                <>
                                    <div className="col-12 form-floating">
                                        <input type="text" className="form-control  rounded-top-2" id="alias" defaultValue={userEdit.alias} required />
                                        <label>{t('views.users.alias')}</label>
                                    </div>
                                    <Form.Group className="col-12 mt-3" controlId="userType">
                                        <Form.Label>{t('views.users.setUserType')}</Form.Label>
                                        <Form.Select defaultValue={getUserType(userEdit.userType)} aria-label={getUserType(userEdit.userType)}>
                                            <option value={String(userEdit.userType)}>{getUserType(userEdit.userType)}</option>
                                            {userEdit.userType !== USER_TYPE.FREE && (<option value="0">{t('views.users.user')}</option>)}
                                            {userEdit.userType !== USER_TYPE.ENTERPRISE && (<option value="2">{t('views.users.enterprise')}</option>)}
                                            {userEdit.userType !== USER_TYPE.ADMIN && (<option value="1">{t('views.users.admin')}</option>)}
                                        </Form.Select>
                                    </Form.Group>
                                </>
                            )}

                        </>
                    )}
                    <Button className="mt-4" variant="primary" type="submit">
                        {t('views.users.saveType')}
                    </Button>
                    {loading && (
                        <div className='col-lg-12 m-2'>
                            <Spinner animation="grow" variant="secondary" />
                            <Spinner animation="grow" variant="secondary" />
                            <Spinner animation="grow" variant="secondary" />
                            <Spinner animation="grow" variant="secondary" />
                        </div>
                    )}
                </Form>
            </Modal.Body>
        </Modal>

    </>)
}

export default Users;
