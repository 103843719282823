import { useSelector } from "react-redux";
import { selectJWT, setError, selectBalances, setBalances, setTotalFiat, setTokensBalances, setTotalFiatEur, selectFiat, selectCurrencySymbol, selectCurrency, CURRENCY, selectFiatEur, selectWallets, selectTokensBalances, setHistoric, UserInfo, setUsers, selectUserInfo, USER_TYPE, setTokens, ConfigInfo, selectConfig, selectCollapsed } from "../features/user/user";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../app/hooks";
import WalletsData from "../components/walletsData";
import BalanceService from "../services/balance.service";
import { CurrentBalance, Balance as BalanceDetail, Token, BLOCKCHAIN, Inquiry } from "../models/models";
import Filters from "../components/Filters";
import RowTokenTable from "../components/RowTokenTable";
import BalanceLoader from "../loaders/BalanceLoader";
import { useTranslation } from "react-i18next";
import Spinner from "../components/Spinner";
import { Modal } from "react-bootstrap";
import FiltersTokensBalance from "../components/FiltersTokensBalance";
import { AdminService } from "../services/admin.service";


function Balance() {

    const dispatch = useAppDispatch();
    const balancesStore = useSelector(selectBalances);
    const configAppStore = useSelector(selectConfig);
    const tokensBalancesStore = useSelector(selectTokensBalances);
    const walletsStore = useSelector(selectWallets);
    const [lastWalletsStore, setLastWalletsStore] = useState<any>();
    const valFiat = useSelector(selectFiat);
    const valFiatEur = useSelector(selectFiatEur);
    const jwt = useSelector(selectJWT);
    // const currencySymbol = useSelector(selectCurrencySymbol);
    // const currency = useSelector(selectCurrency);
    const [balances, setBalancesData] = useState<CurrentBalance[]>([]);
    const [filtered, setFiltered] = useState<CurrentBalance[]>([]);
    const [loading, setLoading] = useState(true);
    const [loadingFiat, setLoadingFiat] = useState(true);
    const { t } = useTranslation('common');
    const [balanceFiat, setBalanceFiat] = useState(0);
    const [balanceFiatEur, setBalanceFiatEur] = useState(0);
    const [enableAllFilters, setEnableAllFilters] = useState(false);
    const [sortConfig, setSortConfig] = useState<any>({ wallet: 'none', symbol: 'none', name: 'none', trustLevel: 'none', balance: 'none', balanceFiat: 'none' });
    const [showRefreshModal, setShowRefreshModal] = useState<boolean>(false);
    const [tokensBalance, setTokensBalanceState] = useState<Token[]>([]);
    const [filteredTokensBalance, setFilteredTokensBalance] = useState<Token[]>([]);
    const userInfo = useSelector(selectUserInfo);
    const [configApp, setConfigApp ] = useState<ConfigInfo>(); 
    const [loadingLotTime, setLoadingLotTime] = useState<boolean>(false);
	const collapsed = useSelector(selectCollapsed);

    useEffect(() => {

        const callHistoric = async () => {
            try {
                const inquiries: Inquiry[] = await BalanceService.historic(jwt);
                if (inquiries.length > 0) {
                    dispatch(setHistoric(inquiries))
                }
            } catch (error: any) {
                // console.log("ERROR GETTING INQUIRIES")
            }
        }

        const callUsers = async () => {

            try {
                let users: UserInfo[] = await AdminService.getUsers(jwt);

                dispatch(setUsers(users));
            } catch (error: any) {
                console.error('Error get users', error);
            }

        }

        const callTokens = async () => {

            try {
                const tokens: Token[] = await AdminService.tokens(jwt);

                dispatch(setTokens(tokens));
            } catch (error: any) {
                console.error('Error get users', error);
            }

        }



        const callFiatBalance = async (balanceAPI: CurrentBalance[]) => {

            try {
                const balancesFiat: CurrentBalance[] = await BalanceService.currentFiat(balanceAPI, jwt);
                parseData(balancesFiat, true);
            } catch (error: any) {
                console.error('Error get tokens', error);
            }

        }

        const parseData = (balanceAPI: CurrentBalance[], fiatOption: boolean) => {
            const tokens: Token[] = [];
            setBalancesData(balanceAPI);


            let vFiat = 0;
            let vFiatEur = 0;
            balanceAPI.forEach((bal: CurrentBalance) => {
                bal.balance.forEach((balance: BalanceDetail) => {
                    balance?.tokens.forEach((token: Token) => {
                        // console.log("Balances: ", token);
                        tokens.push({ ...token, wallet: bal.wallet });
                        if (fiatOption && token.balanceFiat) {
                            vFiat += token.balanceFiat;
                            vFiatEur += token.balanceFiatEur;
                        }
                    })
                })
            });
            if (fiatOption) {
                
                setBalanceFiatEur(vFiatEur);
                setBalanceFiat(vFiat);
                dispatch(setTotalFiat(vFiat));
                dispatch(setTotalFiatEur(vFiatEur));
                setLoadingFiat(false);
            }
            dispatch(setBalances(balanceAPI));
            setTokensBalanceState(tokens);
            dispatch(setTokensBalances(tokens));
        }


        const callBalance = async () => {

            try {
                callHistoric();
                if(userInfo.userType && [USER_TYPE.ADMIN, USER_TYPE.ENTERPRISE].includes(userInfo.userType)){
                    callTokens();
                    callUsers();
                }
                const balanceAPI: CurrentBalance[] = await BalanceService.currentBalance(jwt);
                parseData(balanceAPI, false);
                setLoading(false);
                callFiatBalance(balanceAPI);

            } catch (error: any) {
                setLoading(false);
                console.error('Error get current balance:', error);
                dispatch(setError({ title: 'Balance error', desc: 'Error has ocurred trying get your balance' }))
            }



        }

        const callConfig = async () => {
            
            const currentConfig: ConfigInfo = await AdminService.currentConfiguration(jwt);
            setConfigApp(currentConfig);
            
        }

        if(!configAppStore) {callConfig();}
        
        else {
            setConfigApp(configAppStore)
        }

        if (balancesStore?.length > 0) {
            setLoading(false);
            setLoadingFiat(false);
            setBalancesData(balancesStore);
            setTokensBalanceState(tokensBalancesStore);
        }
        else {
            callBalance();
            setTimeout(() => {
                setLoadingLotTime(true);
            }, 5000);
        }

        if (walletsStore?.length) {
            setLastWalletsStore(walletsStore);
            setTimeout(() => {
                if (lastWalletsStore && lastWalletsStore?.length !== walletsStore.length) {
                    setShowRefreshModal(true);
                }
            }, 6000);
        }

        if (valFiat) {
            setBalanceFiat(valFiat);
        }

        if (valFiatEur) {
            setBalanceFiatEur(valFiatEur);
        }
    }, [jwt, balancesStore, valFiat, valFiatEur, walletsStore, configAppStore]);

    const iconBlockchain = (blockchain?: BLOCKCHAIN) => {
        return blockchain === BLOCKCHAIN.ETHEREUM ? './assets/img/blockchains/ethereum.png' :
            blockchain === BLOCKCHAIN.POLYGON ? './assets/img/blockchains/polygon.png' : './assets/img/blockchains/binance.png'
    }

    const sortByColumns = (column: string, value: string) => {

        setSortConfig({ ...sortConfig, [column]: value });


        if (column === 'wallet') {
            setFilteredTokensBalance(filteredTokensBalance.sort((a, b) => {
                if (a.wallet && b.wallet) {
                    if (value === 'asc') {
                        return a.wallet > b.wallet ? 1 : -1;
                    }
                    return a.wallet < b.wallet ? 1 : -1;
                }
                else return -1;
            }))

            return;
        }

        if (column === 'symbol') {
            setFilteredTokensBalance(filteredTokensBalance.sort((a, b) => {
                if (value === 'asc') {
                    return a.symbol > b.symbol ? 1 : -1;
                }
                return a.symbol < b.symbol ? 1 : -1;
            }))

        }
        else if (column === 'name') {
            setFilteredTokensBalance(filteredTokensBalance.sort((a, b) => {
                if (value === 'asc') {
                    return a.name > b.name ? 1 : -1;
                }
                return a.name < b.name ? 1 : -1;
            }))
        }
        else if (column === 'trustLevel') {
            setFilteredTokensBalance(filteredTokensBalance.sort((a, b) => {
                if (value === 'asc' && a.trustLevel && b.trustLevel) {
                    return a.trustLevel > b.trustLevel ? 1 : -1;
                }
                return a.trustLevel && b.trustLevel && a.trustLevel < b.trustLevel ? 1 : -1;
            }))
        }
        else if (column === 'balance') {
            setFilteredTokensBalance(filteredTokensBalance.sort((a, b) => {
                if (value === 'asc') {
                    return a.balance > b.balance ? 1 : -1;
                }
                return a.balance < b.balance ? 1 : -1;
            }))
        }
        else if (column === 'balanceFiat') {
            setFilteredTokensBalance(filteredTokensBalance.sort((a, b) => {
                if (value === 'asc') {
                    return a.balanceFiat > b.balanceFiat ? 1 : -1;
                }
                return a.balanceFiat < b.balanceFiat ? 1 : -1;
            }))
        }

        const newBalances: BalanceDetail[] = [];

        // filtered?.forEach( bal  => {
        //     bal.balance.forEach( (b : BalanceDetail) => {
        // filteredTokensBalance?.forEach((tok: Token) => {


        // })

        //     })

        // })


    }


    return (
        <>
            <div id="dashboard" className={collapsed ? " d-grid align-content-start vh-100 pt-4 px-4 pb-1 overflow-auto main-content-collapsed" : " d-grid align-content-start vh-100 pt-4 px-4 pb-1 overflow-auto main-content-expanded"  } >
                <WalletsData setInquiryId={() => { }} />
                {loading ? (
                    <>
                        <Spinner />
                        <div className="h4 text-center"> {t('views.balance.waitingBalance')}</div>
                        {loadingLotTime && (<div className="h4 text-center"> {t('views.balance.lotOfTime')}</div>)}
                        <BalanceLoader />
                    </>
                ) : (
                    <>
                        {/* <div className="row my-4">
                            <h2 className="h6 text-muted m-0">{t('views.balance.value')}</h2>
                            {loadingFiat ? (
                                <p className="h2 m-0">{t('views.balance.loadingFiat')}</p>
                            ) :
                                (<h3 className="h2 m-0">{!loadingFiat && balanceFiat && currency === CURRENCY.USD ? balanceFiat.toFixed(2) : balanceFiatEur && currency === CURRENCY.EUR ? balanceFiatEur.toFixed(2) : '0.0'}&nbsp;{currencySymbol}</h3>)
                            }
                            
                        </div> */}
                        <FiltersTokensBalance currentBalances={balances} tokensBalance={tokensBalance} iconBlockchain={iconBlockchain} setFiltered={setFiltered}
                            enableAllFilters={enableAllFilters} setEnableAllFilters={setEnableAllFilters} setFilteredTokensBalance={setFilteredTokensBalance} />
                        {/* <div>hola : {filtered.length} - {balances.length} </div> */}
                        <div className="row rounded-bottom-4 table-responsive z-0 px-1 text-light-emphasis bg-light-subtle">
                            {(filteredTokensBalance.length === 0) ? (
                                <div className="my-5 text-center">
                                    <h4 className="h6">{t('views.balance.noTokens')}</h4>
                                    {/* <p className="text-muted">Don't see a token?</p> */}
                                    <button onClick={() => setEnableAllFilters(true)} className="btn btn-primary px-3 text-nowrap" aria-current="page">
                                        <i className="bi bi-arrow-repeat"></i><span className="ms-3">{t('views.historicView.selectAllFilters')}</span>
                                    </button>
                                </div>
                            ) : (
                                <table className="table table-striped table-hover table-borderless align-middle">
                                    <thead className="sticky-top">
                                        <tr className="text-light-emphasis">
                                            {/* <th scope="col">{t('views.balance.blockchain')}</th> */}
                                            <th scope="col">{t('views.balance.wallet')} &nbsp;
                                                <i className={sortConfig.wallet === 'asc' ? "bi bi-arrow-down-short fs-4 text-muted " : "bi bi-arrow-up-short fs-4  text-muted "} onClick={() => sortByColumns('wallet', sortConfig.wallet === 'desc' ? 'asc' : 'desc')} ></i>
                                            </th>
                                            <th scope="col"></th>
                                            <th scope="col" >{t('views.balance.symbol')}&nbsp;
                                                <i className={sortConfig.symbol === 'asc' ? "bi bi-arrow-down-short fs-4 text-muted " : "bi bi-arrow-up-short fs-4  text-muted "} onClick={() => sortByColumns('symbol', sortConfig.symbol === 'desc' ? 'asc' : 'desc')} ></i></th>
                                            <th scope="col">{t('views.balance.tokenName')}&nbsp;
                                                <i className={sortConfig.name === 'asc' ? "bi bi-arrow-down-short fs-4 text-muted " : "bi bi-arrow-up-short fs-4  text-muted "} onClick={() => sortByColumns('name', sortConfig.name === 'desc' ? 'asc' : 'desc')} ></i></th>
                                            <th scope="col">{t('views.balance.contractAddress')}</th>
                                            <th scope="col">{t('views.balance.tokenType')}&nbsp;
                                                <i className={sortConfig.trustLevel === 'asc' ? "bi bi-arrow-down-short fs-4 text-muted " : "bi bi-arrow-up-short fs-4  text-muted "} onClick={() => sortByColumns('trustLevel', sortConfig.trustLevel === 'desc' ? 'asc' : 'desc')} ></i>
                                            </th>
                                            <th scope="col" className="balances-amounts">{t('views.balance.balance')}
                                                &nbsp;<i className="bi bi-info-circle-fill text-muted " data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-custom-class="tooltip-popover popover-sm" data-bs-content="Popover con información al respecto de esta columna..."></i>
                                                &nbsp;<i className={sortConfig.balance === 'asc' ? "bi bi-arrow-down-short fs-4 text-muted " : "bi bi-arrow-up-short fs-4  text-muted "} onClick={() => sortByColumns('balance', sortConfig.balance === 'desc' ? 'asc' : 'desc')}> </i>
                                            </th>
                                            <th scope="col" className="balances-amounts">{t('views.balance.balance')} {t('views.balance.currencyType')}
                                                &nbsp;<i className={sortConfig.balanceFiat === 'asc' ? "bi bi-arrow-down-short fs-4 text-muted " : "bi bi-arrow-up-short fs-4  text-muted "} onClick={() => sortByColumns('balanceFiat', sortConfig.balanceFiat === 'desc' ? 'asc' : 'desc')}> </i>
                                            </th>
                                        </tr>
                                    </thead>
                                    {tokensBalance.length > 0 && filteredTokensBalance.length === 0 && (
                                        <tbody>
                                            {/* {balances.map((balance: CurrentBalance, index1: number) => (
                                            balance.balance.map((bal: BalanceDetail, index2: number) => (
                                                bal?.tokens?.map((token: Token, index3: number) => (
                                                    <tr key={index1 + '_' + index2 + '_' + index3}>
                                                        <RowTokenTable index={(index1 * balance.balance.length * bal.tokens.length) + (index2 * bal.tokens.length) + index3} wallet={balance.wallet} token={token} iconBlockchain={iconBlockchain} />
                                                    </tr>
                                                ))
                                            ))
                                        ))} */}
                                            {tokensBalance?.map((token: Token, index: number) => (
                                                <tr key={index}>
                                                    {/* <RowTokenTable index={index} balance={balance} token={token} iconBlockchain={iconBlockchain} /> */}
                                                    <RowTokenTable index={index} token={token} iconBlockchain={iconBlockchain} />
                                                </tr>
                                            ))}

                                        </tbody>
                                    )}
                                    {filteredTokensBalance.length > 0 && (
                                        <tbody>
                                            {/* {filtered.map((balance: CurrentBalance, index1: number) => (
                                            balance.balance.map((bal: BalanceDetail, index2: number) => (
                                                bal?.tokens?.map((token: Token, index3: number) => (
                                                    <tr key={index1 + '_' + index2 + '_' + index3}>
                                                        <RowTokenTable index={(index1 * balance.balance.length * bal.tokens.length) + (index2 * bal.tokens.length) + index3} wallet={balance.wallet} token={token} iconBlockchain={iconBlockchain} />
                                                    </tr>
                                                ))
                                            ))
                                        ))} */}
                                            {filteredTokensBalance?.map((token: Token, index: number) => (
                                                <tr key={index}>
                                                    {/* <RowTokenTable index={index} balance={balance} token={token} iconBlockchain={iconBlockchain} /> */}
                                                    <RowTokenTable index={index} token={token} iconBlockchain={iconBlockchain} />
                                                </tr>
                                            ))}
                                        </tbody>
                                    )}
                                </table>
                            )}
                        </div>
                    </>)}
            </div>
            <Modal
                onHide={() => setShowRefreshModal(false)}
                show={showRefreshModal}
                size="lg"

            >
                <Modal.Header>
                    <Modal.Title>{t('views.balance.modal.title')}</Modal.Title>
                    <button type="button" className="btn-close position-absolute top-0 end-0 me-3 mt-3" onClick={() => { setShowRefreshModal(false) }} aria-label="Close"></button>
                </Modal.Header>
                <div className="container">
                    <div className="p-3 my-4 text-center">
                        <div className="p-3 my-4 text-center">
                            <div className="mb-4">
                                <i className="display-5 text-info-ranking bi bi-check2-square"></i>
                            </div>
                            <h1 className="h2">{t('views.balance.modal.description')}</h1>
                        </div>
                        <br />
                        {true && (<button type="button" className="btn btn-primary"
                            onClick={() => { dispatch(setBalances([])); setLoading(true); setShowRefreshModal(false); }}>{t('views.balance.modal.button')}</button>)}
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Balance;