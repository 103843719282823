import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loadState } from '../../app/sessionHelper';
import { RootState } from '../../app/store';
import { Balance, BLOCKCHAIN, Certificate, CurrentBalance, Inquiry, ModifyToken, Plan, Token } from '../../models/models';
// import { fetchCount } from './userAPI';

export function fetchCount(amount = 1) {
    return new Promise<{ data: number }>((resolve) =>
      setTimeout(() => resolve({ data: amount }), 500)
    );
  }

export enum USER_TYPE {
  FREE,
  ADMIN,
  ENTERPRISE
}

export type WalletInfo = {
  nonce? : string,
  wallet?: string,
  _id?: string,
  status? : string,
  error?: string,
}

export enum CURRENCY {
  EUR,
  USD
}

export type UserData = {
  _id?: string;
  name?: string,
  lastName?: string,
  email? : string, 
  phone?: string,
  timeZoneDefault?: string,
  userType?: USER_TYPE,
  alias?: string,
  addCredits? : number
}

export type InvoiceData = {
  name?: string,
  businessName?: string,
  cif? : string, 
  address?: string,
  zip?: number
}

export type WalletData = {
  wallet? : string,
  alias?: string,
  blockchainsConsulted? : Array<BLOCKCHAIN>
}

export type BetaUser = {
  wallet : string;
  isBeta : boolean;
}

export type ConfigInfo = {
  creditsDefault : string,
  priceCredits: string,
  priceCertificate: string,
  betaPhase : boolean,
  betaUsers : Array<BetaUser>
}

export type UserInfo = {
  _id?: string;
  email?: string,
  name?: string,
  url?: string,
  img? : string,
  banner? : string
  userType?: USER_TYPE;
  wallets? : string[] | any[];
  walletsAssociated? : string[] | any[];
  walletsData?: WalletData[];
  tokensInquiry? : Array<any>;
  creditsTotal? : number;
  creditsConsumed? : number;
  creditsAvailable? : number;
  newUser?: boolean;
  userData?: UserData;
  invoiceData?: InvoiceData;
  alias? : string;
  isBeta?: boolean;
}

export type ResponseWalletAdded = {
  user: UserInfo,
  wallets : string[]
}
export type Detail = {
  title?: string | null;
  desc?: string | null;
  moreInfo?: string | null;
}

export interface UserState {
  configurationApp? : ConfigInfo;
  wallet: string;
  status: 'ok' | 'ko';
  jwt: string;
  userInfo: UserInfo;
  tokens : Token[];
  users: UserInfo[];
  balances : CurrentBalance[];
  tokensBalances : Token[];
  totalFiat : number;
  totalFiatEur : number;
  historic : Inquiry[];
  plans : Plan[];
  error? : Detail;
  notification?: Detail;
  success? : Detail;
  currency : CURRENCY;
  collapsed : boolean;
}

const initialState: UserState =  loadState();

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setWallet: (state, action: PayloadAction<string>) => {
      state.wallet = action.payload;
      },
    setTotalFiat: (state, action: PayloadAction<number>) => {
      state.totalFiat = action.payload;
      },
    setTotalFiatEur: (state, action: PayloadAction<number>) => {
        state.totalFiatEur = action.payload;
      },
    setJWT: (state, action: PayloadAction<string>) => {
      state.jwt = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<UserInfo>) => {
      state.userInfo = action.payload;
    },
    setUserData: (state, action: PayloadAction<UserData>) => {
      state.userInfo.userData = action.payload;
      state.users.forEach( user => {
        if(user._id === action.payload._id){
          user.userData = action.payload;
        }
      })
    },
    setUsers: (state, action: PayloadAction<UserInfo[]>) => {
      state.users = [...action.payload];
    },
    setTimeZone: (state, action: PayloadAction<string>) => {
      if(state.userInfo.userData){
        state.userInfo.userData.timeZoneDefault = action.payload;
      }
    },
    setInvoiceData: (state, action: PayloadAction<InvoiceData>) => {
      state.userInfo.invoiceData = action.payload;
    },
    setBalances: (state, action: PayloadAction<CurrentBalance[]>) => {
      state.balances = [...action.payload];

    },
    setTokens: (state, action: PayloadAction<Token[]>) => {
      state.tokens = [...action.payload];
    },
    setTokensBalances: (state, action: PayloadAction<Token[]>) => {
      state.tokensBalances = [...action.payload];
    },
    modifyToken: (state, action: PayloadAction<ModifyToken>) => {
      state.tokens.forEach( (token : Token) => {
        if(token._id === action.payload._id){
          token.trustLevel = action.payload.trustLevel;
          token.edited = true;
        }
      })
    },
    setHistoric : (state, action: PayloadAction<Inquiry[]>) => {
      state.historic = [...action.payload];
    },
    setPlans : (state, action: PayloadAction<Plan[]>) => {
      state.plans = [...action.payload];
    },
    setNewPlan : (state, action: PayloadAction<Plan[]>) => {
      state.plans = state.plans.concat(action.payload);
    },
    setNewInquiry : (state, action: PayloadAction<Inquiry>) => {
      state.historic = [action.payload].concat(state.historic);
    },
    modifyUserType: (state, action: PayloadAction<UserData>) => {
      state.users.forEach( user => {
        if(user._id === action.payload._id){
          user.userType = Number(action.payload.userType);
          user.alias = action.payload.alias;
          user.creditsTotal = user.creditsTotal && action.payload.addCredits ? user.creditsTotal + action.payload.addCredits : action.payload.addCredits;
          user.creditsAvailable = user.creditsAvailable && action.payload.addCredits ? user.creditsAvailable + action.payload.addCredits : action.payload.addCredits; 
        }
      })
    },
    setAliasWallet: (state, action: PayloadAction<WalletData>) => {
      state.userInfo?.walletsData?.forEach( wall => {
        if(wall.wallet === action.payload.wallet){
          wall.alias = action.payload.alias;
        }
      })
    },
    setNewCertificate : (state, action: PayloadAction<Certificate>) => {
      state.historic.forEach( inquiry => {
        if(inquiry._id === action.payload.inquiryId){
          inquiry.certificate = action.payload;
        }
      })
    },
    setInquiryConfirmed : (state, action: PayloadAction<string>) => {
      state.historic.forEach( (inquiry : Inquiry) => {if(inquiry._id === action.payload) inquiry.consumed = true});
    },
    setInquiryUpdated : (state, action: PayloadAction<{updated : Date, id : string, balances : Balance[]}>) => {
      state.historic.forEach( (inquiry : Inquiry) => { if(inquiry._id === action.payload.id) {
        inquiry.updatedAt = action.payload.updated
        inquiry.balanceResponse = action.payload.balances
      }});
    },
    setInquiryDeleted : (state, action: PayloadAction<string>) => {
      state.historic.forEach( (inquiry : Inquiry) => {if(inquiry._id === action.payload) inquiry.deleted = true});
    },
    setInquiryAlias : (state, action: PayloadAction<any>) => {
      console.log("PAYLOAD INQ ALIAS STORE : ", action.payload);
      state.historic.forEach( (inquiry : Inquiry) => {if(inquiry._id === action.payload.inquiryId) inquiry.alias = action.payload.alias});
    },
    setInquiryDate : (state, action: PayloadAction<any>) => {
      state.historic.forEach( (inquiry : Inquiry) => {if(inquiry._id === action.payload.inquiryId) inquiry.configuration.date = action.payload.date});
    },
    addCreditsConsumed: (state, action: PayloadAction<number>) => {
      state.userInfo.creditsConsumed = state.userInfo.creditsConsumed ? state.userInfo.creditsConsumed + action.payload : action.payload; // ¿Seguro que debe funcionar así?
      // state.userInfo.creditsTotal = state.userInfo.creditsTotal ? state.userInfo.creditsTotal + action.payload : action.payload;
      state.userInfo.creditsAvailable = state.userInfo.creditsAvailable ? state.userInfo.creditsAvailable - action.payload : action.payload;
    },
    addCreditsAvailable: (state, action: PayloadAction<number>) => {
      state.userInfo.creditsTotal = state.userInfo.creditsTotal ? state.userInfo.creditsTotal + action.payload : action.payload
      state.userInfo.creditsAvailable = state.userInfo.creditsAvailable ? state.userInfo.creditsAvailable + action.payload : action.payload;
    },
    addWalletToUser: (state, action: PayloadAction<string>) => {
      if(!state.userInfo.wallets){
        state.userInfo.wallets = [state.wallet];
      }
      state.userInfo.wallets.push(action.payload);
      state.userInfo.walletsData = state.userInfo.walletsData ? state.userInfo.walletsData.concat({ wallet : action.payload }) : [{ wallet : action.payload }];
    },
    addWalletAssociatedToUser: (state, action: PayloadAction<string>) => {
      if(!state.userInfo.walletsAssociated){
        state.userInfo.walletsAssociated = [action.payload];
      }
      state.userInfo.walletsAssociated.push(action.payload);
    },
    setCollapsed: (state, action: PayloadAction<boolean>) => {
      state.collapsed = action.payload;
    },
    setError: (state, action: PayloadAction<Detail>) => {
      state.error = action.payload;
    },
    setSuccess: (state, action: PayloadAction<Detail>) => {
      state.success = action.payload;
    },
    setNotification: (state, action: PayloadAction<Detail>) => {
        state.notification = action.payload;
    },
    cleanError: (state, action: PayloadAction<void>) => {
      state.error = undefined;
    },
    cleanSuccess: (state, action: PayloadAction<void>) => {
      state.success = undefined;
    },
    cleanNotification: (state, action: PayloadAction<void>) => {
      state.notification = undefined;
    },
    setCurrency: (state, action: PayloadAction<CURRENCY>) => {
      state.currency = action.payload;
    },
    logout: (state, action: PayloadAction<void>) => {
      sessionStorage.removeItem('userState');
      return {  collapsed : false, wallet : '', jwt : '', status : 'ko', configurationApp : undefined , userInfo : {email : ''}, totalFiatEur : 0, totalFiat : 0, error : undefined, notification : undefined, tokensBalances : [], balances : [], historic : [], plans : [], tokens : [], users : [], alias : '', currency : CURRENCY.EUR};
    }
  }
});

export const { setWallet, setJWT, setUserInfo,setUserData,setTokens,modifyToken, setTotalFiat, setTotalFiatEur,
                setUsers, setTimeZone, setInvoiceData, setInquiryUpdated, setNewCertificate,setSuccess, setNewInquiry, setAliasWallet,
                setInquiryConfirmed,setInquiryDeleted,setPlans, setNewPlan, addWalletToUser,addWalletAssociatedToUser,setCollapsed,setError,setNotification,
                cleanError,cleanNotification,cleanSuccess,logout, setTokensBalances, setBalances, addCreditsConsumed,setInquiryAlias, setInquiryDate,
                setHistoric,addCreditsAvailable,modifyUserType , setCurrency} = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.user.value)`
export const selectWallet = (state: RootState) => state.user.wallet;
export const selectCollapsed = (state: RootState) => state.user.collapsed;
export const selectConfig = (state : RootState) => state.user.configurationApp;
export const selectJWT = (state: RootState) => state.user.jwt;
export const selectUserInfo = (state: RootState) => state.user.userInfo;
export const selectError = (state: RootState) => state.user.error;
export const selectSuccess = (state: RootState) => state.user.success;
export const selectNotification = (state: RootState) => state.user.notification;
export const selectBalances = (state: RootState) => state.user.balances;
export const selectTokensBalances = (state: RootState) => state.user.tokensBalances;
export const selectHistoric = (state: RootState) => state.user.historic;
export const selectPlans = (state: RootState) => state.user.plans;
export const selectTokens = (state: RootState) => state.user.tokens;
export const selectUsers = (state: RootState) => state.user.users;
export const selectFiat = (state: RootState) => state.user.totalFiat;
export const selectFiatEur = (state: RootState) => state.user.totalFiatEur;
export const selectCurrency = (state: RootState) => state.user.currency;
export const selectCurrencySymbol = (state: RootState) => state.user.currency === CURRENCY.EUR ? '€' : '$';
export const selectWallets = (state : RootState) => state.user.userInfo.walletsData;


export default userSlice.reducer;
