
class AnalyticsService {

    static view( view : string){
        // if(process.env.REACT_APP_ENV_ANALYTICS !== "LOCAL-") amplitude.track(process.env.REACT_APP_ENV_ANALYTICS! + 'View ->' + view);
        //console.log("ANALYTICS VIEW: ", view);
    }

    static click( button : string){
        // if(process.env.REACT_APP_ENV_ANALYTICS !== "LOCAL-") amplitude.track(process.env.REACT_APP_ENV_ANALYTICS! + 'Click ->' + button);
        //console.log("ANALYTICS Click: ", button);
    }

    static modal( modal : string){
        // if(process.env.REACT_APP_ENV_ANALYTICS !== "LOCAL-") amplitude.track(process.env.REACT_APP_ENV_ANALYTICS! + 'Modal ->' + modal);
        //console.log("ANALYTICS Modal: ", modal);
    }

    static error( error : string){
        // if(process.env.REACT_APP_ENV_ANALYTICS !== "LOCAL-") amplitude.track(process.env.REACT_APP_ENV_ANALYTICS! + 'Error ->' + error);
        //console.log("ANALYTICS Error: ", error);
    }

    static transaction( tx: string){
        // if(process.env.REACT_APP_ENV_ANALYTICS !== "LOCAL-") amplitude.track(process.env.REACT_APP_ENV_ANALYTICS! + 'Transaction OK ->' + tx);
        //console.log("ANALYTICS Transaction OK: ", tx);
    }


}

export default AnalyticsService;