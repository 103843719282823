import axios from "axios";
import { Plan, Token } from "../models/models";
import { ConfigInfo, UserData, UserInfo } from "../features/user/user";

export type FileAWS = {
    aws: any, 
    name : string,
    file : any
}


export class AdminService {

    static async createPlan ( plans : Plan[], jwt : string) : Promise<Plan[]> {

        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/plans';
        const resp = await axios.post(url, plans, { headers: { 'authorization': jwt } });
        return resp?.data?.result;

    }

    static async tokens(jwt : string) : Promise<Token[]>{
        
        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/tokens';
        const resp = await axios.get(url, { headers: { 'authorization': jwt } });
        return resp?.data?.result?.tokens;
        
    }

    static async currentConfiguration(jwt : string) : Promise<ConfigInfo>{
        
        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/users/current-configuration';
        const resp = await axios.get(url, { headers: { 'authorization': jwt } });
        return resp?.data?.result?.config;
        
    }

    static async saveConfiguration ( body : any, jwt : string) : Promise<any> {

        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/users/admin-configuration';
        const resp = await axios.post(url, body, { headers: { 'authorization': jwt } });
        return resp?.data?.result;
        
    }

    static async saveUserData ( body : UserData, jwt : string) : Promise<any> {

        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/users/user-data';
        const resp = await axios.post(url, body, { headers: { 'authorization': jwt } });
        return resp?.data?.result;
        
    }

    static async getUsers(jwt : string) : Promise<UserInfo[]> {

        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/users';
        const resp = await axios.get(url, { headers: { 'authorization': jwt } });
        return resp?.data?.result;
    }


    static async uploadFile(certificateId : string , file : any, jwt : string ) : Promise<FileAWS | undefined>{
        const formData = new FormData();
        formData.append("file", file);

        try {
          //console.log("TRY UPLOAD IMAGE", formData);
          const url = process.env.REACT_APP_BASE_URL! + '/api/v1/certificate/signed/' + certificateId;
          const response = await axios.post(url,formData,{ headers: { 'authorization': jwt } });
          return response?.data?.result?.imageS3;
        } catch(error) {
          //console.log(error)
          return undefined;
        }
    }

}