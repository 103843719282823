import axios from "axios";
import { PayInquiryBody, PayPlanBody, ResponseApi } from "../models/models";

class PaymentService {

    static async paid (body : any , jwt: string ) : Promise<any> {
        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/payments/paid';
        const resp = await axios.post(url, body, { headers: { 'authorization': jwt } });
        return resp?.data?.result;
    }

    static async pack(body : PayPlanBody , jwt: string ) : Promise<ResponseApi> {
        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/payments/pack';
        const resp = await axios.post(url, body, { headers: { 'authorization': jwt } });
        return resp?.data?.result;
    }

    static async create(body : PayInquiryBody , jwt: string ) : Promise<ResponseApi> {
        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/payments/create';
        const resp = await axios.post(url, body, { headers: { 'authorization': jwt } });
        return resp?.data?.result;
    }

}

export default PaymentService;