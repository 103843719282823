import axios from "axios";
import { CurrentBalance, Inquiry } from "../models/models";

class BalanceService {

    static async currentBalance( jwt : string) : Promise<CurrentBalance[]>{
        
        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/balanceshot/current-balance';
        const resp = await axios.get(url, { headers: { 'authorization': jwt } });
        return resp?.data?.result?.balances;
        
    }

    static async currentFiat( body : any, jwt : string) : Promise<CurrentBalance[]>{
        
        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/balanceshot/current-fiat';
        const resp = await axios.post(url,body, { headers: { 'authorization': jwt } });
        return resp?.data?.result?.balances;
        
    }
    
    static async balance (body : any , jwt: string ) : Promise<any>{
        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/balanceshot/balance';
        const resp = await axios.post(url, body, { headers: { 'authorization': jwt } });
        //console.log("RESP INQUIRY", resp);
        return resp?.data?.result;
    }

    static async regenerateBalance (body : any , jwt: string ) : Promise<any>{
        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/balanceshot/regenerate/balance';
        const resp = await axios.post(url, body, { headers: { 'authorization': jwt } });
        return resp?.data?.result;
    }

    static async quotes(body : any, jwt : string) : Promise<[]>{
        // console.log("GET QUOTES:", body);
        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/tokens/quote';
        const resp = await axios.post(url,body, { headers: { 'authorization': jwt } });
        return resp?.data?.result;
    }

    static async inquiry (body : any , jwt: string ) : Promise<any>{
        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/balanceshot/inquiry';
        const resp = await axios.post(url, body, { headers: { 'authorization': jwt } });
        //console.log("RESP INQUIRY", resp);
        return resp?.data?.result;
    }

    static async similarRequests (body : any , jwt: string ) : Promise<any>{
        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/balanceshot/similar-requests';
        const resp = await axios.post(url, body, { headers: { 'authorization': jwt } });
        //console.log("RESP INQUIRY", resp);
        return resp?.data?.result.inquiries;
    }

    static async deleteInquiry (idInquiry : string , jwt: string ) : Promise<any>{
        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/balanceshot/delete/inquiry';
        const resp = await axios.post(url, { idInquiry }, { headers: { 'authorization': jwt } });
        //console.log("RESP INQUIRY", resp);
        return resp?.data?.result;
    }

    static async editInquiry (body : any , jwt: string ) : Promise<any>{
        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/balanceshot/edit/inquiry';
        const resp = await axios.post(url, body, { headers: { 'authorization': jwt } });
        //console.log("RESP INQUIRY", resp);
        return resp?.data?.result;
    }

    static async historic(jwt : string) : Promise<Inquiry[]>{
        
        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/balanceshot/historic';
        const resp = await axios.get(url, { headers: { 'authorization': jwt } });
        return resp?.data?.result?.inquiries;
        
    }

    static async certificate (inquiryId : any , jwt: string ) : Promise<any>{
        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/certificate/create/' + inquiryId;
        const resp = await axios.get(url, { headers: { 'authorization': jwt } });
        //console.log("RESP INQUIRY", resp);
        return resp?.data?.result;
    }

    static async refreshCertificate (inquiryId : any , jwt: string ) : Promise<any>{
        const url: string = process.env.REACT_APP_BASE_URL! + '/api/v1/certificate/regenerate/' + inquiryId;
        const resp = await axios.get(url, { headers: { 'authorization': jwt } });
        //console.log("RESP INQUIRY", resp);
        return resp?.data?.result;
    }


}

export default BalanceService;