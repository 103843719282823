import TypeTokenRow from "./TypeTokenRow";
import { BLOCKCHAIN, Token } from "../models/models";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../features/user/user";
import { useTranslation } from "react-i18next";
import { useState } from "react";

type TokensTableProps = {
    tokens: Token[],
    iconBlockchain: any,
    setIdToken: any,
    setShowTokenModal: any,
    setInfoSelected: any,
    setShowModal: any,
    filterText: string,
    setFilterText: any,
    someToken: boolean,
    filterId: string
}

function TokensTable({ tokens, iconBlockchain, setIdToken, setShowTokenModal, setInfoSelected, setShowModal, filterText, setFilterText, filterId, someToken }: TokensTableProps) {


    const userInfo = useSelector(selectUserInfo);
    const { t } = useTranslation('common');

    const getUrl = (token: Token) => {

        return token.address ?
            token.blockchain === BLOCKCHAIN.ETHEREUM ? 'https://etherscan.io/address/' + token.address :
                token.blockchain === BLOCKCHAIN.POLYGON ? 'https://polygonscan.com/address/' + token.address :
                    'https://bscscan.com/address/' + token.address : '';

    }

    const getLiteral = (pathLiteral: string): string => {

        return t(pathLiteral) ? t(pathLiteral) : '';
    }

    const filterToken = (token: Token) => {
        const isFiltered = (filterText !== '' && (token.name?.toUpperCase().includes(filterText.toUpperCase()) ||
            token.symbol?.toUpperCase().includes(filterText.toUpperCase()) ||
            token.address?.toUpperCase().includes(filterText.toUpperCase()))) ||
            (filterId !== '' && filterId === token._id);

        if (isFiltered) {
            console.log("FILTER: ", filterText, ' id: ', filterId);
            console.log("token: ", token);
        }
        return (filterText === '' && filterId === '') || isFiltered;

    }



    return (<>
        <table className="table table-striped table-hover table-borderless align-middle">
            <thead className="sticky-top">
                <tr className="text-light-emphasis text-center">
                    <th scope="col">{t('views.tokens.blockchain')}</th>
                    <th scope="col">{t('views.tokens.isContract')}</th>
                    <th scope="col">{t('views.tokens.symbol')}</th>
                    <th scope="col">{t('views.tokens.name')}</th>
                    <th scope="col">{t('views.tokens.address')}</th>
                    <th scope="col">{t('views.tokens.trustLevel')}</th>
                    <th scope="col">{t('views.tokens.mainAsset')}</th>
                    <th scope="col">{t('views.tokens.edit')}</th>
                </tr>
            </thead>
            <tbody>
                {tokens?.map((token: Token, index: number) => (
                    (filterToken(token) && (
                        <tr key={index} className="text-center">
                            {/* <th scope="row" className="text-center fw-light px-2">{index + 1}</th> */}
                            <td className="text-nowrap">
                                <span className="btn-image-md">
                                    <img src={iconBlockchain(token.blockchain)} alt="Imagen" />
                                </span>
                            </td>
                            <td className="text-nowrap">
                                <span className="btn-image-md">
                                    <span className="ms-2">
                                        {token.isContract ? (<i className="bi bi-check-circle icon-md"></i>) :
                                            (<i className="bi bi-x-octagon icon-md" data-toggle="tooltip" data-placement="top" title={'Is not contract'}></i>)}
                                    </span>
                                </span>

                            </td>
                            <td className="text-nowrap" style={{ maxWidth: '100px' }}>
                                <span className="btn-image-md" data-toggle="tooltip" data-placement="top" title={token.symbol}>
                                    <span className="ms-2" >
                                        {token.symbol?.substring(0, 8)}{token.symbol.length > 8 ? '...' : ''}
                                    </span>
                                </span>
                            </td>
                            <td className="text-nowrap" style={{ maxWidth: '100px' }} data-toggle="tooltip" data-placement="top" title={token.name}>
                                <span className="btn-image-md">
                                    <span className="ms-2" data-toggle="tooltip" data-placement="top" title={token.name} >{token.name?.substring(0, 8)}{token.name.length > 10 ? '...' : ''}</span>

                                </span>
                            </td>

                            <td className="text-nowrap">
                                <span data-toggle="tooltip" data-placement="top" title={token.address}>{token.address && token.address.length > 20
                                    ? `${token.address?.slice(0, 4)}. . .${token.address?.slice(-4)}`
                                    : 'Native Asset'}&nbsp;&nbsp;</span>
                                {token.address && (<i className="bi bi-box-arrow-up-right pointer" onClick={() => window.open(getUrl(token), '_blank')}></i>)}&nbsp;&nbsp;
                                {token.address && (<i className="bi bi-clipboard-check-fill pointer" onClick={() => navigator.clipboard.writeText(getUrl(token))}></i>)}
                            </td>

                            <TypeTokenRow showEdit={false} id={token._id} trustLevel={token.trustLevel} userType={userInfo.userType} setIdToken={setIdToken} setShowTokenModal={setShowTokenModal}></TypeTokenRow>

                            <td className="">
                                <div className="d-grid">
                                    <div className="btn-group" role="group" aria-label="Certificado Pendiente">

                                        {token.parentToken && (<>
                                            <button type="button" className="btn btn-outline-primary pe-none border-end-none" data-toggle="tooltip" data-placement="top" title={getLiteral('views.balance.linkButton')}><i className="bi bi-info-square pointer" ></i></button>
                                            <button type="button" className="btn btn-primary border-start-none text-start" onClick={() => { setInfoSelected(tokens.find(t => t.address === token.parentToken)); setShowModal(true) }}>Info</button>
                                        </>
                                        )}
                                    </div>
                                </div>
                            </td>
                            <td className="">
                                <div className="d-grid">
                                    <div className="btn-group" role="group" aria-label="Generar Certificado">
                                        <button type="button" className="btn btn-outline-primary pe-none border-end-none pointer"><i data-toggle="tooltip" data-placement="top" title={getLiteral('views.balance.linkButton')} className="bi bi-pencil-square"></i></button>
                                        <button type="button" className="btn btn-primary border-start-none text-start"
                                            onClick={() => { setIdToken(token?._id ? token._id : ''); setShowTokenModal(true) }}
                                        >{t('components.tokensTable.edit')}</button>
                                    </div>
                                </div>
                            </td>

                        </tr>))
                ))}

            </tbody>
        </table>
        {!someToken && filterText !== '' && (

            <div className="row row-gap-3 d-flex justify-content-end">
                <div className="col-12 my-auto text-center mt-5">
                    <h3>{t('views.tokens.noTokenFilters')}</h3>
                    <button className="m-2 btn btn-light text-nowrap" onClick={() => { setFilterText(''); }}>
                        <i className="bi bi-person-dash ">&nbsp;&nbsp;</i>
                        <span className="ms-2">{t('views.users.buttonNoFilter')}</span>
                    </button>
                </div>
            </div>
        )}
    </>
    )
}

export default TokensTable;