
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectJWT, selectPlans, selectUserInfo, setError, setPlans, setSuccess, setUserData } from "../features/user/user";
import { PAYMENT_TYPE, PAYMENT_TYPE_API, PaymentData, Plan, ResponseApi } from "../models/models";
// import { Link } from "react-router-dom";
import { UserService } from "../services/user.service";
import { useAppDispatch } from "../app/hooks";
import PaymentService from "../services/payment.service";
import FormProfile from "./FormProfile";
import { useTranslation } from "react-i18next";
import Spinner from "./Spinner";

type SpendChosen = {
    credits: boolean,
    purchaseInquiry: boolean,
    purchasePack: boolean,
    isPayingInquiry: boolean
}

type PaymentMethoProps = {
    paymentData? : PaymentData,
    inquiryId?: string,
    certificateId?: string,
    planId?: string,
    credits: number,
    setModalPayment: any,
    setInquiryId: any,
    setCorrectPayment: any,
    isPayingInquiry: boolean
}

function PaymentMethod({inquiryId,paymentData,credits,certificateId,setModalPayment, setInquiryId, setCorrectPayment,planId, isPayingInquiry }: PaymentMethoProps) {

    const [step, setStep] = useState<number>(1);
    const userInfo = useSelector(selectUserInfo);
    const [userData,setUserDataLocal] = useState<any>({...userInfo.userData, correctData: true});
    const jwt = useSelector(selectJWT);
    const plansStore = useSelector(selectPlans);
    const [plans, setPlansLocal] = useState<Plan[]>([]);
    const [planChoosen, setPlanChoosen] = useState<string>();
    const [spendChosen, setSpendChosen] = useState<SpendChosen>({ credits: false, purchaseInquiry: false, purchasePack: false, isPayingInquiry: false });
    const [paymentType, setPaymentType] = useState<PAYMENT_TYPE>(PAYMENT_TYPE.PENDING);
    const [warning, setWarning] = useState<string>();
    // const [showModalUserData, setShowModalUserData] = useState<boolean>(false);
    const [stripePayment , setStripePayment] = useState<PaymentData>();
    const dispatch = useAppDispatch();
    const [spinner , setSpinner] = useState<boolean>(false);
    // const navigate = useNavigate();
    const { t } = useTranslation('common');

    const stripePromise = loadStripe("pk_test_51MlRzyAc6VVnGvMMuk3ExxhMfWtPLdaONN4vpEmnjg06WTnqq34fwabLRNEIHXSVBcSXJgaI6CdlO9BfarpIKrfc00Z63wv2JS");

    const planPayment = async ( payType : PAYMENT_TYPE) => {
        if(planId){
            setPlanChoosen(planId);
            payPack(planId, payType);
            return;
        }
        else{setStep(1)}
    }

    const chooseCrypto = async () => {
        // console.log("CHOOSE CRYPTO");
        if (!spendChosen.credits && !spendChosen.purchaseInquiry && !spendChosen.purchasePack) {
            setWarning("Por favor, elige una compra");
            return;
        }

        setWarning('');
        
        if (spendChosen.purchasePack) {
            callPlans();
            setStep(2);
            return;
        }
        
        if (spendChosen.purchaseInquiry && paymentType === PAYMENT_TYPE.CRYPTO) {
            if(!userInfo.userData?.lastName){
                setWarning("Utrust solicita tus datos personales para llevar a cabo el pago desde su plataforma.");
                setStep(3);
                return;
            }
            const packPayment : ResponseApi = await PaymentService.create({
                paymentType : PAYMENT_TYPE_API.CRYPTO,
                inquiryId,
                certificateId 
            }, jwt);
            generatePayment(packPayment);
            
            return;
        }


    }

    const chooseCard = async () => {
        
        // console.log("AQUI")
        if (!spendChosen.credits && !spendChosen.purchaseInquiry && !spendChosen.purchasePack) {
            setWarning("Por favor, elige una compra");
            return;
        }

        if (spendChosen.purchasePack) {
            callPlans();
            setStep(2);
            return;
        }
        if (spendChosen.purchaseInquiry && paymentType === PAYMENT_TYPE.CARD) {
            const packPayment : ResponseApi = await PaymentService.create({
                paymentType : paymentType === PAYMENT_TYPE.CARD ? PAYMENT_TYPE_API.CARD : PAYMENT_TYPE_API.CRYPTO,
                inquiryId , certificateId
            }, jwt);
            generatePayment(packPayment);
            setStep(3);
            return;
        }
        
    }

    const generatePayment = ( paymentData : ResponseApi) => {
        
        // console.log("GEN PAY: ", paymentData , " tipe :", paymentType);

        if(paymentData.paymentData){
            setStripePayment(paymentData.paymentData);
            setStep(3);
            return;
        }

        if(paymentData.cryptoPaymentData){
            window.open(
                paymentData.cryptoPaymentData.redirectUrl,
                '_blank' // <- This is what makes it open in a new window.
              );
            setModalPayment(false);
            dispatch(setSuccess({title : "Crypto payment", desc : "Al finalizar el pago en utrust, una vez se valide el pago (Puede tardar hasta 2 horas), podrás acceder a tu histórico y ver tu consulta"}))
            return;
        }

        dispatch(setError({ title: 'Payment error', desc: 'Error has ocurred trying pay your pack' }));

    }

    const callPlans = async () => {

        try {

            if (plansStore.length > 0) {
                setPlansLocal(plansStore);
                return;
            }

            const planList: Plan[] = await UserService.getPlans(jwt);
            dispatch(setPlans(planList));
            setPlansLocal(planList);
            return planList;
        } catch (error: any) {
            setWarning('Error al obtener los packs disponibles');
            return undefined;
        }

    }

    const payPack = async ( plan? : string, payType?: PAYMENT_TYPE) => {

        setSpinner(true);

        if(spendChosen.purchasePack && !planChoosen){
            setWarning('Por favor, elige un pack de créditos');
            return;
        }

        // TODO REVISAR FLUJO BRYAN
        // if(!userData?.name || !userData?.lastName || !userData?.email){
        //     setWarning("Add your personal data");
        //     setStep(3);
        //     return;
        // }

        try {
            const paymentMethod = payType !== undefined ? payType : paymentType;
            setPaymentType(paymentMethod);
            const packPayment : ResponseApi = await PaymentService.pack({
                paymentType : paymentMethod === PAYMENT_TYPE.CARD ? PAYMENT_TYPE_API.CARD : PAYMENT_TYPE_API.CRYPTO,
                packId : plan ? plan : planChoosen ? planChoosen : '',
                userData
            }, jwt);

            setSpinner(false);

            if(userData.saveData){
                dispatch(setUserData(userData));
            }
    
    
            generatePayment(packPayment);
        } catch (error) {
            dispatch(setError({ title: 'Payment error', desc: 'Error has ocurred trying pay your pack' }));
        }

        

    }

    // const payByCard = () => {
        
    // }
    
    const payByCripto = async () => {
        console.log("PAY CRYPTO", userData)
        if(!userData?.name || !userData?.lastName || !userData?.email){
            setWarning("Add your personal data");
            return;
        }

        if(planChoosen){
            payPack();
            return
        }

        const inquiryPayment : ResponseApi = await PaymentService.create({
            paymentType : PAYMENT_TYPE_API.CRYPTO,
            inquiryId,certificateId,
            userData
        }, jwt);
        
        generatePayment(inquiryPayment);
    }

    return (
        <div className="container">
            {(inquiryId || certificateId || planId) && (
                <>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            
                            <li className="breadcrumb-item" ><span>Método de pago</span></li>
                            {step >= 1  && (<li className="breadcrumb-item pointer" style={{textDecoration : step === 1 ? 'underline' : ''}} onClick={ () => setStep(1)}><span>{paymentType === PAYMENT_TYPE.CARD ? 'Pago con tarjeta' : 'Pago con criptomonedas'}</span></li>
                            )}
                            {/* <li className="breadcrumb-item active" aria-current="page"></li> */}
                            {/* {step === 1 ? (<li className="breadcrumb-item"><a href="#">Tipo de compra</a></li>) : 
                                (<li className="breadcrumb-item"><a href="#">{spendChosen.purchaseInquiry === true ? 'Pagar petición' : 'Pagar pack'}</a></li>
                            )} */}
                            {step === 2 && spendChosen.purchasePack === true &&  (<li className="breadcrumb-item active pointer" aria-current="page" onClick={ () => setStep(2)}><span>Elección pack</span></li>)}
                            {step === 3 && (<li className="breadcrumb-item activ pointer" style={{textDecoration : 'underline'}} aria-current="page" onClick={ () => setStep(3)}><span>Realizar pago</span></li>)}
                        </ol>
                    </nav>
                    <div className="p-3 my-4 text-center">
                        <div className="mb-4">
                            <i className="display-5 text-info-ranking bi bi-cart-check"></i>
                        </div>
                        <h1 className="h2">{t('components.paymentMethod.paymentType')}</h1>
                        <form className="mt-4 list-group-radio">
                            <div className="row text-center">
                                <input className="form-check-input" type="radio" name="listGroupRadioGrid" id="listGroupRadioGrid1" value=""
                                    onChange={() => {}} checked={paymentType === PAYMENT_TYPE.CARD} hidden />
                                <label className="list-group-item col d-grid border pt-3 m-1 mb-4" id="listGroupRadioGrid1"
                                    onClick={() => { setPaymentType(PAYMENT_TYPE.CARD); setWarning(''); planPayment(PAYMENT_TYPE.CARD); }}>
                                    <h3 className="h5">{t('components.paymentMethod.immediatePayment')}</h3>
                                    <p className="text-muted fst-italic fw-light"></p>
                                </label>
                                <input className="form-check-input" type="radio" name="listGroupRadioGrid" id="listGroupRadioGrid2" value=""
                                    onChange={() => {}} checked={paymentType === PAYMENT_TYPE.CRYPTO} hidden disabled />
                                <label className="list-group-item col d-grid border pt-3 m-1 mb-4" id="listGroupRadioGrid2"
                                    onClick={() => { setPaymentType(PAYMENT_TYPE.CRYPTO); setWarning("Debes tener en cuenta que tu compra puede tardar varios minutos en completarse. Tras finalizar el pago, podrás consultar el estado de tu consulta en la sección de historico.") ; planPayment(PAYMENT_TYPE.CRYPTO);  }} >
                                    <h3 className="h5">{t('components.paymentMethod.cryptoPayment')}</h3>
                                </label>
                            </div>
                        </form>
                        {warning !== '' && (
                        <div className="col-12">
                            <h3 className="h6 red">{warning}</h3>
                        </div>)}
                    </div>
                    
                </>
            )}
            {spinner && (
                <Spinner></Spinner>
            )}
            {step === 1 && paymentType === PAYMENT_TYPE.CARD && (inquiryId || certificateId) && (
                // <div className="container">
                <div className="p-3 my-4 text-center">
                    <h1 className="h2">{t('components.paymentMethod.choosePurchase')}</h1>
                    <form className="mt-4 list-group-radio">
                        <div className="row text-center">
                            {/* <input className="form-check-input" type="radio" name="listGroupRadioGrid" id="listGroupRadioGrid1" value="" 
                                    onChange={() => {}} checked={(userInfo?.creditsAvailable && userInfo.creditsAvailable >= credits) || spendChosen.credits ? true : false} 
                                    disabled={checkCredits()} hidden />
                                <label className="list-group-item col d-grid border pt-3 m-1 mb-4" id="listGroupRadioGrid1" 
                                    onClick={() => {setSpendChosen({purchaseInquiry : false, purchasePack : false, credits : !spendChosen.credits})}}>
                                    <h3 className="h6">Spend my credits</h3>
                                    <p className="text-muted fst-italic fw-light">{userInfo.creditsAvailable && userInfo.creditsAvailable >= 0 ? userInfo.creditsAvailable : 0 }</p>
                                </label> */}
                            <input className="form-check-input" type="radio" name="listGroupRadioGrid" id="listGroupRadioGrid2" value="" hidden
                                onChange={() => {}} checked={spendChosen.purchaseInquiry} />
                            <label className="list-group-item col d-grid border pt-3 m-1 mb-4" id="listGroupRadioGrid2"
                                onClick={() => { setSpendChosen({ purchasePack: false, credits: false, purchaseInquiry: !spendChosen.purchaseInquiry, isPayingInquiry: false }) }} >
                                <h3 className="h6">{t('components.paymentMethod.inquiryPurchase')}</h3>
                                <p className="text-muted fst-italic fw-light">{credits} €</p>
                            </label>
                            <input className="form-check-input" type="radio" name="listGroupRadioGrid" id="listGroupRadioGrid3" value="" hidden
                                onChange={() => {}} checked={spendChosen.purchasePack} />
                            <label className="list-group-item col d-grid border pt-3 m-1 mb-4" id="listGroupRadioGrid3"
                                onClick={() => { setSpendChosen({ credits: false, purchaseInquiry: false, purchasePack: !spendChosen.purchasePack, isPayingInquiry: false }) }} >
                                <h3 className="h6 m-3">{t('components.paymentMethod.creditsPurchase')}</h3>
                                <p className="text-muted fst-italic fw-light"></p>
                            </label>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-6 d-grid">
                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={chooseCard}>
                                    {t('components.paymentMethod.cardPayment')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                // </div>
            )}
            {step === 1 && paymentType === PAYMENT_TYPE.CRYPTO && (inquiryId || certificateId) && (
                // <div className="container">
                <div className="p-3 my-4 text-center">
                    <h1 className="h2">{t('components.paymentMethod.choosePurchase')}</h1>
                    <form className="mt-4 list-group-radio">
                        <div className="row text-center">
                            <input className="form-check-input" type="radio" name="listGroupRadioGrid" id="listGroupRadioGrid2" value="" hidden
                                onChange={() => {}} checked={spendChosen.purchaseInquiry} />
                            <label className="list-group-item col d-grid border pt-3 m-1 mb-4" id="listGroupRadioGrid2"
                                onClick={() => { setSpendChosen({ purchasePack: false, credits: false, purchaseInquiry: !spendChosen.purchaseInquiry, isPayingInquiry: false }) }} >
                                <h3 className="h6">{t('components.paymentMethod.inquiryPurchase')}</h3>
                                <p className="text-muted fst-italic fw-light">{credits} €</p>
                            </label>
                            <input className="form-check-input" type="radio" name="listGroupRadioGrid" id="listGroupRadioGrid3" value="" hidden
                                onChange={() => {}} checked={spendChosen.purchasePack} />
                            <label className="list-group-item col d-grid border pt-3 m-1 mb-4" id="listGroupRadioGrid3"
                                onClick={() => { setSpendChosen({ credits: false, purchaseInquiry: false, purchasePack: !spendChosen.purchasePack, isPayingInquiry: false }) }} >
                                <h3 className="h6 m-3">{t('components.paymentMethod.creditsPurchase')}</h3>
                                <p className="text-muted fst-italic fw-light"></p>
                            </label>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-6 d-grid">
                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={chooseCrypto}>
                                    {t('components.paymentMethod.cryptoPayment')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                // </div>
            )}
            {step === 2 && (
                <div className="p-3 my-4 text-center">
                    <h1 className="h2">{t('components.paymentMethod.choosePack')}</h1>
                    <form className="mt-4 list-group-radio">
                        <div className="row text-center d-flex justify-content-center">
                            {plans.map((plan: Plan, index: number) => (
                                <div key={index} className="col-4" >
                                    <input className="form-check-input" type="radio" name="listGroupRadioGrid" id="listGroupRadioGrid2" value="" hidden
                                        onChange={() => {}} checked={plan._id === planChoosen} />
                                    <label className="list-group-item col d-grid border pt-3 m-1 mb-4" id="listGroupRadioGrid2"
                                        onClick={() => {setPlanChoosen(plan._id) }} >
                                        <h3 className="h6">{plan.publicName}</h3>
                                        <p className=" fw-light">{plan.credits}{t('components.paymentMethod.credits')}</p>
                                        <p className="text-muted fw-light">{plan.price} €</p>
                                    </label>
                                </div>
                            ))}
                        </div>
                    </form>
                    <div className="row d-flex justify-content-center">
                        <div className="col-6 d-grid">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => payPack()}>
                                {t('components.paymentMethod.paymentBy')}{paymentType === PAYMENT_TYPE.CRYPTO ? 'Cryptocurrency' : 'Card'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {step === 3 && paymentType === PAYMENT_TYPE.CRYPTO && (
                <>
                <div className="row d-flex justify-content-center">
                        <div className="col-6 d-grid">
                            <FormProfile  showSaveButton={false} showSaveData={true} userData={userData} setUserDataLocal={setUserDataLocal}/>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={payByCripto}>
                                {t('components.paymentMethod.byCrypto')}
                            </button>
                        </div>
                </div>
                </>
            )}
            {step === 3 && paymentType === PAYMENT_TYPE.CARD && (
                <>
                    { (paymentData?.amount || stripePayment?.amount || planId) && (
                        <>
                            {/* <div className="container"> */}
                            <div className="p-3 my-4 text-center">
                                {/* <h1 className="h2">Pay by Card</h1> */}
                                <div className="mb-4">
                                    <i className="display-5 text-info-ranking bi bi-credit-card"></i>
                                </div>

                                {stripePayment && (inquiryId || certificateId || planId) && credits && (<>
                                    <h2>{t('components.paymentMethod.price')}{stripePayment?.amount ? (stripePayment.amount/100) : credits} €</h2>
                                    <Elements options={{ clientSecret:  stripePayment.client_secret, appearance: { theme: "flat" } }} stripe={stripePromise}>
                                        <CheckoutForm paymentData={stripePayment }
                                            inquiryId={inquiryId}
                                            certificateId={certificateId}
                                            planId={planId}
                                            credits={credits}
                                            setModalPayment={setModalPayment}
                                            setInquiryId={setInquiryId}
                                            setCorrectPayment={setCorrectPayment}
                                            isPayingInquiry={isPayingInquiry}
                                        />
                                    </Elements>
                                    </>
                                )}
                                
                            </div>
                            {/* </div> */}

                        </>
                    )}
                </>
            )}
        </div>
    )
}

export default PaymentMethod;