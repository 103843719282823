import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectJWT, selectUserInfo, selectUsers, setError, setInquiryAlias, setInquiryDeleted, setSuccess, USER_TYPE, UserInfo, WalletData } from "../features/user/user";
import { BLOCKCHAIN, Inquiry, Token } from "../models/models";
import { useState } from "react";
import BalanceService from "../services/balance.service";
import { useAppDispatch } from "../app/hooks";
import { Button, Form, Modal } from "react-bootstrap";

type HistoricTableProps = {
    historic: Inquiry[];
    getDetails: any;
    setInquiryId: any;
    iconBlockchain: any;
    showModalCertificate: any
    showModalUploadCertificate: any;
    enableAllFilters: any;
}

const getUrl = (token?: Token, wallet?: string): string => {

    const address = wallet ? wallet : token?.address;

    return address ?
        token?.blockchain === BLOCKCHAIN.ETHEREUM ? 'https://etherscan.io/address/' + wallet :
            token?.blockchain === BLOCKCHAIN.POLYGON ? 'https://polygonscan.com/address/' + wallet :
                'https://bscscan.com/address/' + wallet : '';

}

function HistoricTable({ historic, getDetails, setInquiryId, iconBlockchain, showModalCertificate, showModalUploadCertificate, enableAllFilters }: HistoricTableProps) {


    const jwt = useSelector(selectJWT);
    const userInfo = useSelector(selectUserInfo);
    const usersStore = useSelector(selectUsers);
    const { t } = useTranslation('common');
    const [walletCopied, setWalletCopied] = useState<string>();
    const dispatch = useAppDispatch();
    const [modalDelete, setModalDelete] = useState<boolean>(false);
    const [modalEdit, setModalEdit] = useState<boolean>(false);
    const [inqIdToDelete, setInqIdToDelete] = useState<string>();
    const [inqIdToEdit, setInqIdToEdit] = useState<string>();

    const getAliasWallet = (wallet: string) => {
        if (!wallet) return;
        const wInfo = userInfo.walletsData?.find((wData: WalletData) => wData.wallet === wallet);
        return wInfo?.alias ? wInfo.alias : `${wallet?.slice(0, 6)}...${wallet?.slice(-4)}`;
    }

    const getAliasWalletFromUser = (wallet: string, userId: string) => {
        if(!wallet) return;
        const usInfo = usersStore?.find((user: UserInfo) => user._id === userId);
        const wInfo = usInfo?.wallets?.find((wall: WalletData) => wall.wallet === wallet);
        // return wInfo?.alias ?  `${wInfo.alias} ${wallet?.slice(0, 6)}...${wallet?.slice(-4)}` : `${wallet?.slice(0, 6)}...${wallet?.slice(-4)}`;
        return wInfo?.alias ? `${wInfo.alias}` : `${wallet?.slice(0, 6)}...${wallet?.slice(-4)}`;
    }

    const copyWallet = (wallet: string) => {
        //console.log("ELIJO WALLET: ", userInfo.wallets ? userInfo.wallets[index]  : 'error');
        navigator.clipboard.writeText(wallet);
        setWalletCopied(wallet);
        setTimeout(() => setWalletCopied(undefined), 3000);
    };

    const getLiteral = (pathLiteral: string): string => {

        return t(pathLiteral) ? t(pathLiteral) : '';
    }

    const handleFormConfig = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        const target = event.target as typeof event.target & {
            alias: { value: string };
        };

        try {
            if (inqIdToEdit && target.alias.value) {

                const inq = await BalanceService.editInquiry({ inquiryId: inqIdToEdit, alias: target.alias.value }, jwt);
                if (!inq || !inq.edited) {
                    throw new Error("Some error");
                }

                if (inq.edited) {
                    dispatch(setInquiryAlias({ inquiryId: inqIdToEdit, alias: target.alias.value }));
                    dispatch(setSuccess({ title: t('messagesSucces.editInquiry.title'), desc: t('messagesSucces.editInquiry.description') }));
                    setModalEdit(false);
                    return;
                }

                return;
            }

            throw new Error("Some error");

        } catch (error) {
            dispatch(setError({
                title: t('messagesFail.editInquiry.title'),
                desc: t('messagesFail.editInquiry.description')
            }));
        }


    };


    const deleteInquiry = async (inquiryId: string) => {

        try {
            if (!inquiryId) throw new Error("Error getting inquiry id");

            const inq = await BalanceService.deleteInquiry(inquiryId, jwt);

            if (inq.deleted) {
                dispatch(setInquiryDeleted(inquiryId));
                dispatch(setSuccess({ title: t('messagesSucces.deleteInquiry.title'), desc: t('messagesSucces.deleteInquiry.description') }));
                setModalDelete(false);
                return;
            }

            throw new Error("Some error");
        } catch (error) {
            setModalDelete(false);
            dispatch(setError({
                title: t('messagesFail.deleteInquiry.title'),
                desc: t('messagesFail.deleteInquiry.description')
            }));
        }
    }


    return (
        <>
            <div className="row mt-2 rounded-bottom-4 table-responsive z-0 px-1 text-light-emphasis bg-light-subtle">
                {historic?.length > 0 && (
                    <table className="table table-striped table-hover table-borderless align-middle">
                        <thead className="sticky-top">
                            <tr className="text-light-emphasis">
                                <th scope="col">{t('components.historicTable.blockchains')}</th>
                                <th scope="col">{t('components.historicTable.date')}</th>
                                <th scope="col">{t('components.historicTable.credits')}</th>
                                {userInfo.userType === USER_TYPE.ADMIN ? (
                                    <th scope="col">{t('components.historicTable.user')}</th>
                                ) : <th scope="col">{t('components.historicTable.wallet')}</th>}
                                <th scope="col">{t('components.historicTable.inquiryDate')}</th>
                                <th scope="col">{t('components.historicTable.timezone')}</th>
                                <th scope="col">{t('components.historicTable.balance')}</th>
                                <th scope="col">{t('components.historicTable.certificate')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {historic.map((inquiry: Inquiry, index: number) => (
                                !inquiry.deleted && (<tr key={index}>
                                    {inquiry.configuration ? (
                                        <td className="text-nowrap" style={{ maxWidth: '90px' }}>
                                            <span className="btn-image-md">
                                                {inquiry.configuration.blockchains.map((bc, index) => (
                                                    <img className="m-1" key={index} src={iconBlockchain(bc)} alt="Imagen" />
                                                ))}
                                            </span>

                                        </td>
                                    ) : (
                                        <td className="text-nowrap">{t('components.historicTable.unknown')}</td>
                                    )}

                                    <td className="text-nowrap" style={{ maxWidth: '90px' }} data-toggle="tooltip" data-placement="top" title={new Date(inquiry.updatedAt).toLocaleString()} >
                                        <div>
                                            {inquiry.alias ? (
                                                <>
                                                    {inquiry.alias}<br/>{new Date(inquiry.updatedAt).toDateString()}&nbsp;&nbsp;
                                                    <button type="button" className="btn btn-outline-primary pointer" onClick={() => { setInqIdToEdit(inquiry._id); setModalEdit(true); }}>
                                                        <i data-toggle="tooltip" data-placement="top" title={'edit'} className="bi bi-pencil-square"></i>
                                                        
                                                    </button>
                                                </>

                                            ) : (
                                                <>
                                                    {new Date(inquiry.updatedAt).toDateString()} &nbsp;
                                                    <button type="button" className="btn btn-outline-primary pointer" onClick={() => { setInqIdToEdit(inquiry._id); setModalEdit(true); }}>
                                                        <i data-toggle="tooltip" data-placement="top" title={'edit'} className="bi bi-pencil-square"></i>
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </td>
                                    {/* <td className="text-nowrap" style={{ maxWidth: '20px' }}>{inquiry.consumed ? "YES" : "NO"}</td> */}
                                    <td className="text-nowrap text-center" style={{ maxWidth: '20px' }}>{inquiry.credits}</td>

                                    {
                                        userInfo.userType === USER_TYPE.ADMIN ? (
                                            <th scope="col">
                                                {inquiry.configuration.wallets.map((str, index) => (
                                                    <div key={index}>
                                                        {getAliasWalletFromUser(str, inquiry.userId)}&nbsp;&nbsp;
                                                        <i onClick={() => { copyWallet(str) }} className={walletCopied === str ? "bi bi-clipboard-check-fill" : "bi bi-clipboard-plus"} data-toggle="tooltip" data-placement="top" title={getLiteral('views.balance.linkButton')}></i>&nbsp;&nbsp;
                                                        {/* <i className="bi bi-box-arrow-up-right pointer" data-toggle="tooltip" data-placement="top" title={getLiteral('views.balance.linkButton')} onClick={ () => window.open(getUrl(undefined,str),'_blank')}></i> */}
                                                    </div>
                                                ))}
                                            </th>
                                        ) : inquiry.configuration ? (
                                            <td className="text-nowrap" style={{ maxWidth: '250px', wordBreak: 'break-word' }}>
                                                {inquiry.configuration.wallets.map((str, index) => (
                                                    <div key={index} data-toggle="tooltip" data-placement="top" title={str}>
                                                        {getAliasWallet(str)}
                                                    </div>))} </td>) : (<td className="text-nowrap">N/A</td>)
                                    }

                                    {inquiry.configuration ? (<td className="" style={{ maxWidth: '100px', wordBreak: 'break-word' }} data-toggle="tooltip" data-placement="top" title={inquiry.configuration.dateSelected}>{`${inquiry.configuration.dateSelected.slice(0, 15)}...`}</td>) : (<td className="text-nowrap">{t('components.historicTable.unknown')}</td>)}
                                    {inquiry.configuration ? (<td className="" style={{ maxWidth: '100px', wordBreak: 'break-word' }}>{inquiry.configuration.timeZone}</td>) : (<td className="text-nowrap">{t('components.historicTable.unknown')}</td>)}

                                    <td className="">
                                        <div className="d-grid">
                                            {inquiry.consumed && (<div className="btn-group" role="group" aria-label="Generar Certificado">
                                                <button type="button" className="btn btn-outline-primary pe-none border-end-none"><i
                                                    className="bi bi-card-list"></i></button>
                                                <button type="button" onClick={() => { getDetails(inquiry); setInquiryId(inquiry._id) }} disabled={!inquiry.consumed}
                                                    className="btn btn-primary border-start-none text-start">{t('components.historicTable.details')}</button>
                                            </div>)}
                                            {!inquiry.consumed && (<div className="btn-group" role="group" aria-label="Generar Certificado">
                                                <button type="button" className="btn btn-outline-danger pe-none border-end-none"><i
                                                    className="bi bi-arrow-clockwise"></i> </button>
                                                <button type="button" onClick={() => { getDetails(inquiry); setInquiryId(inquiry._id) }}
                                                    className="btn btn-outline-danger border-start-none text-start">{t('components.historicTable.recover')}</button>

                                                <button type="button" className="btn btn-outline-danger pointer" onClick={() => { setInqIdToDelete(inquiry._id); setModalDelete(true) }}><i
                                                    className="bi bi-trash pointer"></i> </button>
                                            </div>)}
                                        </div>
                                    </td>
                                    <td className="">
                                        <div className="d-grid ">
                                            {userInfo.userType === USER_TYPE.ADMIN && (<div className="btn-group" role="group" aria-label="Generar Certificado">
                                                <button type="button" className="btn btn-outline-primary pe-none border-end-none"><i
                                                    className="bi bi-patch-check"></i></button>
                                                {inquiry.certificate ? (
                                                    <button type="button" onClick={() => window.open(inquiry.certificate?.fileSigned ? inquiry.certificate?.fileSigned : inquiry.certificate?.fileUnsigned, '_blank')}
                                                        className="btn btn-primary border-start-none text-start">{inquiry.certificate?.fileSigned ? 'View Certificate signed' : 'View Certificate'}</button>
                                                ) :
                                                    (<button type="button" onClick={() => { showModalCertificate(true); setInquiryId(inquiry._id) }} disabled={!inquiry.consumed}
                                                        className="btn btn-primary border-start-none text-start">{t('components.historicTable.requestCertificate')}</button>)}

                                            </div>)}
                                            {userInfo.userType === USER_TYPE.ADMIN && inquiry.certificate?.fileUnsigned && (<>
                                                <div className="btn-group mt-2 " role="group" aria-label="Generar Certificado">
                                                    <button type="button" className="btn btn-outline-primary pe-none border-end-none"><i
                                                        className="bi bi-patch-check"></i></button>
                                                    <button type="button" onClick={() => window.open(inquiry.certificate?.fileSigned ? inquiry.certificate?.fileSigned : inquiry.certificate?.fileUnsigned, '_blank')}
                                                        className="btn btn-primary  text-start">{inquiry.certificate?.fileSigned ? 'Certificate signed' : 'Get Certificate'}</button>
                                                </div>
                                                {!inquiry.certificate?.fileSigned && (<div className="btn-group mt-2 " role="group" aria-label="Generar Certificado">
                                                    <button type="button" className="btn btn-outline-primary pe-none border-end-none"><i
                                                        className="bi bi-patch-check"></i></button>
                                                    <button type="button" onClick={() => { setInquiryId(inquiry._id); showModalUploadCertificate(true) }}
                                                        className="btn btn-primary  text-start">{t('components.historicTable.signedUpload')}</button>
                                                </div>)}
                                            </>
                                            )}
                                        </div>
                                    </td>
                                </tr>)
                            ))}

                        </tbody>
                    </table>
                )
                }


            </div>
            <Modal
                onHide={() => { setModalDelete(false) }}
                show={modalDelete}
                size="sm">
                <Modal.Header className="d-flex justify-content-center">
                    <h3>{t('views.historicView.sureDelete')}</h3>
                </Modal.Header>
                <Modal.Body className="row d-flex justify-content-center">
                    <div className="col-12 m-3">
                        {t('views.historicView.sureDeleteDesc')}
                    </div>
                    <div className="col-12 m-3"></div>
                    <Button onClick={() => deleteInquiry(inqIdToDelete ? inqIdToDelete : '')}>{t('views.historicView.sureDone')}</Button>
                </Modal.Body>
            </Modal>
            <Modal
                onHide={() => { setModalEdit(false) }}
                show={modalEdit}
                size="sm">
                <Modal.Header className="d-flex justify-content-center">
                    <h3>{t('views.historicView.modalEdit.title')}</h3>
                </Modal.Header>
                <Modal.Body className="row d-flex justify-content-center">
                    <div className="col-12 m-3">
                        <Form onSubmit={handleFormConfig}>
                            <Form.Group className="mb-3" controlId="alias">
                                <Form.Label><b>Alias</b></Form.Label>
                                <Form.Control type="text" placeholder="Alias" required />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                {t('views.historicView.modalEdit.button')}
                            </Button>
                        </Form>
                    </div>
                    <div className="col-12 m-3"></div>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default HistoricTable;