import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BLOCKCHAIN, Balance, CurrentBalance, TRUST_LEVEL_TOKEN } from "../models/models";
import { Token } from "../models/models";
import { useSelector } from "react-redux";
import user, { selectUserInfo } from "../features/user/user";
import Dropdown from 'react-bootstrap/Dropdown';
import { Form } from "react-bootstrap";




type BlockchainSelected = {
    blockchain: BLOCKCHAIN;
    active: boolean
}

type TrustLevelSelected = {
    trustLevel: TRUST_LEVEL_TOKEN;
    active: boolean
}

type WalletSelected = {
    wallet: string;
    active: boolean
}

type FilterValues = {
    blockchains: BlockchainSelected[];
    bcs: BLOCKCHAIN[];
    trusted : TRUST_LEVEL_TOKEN[];
    wallets?: WalletSelected[];
    trustLevels : TrustLevelSelected[];
    showErc20: boolean;
    showNative: boolean;
    showZero: boolean;
    filterText: string;
}

type FilterProps = {
    tokensBalance?: Token[];
    balances?: Balance[];
    currentBalances?: CurrentBalance[];
    iconBlockchain: any;
    setFiltered: any;
    setFilteredTokensBalance: any;
    enableAllFilters: boolean,
    setEnableAllFilters: any
}


function FiltersTokensBalance({ balances, tokensBalance, currentBalances, iconBlockchain, setFiltered, enableAllFilters, setEnableAllFilters, setFilteredTokensBalance }: FilterProps) {

    const { t } = useTranslation('common');
    const userInfo = useSelector(selectUserInfo);
    const [filterValues, setFilterValues] = useState<FilterValues>({
        showErc20: true,
        showZero: true,
        showNative: true,
        bcs: [BLOCKCHAIN.ETHEREUM, BLOCKCHAIN.BSC, BLOCKCHAIN.POLYGON],
        blockchains: [  { blockchain: BLOCKCHAIN.BSC, active: true }, { blockchain: BLOCKCHAIN.ETHEREUM, active: true }, 
                        { blockchain: BLOCKCHAIN.POLYGON, active: true }],
        wallets: currentBalances?.map((bal: CurrentBalance) => { return { wallet: bal.wallet, active: true } }),
        filterText: '',
        trustLevels : [ {trustLevel : TRUST_LEVEL_TOKEN.NOT_DEFINED , active : true}, {trustLevel : TRUST_LEVEL_TOKEN.PENDING , active : true}, 
                        {trustLevel : TRUST_LEVEL_TOKEN.SCAM , active : true}, {trustLevel : TRUST_LEVEL_TOKEN.SUSPICIOUS , active : true},
                        {trustLevel : TRUST_LEVEL_TOKEN.VALID , active : true} ],
        trusted  : [TRUST_LEVEL_TOKEN.NOT_DEFINED, TRUST_LEVEL_TOKEN.PENDING, TRUST_LEVEL_TOKEN.SUSPICIOUS, TRUST_LEVEL_TOKEN.SCAM,  TRUST_LEVEL_TOKEN.VALID],
    });
    
    const [showTrust, setShowTrust] = useState<boolean>(false);
    const classTrust = ["indefinido", "desarrollo", "depravado", "enganioso", "valido"];
    const [allBlockchains , setAllBlockchains] = useState<boolean>(true);
    const [allWallets , setAllWallets] = useState<boolean>(true);

    const handleBlockchainFilter = (blockchain: BLOCKCHAIN) => {
        if (filterValues.blockchains.find((bc: BlockchainSelected) => bc?.blockchain === blockchain)) {
            filterValues.blockchains?.forEach((bc: BlockchainSelected) => { if (blockchain === bc?.blockchain) bc.active = !bc.active });
        }
        else {
            filterValues.blockchains.push({ blockchain, active: true });
        }

        const bcsNew: BLOCKCHAIN[] = [];
        filterValues.blockchains?.forEach((bc: BlockchainSelected) => { if (bc.active) bcsNew.push(bc?.blockchain) })

        setFilterValues({ ...filterValues, bcs: bcsNew });
    };

    const selectAllBlockchains = () => {

        const bcsNew: BLOCKCHAIN[] = [];
        filterValues.blockchains?.forEach((bc: BlockchainSelected) => { if (!allBlockchains) bcsNew.push(bc?.blockchain) })

        setFilterValues({ ...filterValues,bcs: bcsNew, blockchains : [
            { blockchain: BLOCKCHAIN.BSC, active: !allBlockchains }, 
            { blockchain: BLOCKCHAIN.ETHEREUM, active: !allBlockchains }, 
            { blockchain: BLOCKCHAIN.POLYGON, active: !allBlockchains }]  });

        setAllBlockchains(!allBlockchains);
    }

    const selectAllWallets = () => {

        const newValueWallets: WalletSelected[] = [];

        filterValues.wallets?.forEach((wal: WalletSelected) => {
            newValueWallets.push({ wallet: wal.wallet, active: !allWallets })
        })
        setFilterValues({ ...filterValues, wallets: newValueWallets });
        setAllWallets(!allWallets);
    }

    const handleWalletFilter = (value: string) => {
        const newValueWallets: WalletSelected[] = [];

        filterValues.wallets?.forEach((wal: WalletSelected) => {
            if (wal.wallet === value) {
                newValueWallets.push({ wallet: wal.wallet, active: !wal.active })
            }
            else {
                newValueWallets.push({ wallet: wal.wallet, active: wal.active })
            }
        })
        setFilterValues({ ...filterValues, wallets: newValueWallets });
    };

    const handleErc20Filter = () => {
        setFilterValues({ ...filterValues, showErc20: !filterValues.showErc20 })
    };

    const handlNativeFilter = () => {
        setFilterValues({ ...filterValues, showNative: !filterValues.showNative })
    };

    const handleZeroFilter = () => {
        setFilterValues({ ...filterValues, showZero: !filterValues.showZero });
    };

    const handleSearchText = (searchText: any) => {
        setFilterValues({ ...filterValues, filterText: searchText });
    };

    const handleTrustLevel = (trustLevel : TRUST_LEVEL_TOKEN) => {
        const filterTrustNew : TrustLevelSelected[] = [];
        filterValues.trustLevels.forEach( (trustLevelFilter : TrustLevelSelected) => {
            if(trustLevel === trustLevelFilter.trustLevel){
                trustLevelFilter.active = !trustLevelFilter.active; 
            }
            filterTrustNew.push(trustLevelFilter);
        });

        const trustedNew: TRUST_LEVEL_TOKEN[] = [];
        filterValues.trustLevels.forEach((tl : TrustLevelSelected) => { if (tl.active) trustedNew.push(tl.trustLevel) })

        setFilterValues({ ...filterValues, trusted : trustedNew ,trustLevels: filterTrustNew});
    };

    const isActive = (blockchain: BLOCKCHAIN) => {
        return filterValues.blockchains.find((bc: BlockchainSelected) => bc?.blockchain === blockchain)?.active;
    }


    useEffect(() => {
        const filterTokensBalances = (tokensBalance: Token[]) => {
            // const newBalances: Balance[] = []

            // balances.forEach((balance: Balance) => {
                const tokens: Token[] = tokensBalance ? tokensBalance.filter((token: Token) => {
                    if (filterValues.filterText !== '' && (
                        token.name.toLowerCase().includes(filterValues.filterText.toLowerCase()) ||
                        token.symbol.toLowerCase().includes(filterValues.filterText.toLowerCase()))
                    ) {
                        // console.log("text: ", token.name , ' - ', token.symbol)
                        return true;
                    }

                    if (token.blockchain !== undefined && !filterValues.bcs.includes(token.blockchain)) { 
                        return false;
                    } 
                    
                      

                    if( filterValues.filterText === '' && token.trustLevel !== undefined && !filterValues.trusted.includes(token.trustLevel) ) {
                        return false;
                    }
                    if (filterValues.filterText === '' && !filterValues.showZero && !(token.balance > 0)) {
                        return false;
                    }
                    if (token.wallet !== undefined && !filterValues.wallets?.find( (wall : WalletSelected) => wall.wallet === token.wallet && wall.active)) { 
                        return false;
                    }
                    if (filterValues.filterText === '' && filterValues.showNative && filterValues.showErc20) {
                        return true;
                    }
                    if (filterValues.filterText === '' && filterValues.showNative && !filterValues.showErc20 && !token.address) {
                        return true;
                    }
                    if (filterValues.filterText === '' && filterValues.showErc20 && !filterValues.showNative && token.address) {
                        return true;
                    }
                    
                    

                    return false;
                }) : [];

                // if(filterValues.trustLevels)
                // console.log("TOKENS FILTRADOS: ", tokens);
            //     if (filterValues.bcs.includes(balance?.blockchain)) { newBalances.push({ ...balance, tokens }) }
                
            // });

            // console.log("TOKENS FILTERED: ", tokens);

            return tokens;
        }

        const enableFilters = () => {

            setFilterValues({
                showErc20: true,
                showZero: true,
                showNative: true,
                bcs: [BLOCKCHAIN.ETHEREUM, BLOCKCHAIN.BSC, BLOCKCHAIN.POLYGON],
                blockchains: [{ blockchain: BLOCKCHAIN.BSC, active: true }, { blockchain: BLOCKCHAIN.ETHEREUM, active: true }, { blockchain: BLOCKCHAIN.POLYGON, active: true }],
                // wallets : currentBalances?.map( (bal: CurrentBalance) => { return {wallet : bal.wallet, active : true}} ),
                wallets: userInfo.wallets?.map((wall: string) => { return { wallet: wall, active: true } }),
                filterText: '',
                trustLevels : [ {trustLevel : TRUST_LEVEL_TOKEN.NOT_DEFINED , active : true}, {trustLevel : TRUST_LEVEL_TOKEN.PENDING , active : true}, 
                    {trustLevel : TRUST_LEVEL_TOKEN.SCAM , active : true}, {trustLevel : TRUST_LEVEL_TOKEN.SUSPICIOUS , active : true},
                    {trustLevel : TRUST_LEVEL_TOKEN.VALID , active : true} ],
                trusted  : [TRUST_LEVEL_TOKEN.NOT_DEFINED, TRUST_LEVEL_TOKEN.PENDING, TRUST_LEVEL_TOKEN.SCAM, TRUST_LEVEL_TOKEN.SUSPICIOUS, TRUST_LEVEL_TOKEN.VALID],
            });
        }

        const applyFilters = () => {

            // console.log("Enable : ", enableAllFilters);
            if (enableAllFilters) {
                enableFilters();
                setEnableAllFilters(false);
            }
            else if (tokensBalance) {
                const filteredTokensBalances = filterTokensBalances(tokensBalance);
                setFilteredTokensBalance(filteredTokensBalances);
                // setFiltered(filteredBalances);
            }
            else if (tokensBalance) {
                // const newCurrentBalances: CurrentBalance[] = [];
                // currentBalances.forEach((bal: CurrentBalance) => {
                //     if (bal && filterValues.wallets?.find((wal) => wal.wallet === bal.wallet && wal.active)) {
                //         const newBalance: CurrentBalance = { wallet: bal.wallet, balance: filterBalances(bal.balance) };
                //         newCurrentBalances.push(newBalance);
                //     }
                // });
                // setFiltered(newCurrentBalances);
            }
        }

        applyFilters();

    }, [enableAllFilters, currentBalances, tokensBalance, filterValues, setFiltered]);

    const getClass = (wallet: string) => {
        const classWallet: string = filterValues.wallets?.find((wal) => wal.wallet === wallet && wal.active) ?
            "m-2 btn btn-primary btn-wallets-2 w-100 my-1 text-center selected" :
            "m-2 btn btn-light btn-wallets-2 w-100 my-1 text-center selected";
        return classWallet;
    }

    const getWallet = (wallet: string): string => {

        const alias = userInfo.walletsData?.find(w => w.wallet === wallet)?.alias;
        return alias && wallet ? alias : wallet?.length > 20
            ? `${wallet?.slice(0, 6)}...${wallet?.slice(-6)}`
            : wallet;
    }

    

    return (
        <div className="row px-1 py-4 rounded-top-4 mt-auto text-light-emphasis bg-light-subtle">
            <div className="row row-gap-3 me-1">
                <div className="col-12 my-auto">
                    {currentBalances && (
                        <>
                            <div className="d-flex flex-col row justify-content-center">
                                <div className="col-12 d-flex justify-content-start">
                                    <h5>Wallets</h5>&nbsp;&nbsp;
                                    {/* <input className="form-check-input" type="checkbox" role="switch" onChange={() => { }} onClick={selectAllWallets} checked={allWallets} /> */}
                                    <Form>
                                        <Form.Group className=" mt-1 d-flex" onChange={(e) => selectAllWallets()}>
                                            {/* <Form.Label >{t('views.historicView.selectBlockchains')}</Form.Label>&nbsp;&nbsp;&nbsp; */}
                                            <Form.Check type="switch" checked={allWallets} onChange={() => {}} />
                                        </Form.Group>
                                    </Form>
                                </div>

                                <div className="col-10 dropdown text-nowrap d-flex justify-content-center">
                                    {/* {currentBalances.map((balance: CurrentBalance, index: number) => (
                                    <div key={index} onClick={() => { handleWalletFilter( balance.wallet) }}
                                        className={getClass(balance.wallet)} data-toggle="tooltip" data-placement="top" title={balance.wallet} >
                                            <i className="bi bi-wallet" ></i>&nbsp;&nbsp;
                                        {getWallet(balance.wallet)}
                                    </div>
                                ))} */}
                                    {userInfo.wallets?.map((wallet: string, index: number) => (
                                        (wallet && (<div key={index} onClick={() => { handleWalletFilter(wallet) }}
                                            className={getClass(wallet)} data-toggle="tooltip" data-placement="top" title={wallet} >
                                            <i className="bi bi-wallet" ></i>&nbsp;&nbsp;
                                            {getWallet(wallet)}
                                        </div>))
                                    ))}

                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className="my-auto ms-auto">
                    <div className="col-12 row">
                        <div className="col-12 d-flex justify-content-start">
                            <h5>Blockchains</h5>&nbsp;&nbsp;
                            <div className="form-check form-switch form-check-reverse text-nowrap mb-0 mt-1">
                                <input className="form-check-input" type="checkbox" role="switch" onChange={() => { }} onClick={selectAllBlockchains} checked={allBlockchains} />
                                {/* <label className="form-check-label" >{t('components.filters.select')}</label> */}
                            </div>
                        </div>
                        <div className="col-12 my-auto d-flex justify-content-center flex-wrap">
                            <div className={"" + (isActive(BLOCKCHAIN.ETHEREUM) ? "m-2 my-1 btn btn-primary btn-bcs selected" : "m-2 my-1 btn btn-light btn-bcs text-center selected")}
                                onClick={() => handleBlockchainFilter(BLOCKCHAIN.ETHEREUM)}>
                                <span className="btn-image-md">
                                    <img className="m-1" src={iconBlockchain(BLOCKCHAIN.ETHEREUM)} alt="Imagen" />
                                </span>
                                {t('components.filters.eth')}
                            </div>
                            <div className={"" + (isActive(BLOCKCHAIN.POLYGON) ? "m-2 my-1 btn btn-primary btn-bcs selected" : "m-2 my-1 btn btn-light btn-bcs text-center selected")}
                                onClick={() => handleBlockchainFilter(BLOCKCHAIN.POLYGON)}>
                                <span className="btn-image-md">
                                    <img className="m-1" src={iconBlockchain(BLOCKCHAIN.POLYGON)} alt="Imagen" />
                                </span>
                                {t('components.filters.poly')}
                            </div>
                            <div className={"" + (isActive(BLOCKCHAIN.BSC) ? "m-2 my-1 btn btn-primary btn-bcs selected" : "m-2 my-1 btn btn-light btn-bcs text-center selected")}
                                onClick={() => handleBlockchainFilter(BLOCKCHAIN.BSC)}>
                                <span className="btn-image-md">
                                    <img className="m-1" src={iconBlockchain(BLOCKCHAIN.BSC)} alt="Imagen" />
                                </span>
                                {t('components.filters.bsc')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-auto ms-auto ">
                    <div className="col-12 row justify-content-around align-items-center">
                        <div className="col-12 d-flex justify-content-start">
                            <h5>Tokens</h5>
                        </div>
                        <div className="col-12 col-lg-5 row row-gap-3 d-flex justify-content-center justify-content-lg-start m-1">
                            <div className="col-auto my-auto">
                                <div className="form-check form-switch form-check-reverse text-nowrap mb-0">
                                    <input className="form-check-input" type="checkbox" role="switch" onChange={() => { }} onClick={handleZeroFilter} checked={filterValues.showZero} />
                                    <label className="form-check-label" >{t('components.filters.zeroBalance')}</label>
                                </div>
                            </div>

                            <div className="col-auto my-auto">

                                <div className="form-check form-switch form-check-reverse text-nowrap mb-0">
                                    <input className="form-check-input" type="checkbox" role="switch" onChange={() => { }} onClick={handleErc20Filter} checked={filterValues.showErc20} />
                                    <label className="form-check-label" >{t('components.filters.showToken')}</label>
                                </div>
                            </div>

                            <div className="col-auto my-auto">


                                <div className="form-check form-switch form-check-reverse text-nowrap mb-0">
                                    <input className="form-check-input" type="checkbox" role="switch" onChange={() => { }} onClick={handlNativeFilter} checked={filterValues.showNative} />
                                    <label className="form-check-label" >{t('components.filters.showNative')}</label>
                                </div>
                            </div>

                            {/* <div className="col-auto my-auto">
                                <div className="btn-group btn-group-sm text-nowrap">
                                    <button type="button" className="btn btn-outline-primary pe-none btn-image-sm border-end-none">
                                        <span className="ms-2">List view</span>
                                    </button>
                                    <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split pe-2 border-start-none" data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">
                                        <span className="visually-hidden">Toggle Dropdown</span>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" >Action</a></li>
                                        <li><a className="dropdown-item" >Another action</a></li>
                                        <li><a className="dropdown-item" >Something else here</a></li>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li><a className="dropdown-item" >Separated link</a></li>
                                    </ul>
                                </div>
                            </div> */}

                        </div>
                        <div className="col-5 col-lg-3 my-auto d-flex justify-content-lg-start justify-content-center px-0">
                                <Dropdown show={showTrust} data-bs-theme="dark">
                                    <Dropdown.Toggle  id="dropdown-basic" onClick={() => {setShowTrust(!showTrust)}}>
                                        {t('components.filters.trustLevel')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu >
                                        <Dropdown.Item onClick={() => handleTrustLevel(TRUST_LEVEL_TOKEN.VALID)}>
                                            <Form.Check type="checkbox" className={"text-nowrap text-" + classTrust[4] + "-ranking"} label={t('components.filters.trust.VALID')} checked={filterValues.trustLevels.find(t => t.trustLevel === TRUST_LEVEL_TOKEN.VALID)?.active} />
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleTrustLevel(TRUST_LEVEL_TOKEN.NOT_DEFINED)} >
                                            <Form.Check type="checkbox" className={"text-nowrap text-" + classTrust[0] + "-ranking"} label={t('components.filters.trust.NOT_DEFINED')} checked={filterValues.trustLevels.find(t => t.trustLevel === TRUST_LEVEL_TOKEN.NOT_DEFINED)?.active} />
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleTrustLevel(TRUST_LEVEL_TOKEN.PENDING)}>
                                            <Form.Check type="checkbox" className={"text-nowrap text-" + classTrust[1] + "-ranking"} label={t('components.filters.trust.PENDING')} checked={filterValues.trustLevels.find(t => t.trustLevel === TRUST_LEVEL_TOKEN.PENDING)?.active} />
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleTrustLevel(TRUST_LEVEL_TOKEN.SUSPICIOUS)} >
                                            <Form.Check type="checkbox" className={"text-nowrap text-" + classTrust[2] + "-ranking"} label={t('components.filters.trust.SUSPICIOUS')} checked={filterValues.trustLevels.find(t => t.trustLevel === TRUST_LEVEL_TOKEN.SUSPICIOUS)?.active} />
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleTrustLevel(TRUST_LEVEL_TOKEN.SCAM)} >
                                            <Form.Check type="checkbox" className={"text-nowrap text-" + classTrust[3] + "-ranking"} label={t('components.filters.trust.SCAM')} checked={filterValues.trustLevels.find(t => t.trustLevel === TRUST_LEVEL_TOKEN.SCAM)?.active} />
                                        </Dropdown.Item>
                                        {/* <Dropdown.Item >
                                        <div className={"text-nowrap text-" + classTrust[2] + "-ranking"} onClick={() => handleTrustLevel(TRUST_LEVEL_TOKEN.SCAM)}>
                                            <i className={"bi "+ iconsTrust[2] +" icon-md"}></i><span className="ms-2 align-middle-md">{trustLevels[2]}</span>&nbsp;&nbsp;        
                                        </div>
                                        </Dropdown.Item> */}
                                       
                                        
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        <div className="col-6 col-lg-3 row row-gap-3 d-flex justify-content-end mt-3 mt-lg-0 mx-lg-0">
                            <div className="col-12 my-auto p-0">
                                <div className="form-check form-switch form-check-reverse text-nowrap mb-0 p-0">
                                    {/* <label className="form-control" >Buscar&nbsp;&nbsp;</label>  
                                    <input className="" type="text"  onChange={(e) => {handleSearchText(e.target.value) }} /> */}
                                    <div className="form-floating">
                                        <input type="text" className="form-control" onChange={(e) => { handleSearchText(e.target.value) }} />
                                        <label>Buscar por texto</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 my-auto ms-auto d-flex justify-content-center ">

                </div>
            </div>
        </div>
    )
}

export default FiltersTokensBalance;
