import { USER_TYPE } from "../features/user/user";


function TypeTokenRow ( {id, trustLevel, userType, setIdToken,setShowTokenModal, showEdit = false } : any) {

    const trustLevels = ["NOT_DEFINED", "PENDING", "SUSPICIOUS", "SCAM", "VALID"];
    const classTrust = ["indefinido", "desarrollo", "depravado", "enganioso", "valido"];
    const iconsTrust = ["bi-question-circle", "bi-wrench-adjustable-circle 1", "bi-exclamation-circle", "bi-x-octagon", "bi-check-circle"]

    return (<>
        <td className={"text-nowrap text-" + classTrust[trustLevel] + "-ranking"}>
            <i className={"bi "+ iconsTrust[trustLevel] +" icon-md"}></i><span className="ms-2 align-middle-md">{trustLevels[trustLevel]}</span>&nbsp;&nbsp;
            {id && showEdit && userType === USER_TYPE.ADMIN && (
                <i className="bi bi-pencil text-desarrollo-ranking pointer" onClick={() => { setIdToken(id); setShowTokenModal(true) }}></i>
            )}
        </td>				
    </>)

}

export default TypeTokenRow;