import { useState } from "react";
import { BLOCKCHAIN } from "../models/models";
import { Modal } from "react-bootstrap";
import Spinner from "./Spinner";
import { useAppDispatch } from "../app/hooks";
import { selectJWT, setError, setSuccess } from "../features/user/user";
import { UserService } from "../services/user.service";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function AddAssetForm({ setShowAddToken }: any) {

    const [step, setStep] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [ dataToken, setDataToken] = useState<any>();
    const dispatch = useAppDispatch();
    const jwt = useSelector(selectJWT);
    const { t } = useTranslation('common');

    const saveAsset = async (event: React.SyntheticEvent) => {
        event.preventDefault();


        const target = event.target as typeof event.target & {
            name: { value: string };
            blockchain: { value: number };
            url : { value: string};
            address : { value : string}
        };


        const data: any = {
            token: {
                name: target.name.value,
                blockchain: target.blockchain.value,
                urlScan: target.url.value,
                address: target.address.value,
            }
        }

        console.log("SAVE ASSET", data);
        setDataToken(data);
        setStep(2);
    };

    const submitAsset = async () => {
        //console.log("STEP SIGUIENTE");
        setLoading(true);
        try {

            const response = await UserService.addToken(dataToken, jwt);
            console.log(response);
            setLoading(false);
            setShowAddToken(false);
            dispatch(setSuccess({ title: "Asset requested correctly!", desc: "Your asset will be review" }));
            
        } catch (error : any) {
            console.log("ERROR", error);
            setLoading(false);
            setShowAddToken(false);
            if(error?.response?.data?.error?.statusCode === 106){
                dispatch(setError({ title: "Asset request error!", desc: "This asset already exists" }));
                return;
            }
            dispatch(setError({ title: "Asset request error!", desc: "Your asset has not been added" }));
        }
        
        
    };

    return <>
        <button type="button" className="btn-close position-absolute top-0 end-0 me-3 mt-3" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowAddToken(false)}></button>
        <div className="container">
            {loading && (
                <>
                    <Modal.Body>
                        <div className="p-3 my-4 text-center">
                            <div className="mb-4">
                                <i className="display-5 text-info-ranking bi bi-plus-square"></i>
                            </div>
                            <h1 className="h2">{t('components.addAssetForm.assetRequest')}</h1>
                            <Spinner />
                        </div>
                    </Modal.Body>
                </>
            )}
            {step === 1 && !loading && (<div className="p-3 my-4 text-center">
                <div className="mb-4">
                    <i className="display-5 text-info-ranking bi bi-plus-square"></i>
                </div>
                <h1 className="h2">{t('components.addAssetForm.assetRequest')}</h1>
                <form className="mt-4 d-grid gap-3 text-start needs-validation" onSubmit={saveAsset} >
                    <div className="row g-2">
                        <div className="col-md">
                            <div className="form-floating">
                                <input type="text" className="form-control" id="name" placeholder="CryptoX" required />
                                <label id="tokenName">{t('components.addAssetForm.assetName')}</label>
                                <div className="invalid-feedback  px-2">{t('components.addAssetForm.invalidName')}</div>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="form-floating">
                                <select className="form-select" id="blockchain" required>
                                    <option value={BLOCKCHAIN.ETHEREUM}>{t('components.addAssetForm.eth')}</option>
                                    <option value={BLOCKCHAIN.BSC}>{t('components.addAssetForm.bsc')}</option>
                                    <option value={BLOCKCHAIN.POLYGON}>{t('components.addAssetForm.poly')}</option>
                                </select>
                                <label id="validationDefault04">{t('components.addAssetForm.blockchain')}</label>
                                <div className="invalid-feedback  px-2">{t('components.addAssetForm.requiredBlockchain')}</div>
                            </div>
                        </div>
                    </div>
                    <div className="form-floating">
                        <input type="url" className="form-control" id="url" pattern="^(http|https)://[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$" required />
                        <label id="urlScan">{t('components.addAssetForm.urlScan')}</label>
                        <div className="invalid-feedback  px-2">{t('components.addAssetForm.invalidUrl')}</div>
                    </div>
                    <div className="form-floating">
                        <input type="text" className="form-control" id="address" placeholder="0x6b175474e89094c44da98b954eedeac495271d0f" pattern="^0x[a-fA-F0-9]{40}$" />
                        <label id="addressToken">{t('components.addAssetForm.assetSmartContract')}</label>
                        <div className="invalid-feedback  px-2">{t('components.addAssetForm.invalidAddress')}</div>
                    </div>
                        <p>{t('components.addAssetForm.assetsReview')}</p>
                    <div className="form-check">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <input className="form-check-input" type="checkbox" value="" id="datosCheck" onChange={() => {}} checked />
                            </div>
                            <div className="col-auto m-0 p-0">
                                <label className="form-check-label" id="datosCheck">
                                    {t('components.addAssetForm.policies')}
                                </label>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary">
                        {t('components.addAssetForm.request')}
                    </button>
                </form>
            </div>)}
            {step === 2 && !loading && (
                <div className="p-3 my-4 text-center">
                    <div className="mb-4">
                        <i className="display-5 text-info-ranking bi bi-card-checklist"></i>
                    </div>
                    <h1 className="h2">{t('components.addAssetForm.tokensReview')}</h1>
                    <p className="lead">{t('components.addAssetForm.transactionCertificate')}</p>
                    <p>{t('components.addAssetForm.verifiable')}</p>
                    <div className="form-check">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <input className="form-check-input" type="checkbox" value="" id="legalDosCheck" onChange={() => {}} required checked />
                            </div>
                            <div className="col-auto m-0 p-0">
                                <label className="form-check-label" id="legalDosCheck">
                                    {t('components.addAssetForm.secondPolicies')}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 d-grid gap-3 text-start">
                        <div className="row">
                            <div className="col d-grid">
                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={submitAsset}>
                                    {t('components.addAssetForm.requestRevision')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    </>
}

export default AddAssetForm;