import { useSelector } from "react-redux";
import { addCreditsConsumed, cleanNotification, selectHistoric, selectJWT, selectUserInfo, setError, setInquiryConfirmed, setNewInquiry, setNotification, setTimeZone, USER_TYPE } from "../features/user/user";
import { BLOCKCHAIN, Inquiry, ResponseApi } from "../models/models";
import TimezoneSelect, { ITimezoneOption } from 'react-timezone-select'
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import BalanceService from "../services/balance.service";
import { useAppDispatch } from "../app/hooks";
import { useTranslation } from "react-i18next";

export type FormProps = {
    inquiryIdRecover?: string;
    setApiResponse?: any
    setLoading: any
    setInquiryId: any
    wallet: string,
    setInquiryData: any,
    setModalPayment: any
}

type BlockchainChosen = {
    ethereum: boolean,
    bsc: boolean,
    polygon: boolean
}

type FormDataInquiry = {
    wallets?: string[],
    blockchain: BLOCKCHAIN[],
    dateSelected: string,
    date: string,
    timeZone: string
    timeZoneDefault?: boolean,
    inquiryIdRecover?: string
}

type WalletSelected = {
    wallet: string;
    selected: boolean
}
function FormBalance({ setLoading, setInquiryId, wallet, setInquiryData, setModalPayment, inquiryIdRecover }: FormProps) {

    const userInfo = useSelector(selectUserInfo);
    const inquiriesStore = useSelector(selectHistoric);
    const [selectedTimezone, setSelectedTimezone] = useState<ITimezoneOption>({ value: '', label: '', offset: 0 });
    const jwt = useSelector(selectJWT);
    const [blockchainsChosen, setBlockchainsChosen] = useState<BlockchainChosen>({ ethereum: true, bsc: false, polygon: false })
    const [showConfirm, setShowConfirm] = useState(false);
    const [dataInquiry, setDataInquiry] = useState<FormDataInquiry>();
    const dispatch = useAppDispatch();
    const [msg, setMsg] = useState<string>('');
    const [saveTimeZone, setSaveTimeZone] = useState<boolean>(userInfo.userData?.timeZoneDefault ? false : true);
    const [walletsChoosen, setWalletsChoosen] = useState<WalletSelected[]>(userInfo.wallets ? userInfo.wallets?.map((wallet) => { return { wallet, selected: true } }) : []);
    const [walletAdmin, setWalletAdmin] = useState<string>(userInfo?.wallets ? userInfo.wallets[0] : '');
    const [similarInquiries, setSimilarInquiries] = useState<Inquiry[]>([]);
    const [valueTime, setValueTime] = useState<Date>();
    const handleBlockchainFilter = (blockchain: BLOCKCHAIN) => {
        setBlockchainsChosen(
            blockchain === BLOCKCHAIN.ETHEREUM ? { ...blockchainsChosen, ethereum: !blockchainsChosen.ethereum } :
                blockchain === BLOCKCHAIN.POLYGON ? { ...blockchainsChosen, polygon: !blockchainsChosen.polygon } :
                    { ...blockchainsChosen, bsc: !blockchainsChosen.bsc })
    };
    const { t } = useTranslation('common');

    const handleForm = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (!blockchainsChosen.ethereum && !blockchainsChosen.bsc && !blockchainsChosen.polygon) {
            setMsg("Please, choose some blockchain");
            return;
        }
        if (!selectedTimezone.label && !userInfo.userData?.timeZoneDefault) {
            setMsg("Please, choose time zone");
            return;
        }

        if (userInfo.userType === USER_TYPE.ADMIN && !walletAdmin) {
            setMsg("Please, set wallet for inquiry");
            return;
        }

        if (saveTimeZone && selectedTimezone.label !== userInfo.userData?.timeZoneDefault) {
            dispatch(setTimeZone(selectedTimezone.label));
        }



        setMsg('');

        setLoading(true);

        const target = event.target as typeof event.target & {
            formWallet: { value: string };
            formDate: { value: Date }
        };

        const date = new Date(target.formDate.value);
        const blockchains: BLOCKCHAIN[] = [];
        if (blockchainsChosen.ethereum) blockchains.push(BLOCKCHAIN.ETHEREUM);
        if (blockchainsChosen.polygon) blockchains.push(BLOCKCHAIN.POLYGON);
        if (blockchainsChosen.bsc) blockchains.push(BLOCKCHAIN.BSC);


        const data: FormDataInquiry = {
            wallets: userInfo.userType === USER_TYPE.ADMIN ? [walletAdmin ? walletAdmin : ''] : walletsChoosen.filter(wal => wal.selected)?.map(wal => { return wal.wallet }),
            blockchain: blockchains,
            dateSelected: date.toString(),
            date: date.toString().split('GMT')[0] + selectedTimezone.label.split(')')[0].replace('(', '').replace(':', ''),
            timeZone: selectedTimezone.label ? selectedTimezone.label : userInfo.userData?.timeZoneDefault ? userInfo.userData?.timeZoneDefault : '',
            timeZoneDefault: saveTimeZone
        }

        setDataInquiry(data);
        setShowConfirm(true);

    };

    const saveWallet = (event: any) => {

        event.preventDefault();


        setWalletAdmin(event.target?.value);

    }

    const getSimilarInquiries = async (event: any) => {
        event.preventDefault();
        // console.log("EVENT: ", new Date(event.target?.value).getTime());
        setValueTime(new Date(event.target?.value));
        const similarInquiries: Inquiry[] = await BalanceService.similarRequests({ date: event.target?.value }, jwt);
        setSimilarInquiries(similarInquiries ? similarInquiries : []);
    }

    const getWallet = (wallet: string): string => {

        const alias = userInfo.walletsData?.find(w => w.wallet === wallet)?.alias;
        return alias ? alias : wallet?.length > 20
            ? `${wallet?.slice(0, 6)}...${wallet?.slice(-6)}`
            : wallet;
    }

    const confirmForm = async (event: React.SyntheticEvent) => {

        event.preventDefault();

        try {
            setShowConfirm(false);
            const response: ResponseApi = await BalanceService.inquiry(dataInquiry, jwt);
            setShowConfirm(false);
            if (response.inquiry) {
                if (!dataInquiry?.inquiryIdRecover) dispatch(setNewInquiry(response.inquiry));

                if (response.paid) {
                    // console.log('Credits available FormBalance Before: ', addCreditsConsumed(response.inquiry.credits));
                    dispatch(addCreditsConsumed(response.inquiry.credits));
                    // console.log('Credits available FormBalance After: ', addCreditsConsumed(response.inquiry.credits));
                    dispatch(setInquiryConfirmed(response.inquiry._id));
                    setInquiryId(response.inquiry._id)
                    setLoading(false);
                    return;
                }

                if (!response.paid) {
                    setInquiryData(response);
                    dispatch(setNotification({ title: 'Créditos insuficientes', desc: 'Será necesario realizar una recarga de créditos o realizar el pago de la consulta' }))

                    setTimeout(() => {
                        dispatch(cleanNotification())
                        setModalPayment(true);
                        setLoading(false);
                    }, 5000);
                    return;
                }

                setLoading(false);
                dispatch(setError({ title: 'Inquiry error', desc: 'Error has ocurred trying get your inquiry' }));

            }

        } catch (error) {
            setLoading(false);
            dispatch(setError({ title: 'Inquiry error', desc: 'Error has ocurred trying get your inquiry' }));
        }


    }



    const iconBlockchain = (blockchain?: BLOCKCHAIN) => {
        return blockchain === BLOCKCHAIN.ETHEREUM ? './assets/img/blockchains/ethereum.png' :
            blockchain === BLOCKCHAIN.POLYGON ? './assets/img/blockchains/polygon.png' : './assets/img/blockchains/binance.png'
    }

    const getCreditsToConsume = () => {
        let credits = 0;
        credits += blockchainsChosen.bsc ? 1 : 0;
        credits += blockchainsChosen.ethereum ? 1 : 0;
        credits += blockchainsChosen.polygon ? 1 : 0;
        if (userInfo.userType !== USER_TYPE.ADMIN) {
            credits = credits * walletsChoosen.filter(wal => wal.selected)?.length;
        }

        return credits;
    }

    const getClass = (wallet: string) => {
        const classWallet: string = walletsChoosen.find((wal: any) => wal.wallet === wallet)?.selected ? "col-6 mb-2 btn btn-primary " : "col-6 btn btn-light ";
        return classWallet + " w-100 my-1 selected";
    }

    const handleWalletFilter = (value: string) => {
        const newValueWallets: WalletSelected[] = [];

        walletsChoosen?.forEach((wal: WalletSelected) => {
            if (wal.wallet === value) {
                newValueWallets.push({ wallet: wal.wallet, selected: !wal.selected })
            }
            else {
                newValueWallets.push({ wallet: wal.wallet, selected: wal.selected })
            }
        })
        setWalletsChoosen(newValueWallets);
    };

    const getHeight = (): string => {
        if (userInfo.userType === USER_TYPE.ADMIN) return '50px';
        return userInfo.wallets?.length ? Number(userInfo.wallets?.length * 50).toString() + 'px' : '50px';
    }

    const formatDateToLocalInput = (date: Date): string => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses van de 0-11, por eso sumamos 1
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    };

    const getLastYearEndDate = () => {
        const now = new Date();
        const lastYear = now.getFullYear() - 1;
        const lastDate = new Date(lastYear, 11, 31, 23, 59, 59); // 11 es diciembre (mes 0-indexado en JS)
        setValueTime(lastDate);
        // return formatDateToLocalInput(lastYearEndDate);
    };

    useEffect(() => {

        if (inquiryIdRecover && inquiriesStore) {
            const inquiry = inquiriesStore.find(inq => inq._id === inquiryIdRecover);
            if (inquiry) {
                const data: FormDataInquiry = {
                    wallets: inquiry.configuration.wallets,
                    blockchain: inquiry.configuration.blockchains,
                    dateSelected: inquiry.configuration.dateSelected,
                    date: inquiry.configuration.date,
                    timeZone: inquiry.configuration.timeZone,
                    inquiryIdRecover
                }

                setDataInquiry(data);
                setShowConfirm(true);
            }
        }

    }, [inquiryIdRecover, inquiriesStore]);

    return (
        <>
            <div className="row col-12 mt-4 d-flex justify-content-center">
                <div className="col">
                    <h4 className="text-center">{t('components.formBalance.chooseWallets')}</h4>
                </div>
            </div>
            {userInfo.wallets && (
                <div className="row col-12 col-md-12 mt-1 d-flex justify-content-center" style={{ minHeight: getHeight() }}>
                    <div className="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6 col-xxl-5" >
                        {userInfo.userType === USER_TYPE.ADMIN ? (
                            <input type="text" className="form-control" id="formWallet" onChange={saveWallet}
                                placeholder="Set Wallet 0x... " defaultValue={userInfo.wallets[0]} required />
                        ) :
                            (
                                <div className="">
                                    <div className={""} data-bs-theme="light">
                                        <div className="row text-dark px-4 py-2" style={{ marginBottom: "-8px", backgroundColor: "var(--bs-white)" }}>
                                            {userInfo.wallets.map((walletOption: string, index: number) => (
                                                // <div  key={index} className={"badge text-decoration-none me-1 lh-sm px-2 pointer " + classesFilters[(index)%3]}>
                                                //     {balance.wallet}
                                                // </div>
                                                (walletOption && (<button key={index}
                                                    onClick={() => { handleWalletFilter(walletOption) }}
                                                    className={getClass(walletOption)}
                                                    aria-current="page"
                                                    data-toggle="tooltip" data-placement="top" title={walletOption}
                                                >
                                                    <i className="bi bi-plus-circle"></i><span className="ms-3">{userInfo?.walletsData?.find(wall => wall.wallet === walletOption && wall.alias) ? userInfo?.walletsData?.find(wall => wall.wallet === walletOption && wall.alias)?.alias + ` ${walletOption?.slice(0, 10)}. . .${walletOption?.slice(-10)}` : `${walletOption?.slice(0, 10)}. . .${walletOption?.slice(-10)}`}</span>
                                                </button>))
                                            ))}

                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            )}
            <form className="p-2 needs-validation" onSubmit={handleForm}>
                <div className="row mb-2 d-flex justify-content-center">
                    <div className="col-lg-4 col-md-8 mt-4 d-flex justify-content-center">

                        {/* <label  className="form-label">Blockchain</label> */}
                        {/* <div className="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group"> */}
                        {/* <br/> */}
                        {/* <input className="m-2" type="checkbox" id="ether" name="interest" value="ETH" />
                            <label className="m-2">
                                <span className="btn-image-md">
                                    <img className="m-1" src={iconBlockchain(BLOCKCHAIN.ETHEREUM)} alt="Imagen" />
                                </span>
                                Ethereum
                            </label>
                            <input className="m-2" type="checkbox" id="binanceSmartChain" name="interest" value="BSC" />
                            <label className="m-2">
                            <span className="btn-image-md">
                                    <img className="m-1" src={iconBlockchain(BLOCKCHAIN.BSC)} alt="Imagen" />
                                </span>
                                BSC
                                </label>
                            <input className="m-2" type="checkbox" id="polygon" name="interest" value="MATIC" />
                            <label className="m-2">
                            <span className="btn-image-md">
                                    <img className="m-1" src={iconBlockchain(BLOCKCHAIN.POLYGON)} alt="Imagen" />
                                </span>
                                Polygon
                                </label> */}
                        {/* <select className="form-select " id="validationDefault04" multiple required>
                                <option value="">Ethereum</option>
                                <option value="">BSC</option>
                                <option value="">Polygon</option>
                            </select> */}
                        <label className={"m-2 btn btn-outline-primary ps-3 align-middle-lg" + (blockchainsChosen.ethereum ? " active" : "")}
                            onClick={() => handleBlockchainFilter(BLOCKCHAIN.ETHEREUM)} >
                            <span className="btn-image-md">
                                <img className="m-1" src={iconBlockchain(BLOCKCHAIN.ETHEREUM)} alt="Imagen" />
                            </span>
                            {t('components.formBalance.eth')}
                        </label>
                        <label className={"m-2 btn btn-outline-primary ps-3 align-middle-lg" + (blockchainsChosen.bsc ? " active" : "")}
                            onClick={() => handleBlockchainFilter(BLOCKCHAIN.BSC)} >
                            <span className="btn-image-md">
                                <img className="m-1" src={iconBlockchain(BLOCKCHAIN.BSC)} alt="Imagen" />
                            </span>
                            {t('components.formBalance.bsc')}
                        </label>
                        <label className={"m-2 btn btn-outline-primary ps-3 align-middle-lg" + (blockchainsChosen.polygon ? " active" : "")}
                            onClick={() => handleBlockchainFilter(BLOCKCHAIN.POLYGON)} >
                            <span className="btn-image-md">
                                <img className="m-1" src={iconBlockchain(BLOCKCHAIN.POLYGON)} alt="Imagen" />
                            </span>
                            {t('components.formBalance.poly')}
                        </label>

                        {/* </div> */}
                    </div>
                    {userInfo.userType !== USER_TYPE.ADMIN && (<div className="col-lg-2 col-md-4 mt-4 d-flex justify-content-center">
                        <div className="mt-3">
                            <h3 className="letter-blue"><i className="bi bi-cart"></i> {getCreditsToConsume()}{t('components.formBalance.credits')}</h3>
                        </div>
                    </div>)}
                </div>
                <div className="row mt-4 d-flex justify-content-center">
                    <div className="col-lg-3 col-md-4">
                        <label className="form-label">{t('components.formBalance.date')}</label>
                        <div className="input-group mb-3">
                            <Button className="btn btn-primary  px-3 text-nowrap" onClick={() => { getLastYearEndDate() }} >{t('components.formBalance.lastYear')}  </Button>
                            <input id="formDate" className="form-control" onChange={getSimilarInquiries} value={valueTime ? formatDateToLocalInput(valueTime) : ''}
                                type="datetime-local" step="1" min="2015-07-30" max={'2023-03-01'} placeholder="Set Valid Date" required />
                            {/* <button> Aceptar</button> */}
                            {/* <span className="input-group-text rounded-end-pill">
                            <i className="bi bi-calendar"></i>
                            </span> */}
                            <Button className="btn btn-primary  px-3 text-nowrap" onClick={() => { setValueTime(new Date()) }}>{t('components.formBalance.today')}</Button>
                        </div>
                    </div>
                    {/* <div className="col-lg-1 col-md-4 ">
                        <br/><br/>
                    </div> */}
                    <div className="col-lg-3 col-md-4">
                        {/* <label className="form-label">Time Zone</label> */}
                        {/* <select className="form-select " id="validationDefault04" required>
						<option selected disabled value="">Choose Country</option>
						<option value="GMT8">(GMT+8:00) Kuala Lumpur, Singapore</option>
						<option>...</option>
					</select> */}
                        <label className="form-label">{t('components.formBalance.timeZone')}</label>
                        <TimezoneSelect
                            className=""
                            value={selectedTimezone}
                            defaultInputValue={userInfo.userData?.timeZoneDefault}
                            // defaultValue={{ value: userInfo.userData.timeZoneDefault, label: userInfo.userData.timeZoneDefault }}
                            onChange={setSelectedTimezone}
                        />
                    </div>
                </div>
                <div className="row mb-4 d-flex justify-content-center">
                    <div className="col-auto d-flex justify-content-center">
                        <input className="m-2" type="checkbox" onChange={() => setSaveTimeZone(!saveTimeZone)} checked={saveTimeZone} />
                        <label className="m-2">
                            {t('components.formBalance.saveTimezone')}
                        </label>
                    </div>
                </div>

                <div className="row row-gap-3 d-flex justify-content-center">
                    <div className="col-auto">
                        {msg !== '' && (<span className="badge  text-bg-danger ">{msg.toUpperCase()}</span>)}
                        {selectedTimezone.label !== '' && userInfo.userData?.timeZoneDefault && selectedTimezone.label !== userInfo.userData?.timeZoneDefault && (
                            <span className="badge  text-bg-warning ">{t('components.formBalance.differentTimezone')}</span>
                        )}
                        {similarInquiries?.map((inquiry: Inquiry, index: number) => (
                            <>
                                <span key={index} className="badge text-bg-warning m-1">{t('components.formBalance.similarInquirie')}{inquiry.configuration.dateSelected}</span><br />
                            </>
                        ))}
                    </div>
                </div>
                <div className="row row-gap-3 d-flex justify-content-center">
                    <div className="col-auto mt-2">
                        {/* <button type="reset" className="btn btn-outline-primary  px-3 me-1 text-nowrap">Clear</button> */}
                        <button type="submit" className="btn btn-primary  px-3 text-nowrap">{t('components.formBalance.getBalance')}</button>
                    </div>
                </div>
            </form>
            <Modal
                onHide={() => { setShowConfirm(false) }}
                show={showConfirm}
                size="lg"
            >
                <button type="button" className="btn-close position-absolute top-0 end-0 me-3 mt-3" data-bs-dismiss="modal" onClick={() => setShowConfirm(false)} aria-label="Close"></button>
                {dataInquiry && (<div className="container">
                    <div className="p-3 my-4 text-center">
                        <div className="mb-4">
                            <i className="display-5 text-info-ranking bi bi-check2-square"></i>
                        </div>
                        <h1 className="h2">{t('components.formBalance.confirmation')}</h1>
                        <h3>{t('components.formBalance.consume')} {getCreditsToConsume()}{t('components.formBalance.credits')}</h3>
                        <form className="mt-4 d-grid gap-3 text-start needs-validation" onSubmit={confirmForm}>
                            <div className="row form-floating">
                                {/* <input type="text" className="form-control" id="wallet"
                                    value={userInfo.userType === USER_TYPE.ADMIN ? walletAdmin : walletsChoosen.filter(wal => wal.selected)?.map(wal => { return wal.wallet }).toString()}
                                    disabled
                                />
                                <label id="wallet">{getCreditsToConsume() === 1 ? "Wallet" : "Wallets"}</label> */}

                                {walletsChoosen.filter(wal => wal.wallet && wal.selected)?.map(wal => { return wal.wallet }).map((wallet: string, index: number) => (
                                    <div key={index}
                                        className={getClass(wallet)} data-toggle="tooltip" data-placement="top" title={wallet} >
                                        <i className="bi bi-wallet" ></i>&nbsp;&nbsp;
                                        {getWallet(wallet)}
                                    </div>
                                ))}
                                <div className="form-text ms-2">{t('components.formBalance.dataImportance')}</div>
                                <div className="invalid-feedback  px-2">{t('components.formBalance.validAddress')}</div>
                            </div>

                            <div className="row g-2">
                                <div className="col-12">
                                    <div className="col-12 my-auto d-flex justify-content-center">
                                        <button className={"" + (blockchainsChosen.ethereum ? "m-2 btn btn-primary btn-bcs selected" : "m-2 btn btn-light btn-bcs text-center selected")}
                                            onClick={() => { }} disabled={!blockchainsChosen.ethereum} >
                                            <span className="btn-image-md">
                                                <img className="m-1" src={iconBlockchain(BLOCKCHAIN.ETHEREUM)} alt="Imagen" />
                                            </span>
                                            {t('components.formBalance.ethAlt')}
                                        </button>
                                        <button className={"" + (blockchainsChosen.bsc ? "m-2 btn btn-primary btn-bcs selected" : "m-2 btn btn-light btn-bcs text-center selected")}
                                            onClick={() => { }} disabled={!blockchainsChosen.bsc}>
                                            <span className="btn-image-md">
                                                <img className="m-1" src={iconBlockchain(BLOCKCHAIN.BSC)} alt="Imagen" />
                                            </span>
                                            {t('components.formBalance.bscAlt')}
                                        </button>
                                        <button className={"" + (blockchainsChosen.polygon ? "m-2 btn btn-primary btn-bcs selected" : "m-2 btn btn-light btn-bcs text-center selected")}
                                            onClick={() => { }} disabled={!blockchainsChosen.polygon}>
                                            <span className="btn-image-md">
                                                <img className="m-1" src={iconBlockchain(BLOCKCHAIN.POLYGON)} alt="Imagen" />
                                            </span>
                                            {t('components.formBalance.polyAlt')}
                                        </button>

                                    </div>
                                    {/* <input className="m-2" type="checkbox" onChange={() => { }} checked={blockchainsChosen.ethereum} />
                                    <label className="mt-3 m-2">
                                        {t('components.formBalance.ethAlt')}
                                    </label>
                                    <input className="m-2" type="checkbox" onChange={() => { }} checked={blockchainsChosen.bsc} />
                                    <label className="m-2">
                                        {t('components.formBalance.bscAlt')}
                                    </label>
                                    <input className="m-2" type="checkbox" onChange={() => { }} checked={blockchainsChosen.polygon} />
                                    <label className="m-2">
                                        {t('components.formBalance.polyAlt')}
                                    </label> */}
                                </div>
                                <div className="col-12">
                                    <div className="input-group mb-2">
                                        <span className="input-group-text">
                                            <i className="bi bi-calendar"></i>
                                        </span>
                                        <div className="form-floating">
                                            <input id="date_flatpickr" type="text" className="form-control" value={dataInquiry.date} required disabled />
                                            <label id="date_flatpickr">{t('components.formBalance.date')}</label>
                                            <div className="invalid-feedback ms-2 position-absolute">{t('components.formBalance.dateRequired')}</div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className="col form-floating">
                                <select className="form-select" id="validationDefault06" defaultValue={dataInquiry.timeZone} required disabled>
                                    <option disabled >{dataInquiry.timeZone}</option>
                                </select>
                                <label id="validationDefault06">{t('components.formBalance.country')}</label>
                                <div className="invalid-feedback  px-2">{t('components.formBalance.countryRequired')}</div>
                            </div>
                            <div className="form-check">
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <input className="form-check-input" type="checkbox" value="" id="datosCheck" required />
                                    </div>
                                    <div className="col-auto m-0 p-0">
                                        <label className="form-check-label" id="datosCheck">
                                            {t('components.formBalance.dataVerification')}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary">
                                {t('components.formBalance.consultNow')}
                            </button>
                        </form>
                    </div>
                </div>
                )}
            </Modal>

        </>
    );
}

export default FormBalance;