import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TRUST_LEVEL_TOKEN, Token } from "../models/models";


type FilterTokensValues = {
    byUser: boolean,
    showPending: boolean,
    showScam: boolean,
    showValid: boolean,
}

type FilterTokensProps = {
    tokens: Token[],
    filterText: string,
    setFilterText: any
    setShowAddToken: any,
    setTokensFiltered: any,
    setSomeToken : any,
    setFilterId : any
}
function FiltersTokens({ setShowAddToken, filterText, tokens, setTokensFiltered, setFilterText, setSomeToken, setFilterId }: FilterTokensProps) {

    const [filterValues, setFilterValues] = useState<FilterTokensValues>({
        byUser: true,
        showPending: true,
        showScam: true,
        showValid: true,
    });
    const { t } = useTranslation('common');

    const filterToken = (token: Token) => {
        const isFiltered = token.name?.toUpperCase().includes(filterText.toUpperCase()) ||
            token.symbol?.toUpperCase().includes(filterText.toUpperCase()) ||
            token.address?.toUpperCase().includes(filterText.toUpperCase());
        return isFiltered;

    }

    const countTokensFiltered = () => {

        const tokensFiltered = tokens.filter((token: Token) => filterToken(token));
        if (tokensFiltered?.length) setSomeToken(true);
        else setSomeToken(false);
    }

    useEffect(() => {

        const filterTokens = (tokens: Token[]) => {
            const tokensFiltered: Token[] = []
            tokens.forEach((token: Token) => {

                if (!filterValues.byUser && token.trustLevel === TRUST_LEVEL_TOKEN.NOT_DEFINED) {
                    return false;
                }
                if (!filterValues.showPending && token.trustLevel === TRUST_LEVEL_TOKEN.PENDING) {
                    return false;
                }
                if (!filterValues.showScam && (token.trustLevel === TRUST_LEVEL_TOKEN.SCAM || token.trustLevel === TRUST_LEVEL_TOKEN.SUSPICIOUS)) {
                    return true;
                }
                if (!filterValues.showValid && token.trustLevel === TRUST_LEVEL_TOKEN.VALID) {
                    return false;
                }
                tokensFiltered.push(token);
            });

            return tokensFiltered;
        }

        if (tokens) {
            // console.log(tokens);
            const tokenFiltered = filterTokens(tokens);
            setTokensFiltered(tokenFiltered)
            // console.log("TOKENS FILTERED: ", tokenFiltered);
        }


    }, [filterValues, tokens, setTokensFiltered]);

    return (<>
        <div className="row px-1 py-4 rounded-top-4 mt-auto text-light-emphasis bg-light-subtle">
            <div className="row mt-5 row-gap-3 me-1">
                <div className="col-12 my-auto ms-auto">
                    <div className="row row-gap-3 justify-content-center">
                        <div className="col-auto my-auto">
                            <div className="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
                                <label className={"btn btn-outline-primary align-middle-lg" + (false ? " active" : "")}
                                    onClick={() => { setShowAddToken(true) }} >
                                    <i className="bi bi-plus-square "></i>&nbsp;&nbsp;
                                    {t('components.filtersTokens.addToken')}
                                </label>
                            </div>
                        </div>
                        <div className="col-auto my-auto">
                            <div className="form-check form-switch text-nowrap mb-0">
                                {/* <label className="form-control" >Buscar&nbsp;&nbsp;</label>  
                            <input className="" type="text"  onChange={(e) => {handleSearchText(e.target.value) }} /> */}
                                <div className="form-floating">
                                    <input type="text" className="form-control" value={filterText} onChange={(e) => { setFilterText(e.target.value); countTokensFiltered();}} />
                                    <label>{t('views.tokens.filterText')}</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-auto my-auto ">
                            <div className="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
                                <label className={"btn btn-outline-primary align-middle-lg" + (filterValues.byUser ? " active" : "")}
                                    onClick={() => { setFilterValues({ ...filterValues, byUser: !filterValues.byUser }) }} >
                                    {t('components.filtersTokens.addedByUsers')}
                                </label>
                                <label className={"btn btn-outline-primary align-middle-lg" + (filterValues.showPending ? " active" : "")}
                                    onClick={() => { setFilterValues({ ...filterValues, showPending: !filterValues.showPending }) }} >
                                    {t('components.filtersTokens.pendingReview')}
                                </label>
                                <label className={"btn btn-outline-primary align-middle-lg" + (filterValues.showScam ? " active" : "")}
                                    onClick={() => { setFilterValues({ ...filterValues, showScam: !filterValues.showScam }) }} >
                                    {t('components.filtersTokens.scam')}
                                </label>
                                <label className={"btn btn-outline-primary align-middle-lg" + (filterValues.showValid ? " active" : "")}
                                    onClick={() => { setFilterValues({ ...filterValues, showValid: !filterValues.showValid }) }} >
                                    {t('components.filtersTokens.valid')}
                                </label>
                                
                            </div>
                        </div>
                        <div className="col-auto my-auto">
                            <div className="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
                                <label className={"btn btn-outline-primary align-middle-lg" + (false ? " active" : "")}
                                    onClick={() => { setFilterId(''); setFilterText('') }} >
                                    <i className="bi bi-trash "></i>&nbsp;&nbsp;
                                    {t('components.filtersTokens.deleteFilters')}
                                </label>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default FiltersTokens;