import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../app/hooks";
import WalletsData from "../components/walletsData";
import { USER_TYPE, selectCollapsed, selectJWT, selectTokens, selectUserInfo, setError, setTokens } from "../features/user/user";
import { BLOCKCHAIN, Token } from "../models/models";
import { AdminService } from "../services/admin.service";
import { useLocation, useNavigate } from "react-router-dom";
import BalanceLoader from "../loaders/BalanceLoader";
import { Button, Modal } from "react-bootstrap";
import FormToken from "../components/FormToken";
import { JsonViewer } from "@textea/json-viewer";
import AddAssetForm from "../components/AddAssetForm";
import FiltersTokens from "../components/FiltersTokens";
import TokensTable from "../components/TokensTable";


function Tokens() {

    const dispatch = useAppDispatch();
    const tokensStore = useSelector(selectTokens);
    const userInfo = useSelector(selectUserInfo);
    const jwt = useSelector(selectJWT);
    const [tokens, setTokensData] = useState<Token[]>([]);
    const [tokensFiltered, setTokensFiltered] = useState<Token[]>([]);
    const [idToken, setIdToken] = useState<string>('')
    const [showTokenModal, setShowTokenModal] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [infoSelected, setInfoSelected] = useState<Token>();
    const [showAddToken, setShowAddToken] = useState<boolean>(false);
    const [filterText, setFilterText] = useState<string>('');
    const [filterId, setFilterId] = useState<string>('');
    const location = useLocation();
    const [someToken, setSomeToken] = useState(true);
	const collapsed = useSelector(selectCollapsed);


    const iconBlockchain = (blockchain?: BLOCKCHAIN) => {
        return blockchain === BLOCKCHAIN.ETHEREUM ? './assets/img/blockchains/ethereum.png' :
            blockchain === BLOCKCHAIN.POLYGON ? './assets/img/blockchains/polygon.png' : './assets/img/blockchains/binance.png'
    }


    useEffect(() => {

        const callTokens = async () => {

            try {
                const tokens: Token[] = await AdminService.tokens(jwt);
                setLoading(false);
                setTokensData(tokens);
                dispatch(setTokens(tokens));
            } catch (error: any) {
                setLoading(false);
                console.error('Error get current balance:', error);
                dispatch(setError({ title: 'Balance error', desc: 'Error has ocurred trying get your balance' }))
            }

        }

        if (userInfo.userType !== USER_TYPE.ADMIN) {
            navigate('/balance');
        }

        if (tokensStore?.length > 0) {
            setLoading(false);
            setTokensData(tokensStore)
        }
        else {
            callTokens();
        }

        const queryParams = new URLSearchParams(location.search);
        const filt = queryParams.get('filter') ? queryParams.get('filter') : '';
        const id = queryParams.get('id') ? queryParams.get('id') : '';
        setFilterText(filt !== null ? filt.replace(/[^a-zA-Z0-9\s-_.:]/g, '') : '');
        setFilterId(id !== null ? id : '' );

    }, [jwt, dispatch, navigate, tokensStore, userInfo]);

    return (
        // <div className="row rounded-bottom-4 table-responsive z-0 px-1 text-light-emphasis bg-light-subtle">
        <>
            <div id="dashboard" className={collapsed ? " d-grid align-content-start vh-100 pt-4 px-4 pb-1 overflow-auto main-content-collapsed" : " d-grid align-content-start vh-100 pt-4 px-4 pb-1 overflow-auto main-content-expanded"  }>
                <WalletsData setInquiryId={() => { }} />

                <FiltersTokens tokens={tokens} setShowAddToken={setShowAddToken} setTokensFiltered={setTokensFiltered} filterText={filterText} setFilterText={setFilterText} setFilterId={setFilterId} setSomeToken={setSomeToken}/>
                {/* <Filters balances={balances} iconBlockchain={iconBlockchain} setFiltered={ ( newBalances : Balance[]) => setTokens(newBalances[0].tokens)} /> */}
                {loading && (<BalanceLoader />)}
                {!loading && tokensFiltered.length === 0 && (
                    <div className="my-5 text-center red">
                        <h4 className="h6">{t('views.tokens.noTokens')}</h4>
                    </div>
                )}
                {!loading && (

                    <TokensTable tokens={tokensFiltered.length !== 0 && tokens.length !== tokensFiltered.length ? tokensFiltered : tokens} filterId={filterId} filterText={filterText} iconBlockchain={iconBlockchain} 
                                setIdToken={setIdToken} setShowTokenModal={setShowTokenModal} setInfoSelected={setInfoSelected} setShowModal={setShowModal} someToken={someToken}
                                setFilterText={setFilterText} />
                )}
                
            </div>
            <Modal
                onHide={() => { setShowModal(false) }}
                show={showModal}
                size="xl"
            >
                <Modal.Header>
                    <Modal.Title>{infoSelected?.name}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <JsonViewer value={infoSelected} />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setShowModal(false) }}>{t('views.tokens.close')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                onHide={() => { setShowTokenModal(false) }}
                show={showTokenModal}
                size="sm"
            >
                <Modal.Header>
                    <Modal.Title>{t('views.tokens.tokenInfo')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <FormToken idToken={idToken} />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setShowTokenModal(false) }}>{t('views.tokens.close')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                onHide={() => { setShowAddToken(false) }}
                show={showAddToken}
                size="lg"
            >
                <AddAssetForm setShowAddToken={setShowAddToken} />
            </Modal>
        </>
    );
}

export default Tokens;