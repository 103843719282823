import { useSelector } from "react-redux";
import { InvoiceData, selectJWT, selectUserInfo, setError, setInvoiceData, setSuccess } from "../features/user/user";
import { UserService } from "../services/user.service";
import { useAppDispatch } from "../app/hooks";
import { useTranslation } from "react-i18next";


function FormInvoice() {

    const userInfo = useSelector(selectUserInfo);
    const jwt = useSelector(selectJWT);
    const dispatch = useAppDispatch();
    const { t } = useTranslation('common');

    const saveInvoiceData = async (event: React.SyntheticEvent) => {

        event.preventDefault();

        const target = event.target as typeof event.target & {
            businessName: { value: string };
            cif: { value: string };
            address: { value: string };
        };

        const data: InvoiceData = {
            businessName: target.businessName.value,
            cif: target.cif.value,
            address: target.address.value,
        }
        
        try {

            await UserService.saveInvoiceData(data, jwt);
            dispatch(setInvoiceData(data));
            dispatch(setSuccess({ title: "Save data", desc: "Invoice data saved correctly" }));
        } catch (error) {
            dispatch(setError({ title: 'Save data error', desc: 'Error has ocurred trying save your profile data' }));

        }
    }

    const getPlaceholder = () : string => {
        return t('components.x.y') ? t('components.x.y') : "Dirección";
    }

    return (
        <form className="mt-4 d-grid gap-3 text-start needs-validation" onSubmit={saveInvoiceData}>
            <h3 className="h5">{t('components.formInvoice.invoiceInfo')}</h3>

            <div className="form-floating">
                <input type="text" className="form-control" name="businessName" defaultValue={userInfo?.invoiceData?.businessName} placeholder="Nombre de la empresa" required />
                <label >{t('components.formInvoice.companyName')}</label>
                <div className="invalid-feedback">{t('components.formInvoice.letCompanyName')}</div>
            </div>

            <div className="form-floating">
                <input type="text" className="form-control" name="cif" defaultValue={userInfo?.invoiceData?.cif} placeholder="Identificación fiscal (CIF)" required />
                <label >{t('components.formInvoice.fiscal')}</label>
                <div className="invalid-feedback">{t('components.formInvoice.letFiscal')}</div>
            </div>

            <div className="form-floating">
                <input type="text" className="form-control" name="address" defaultValue={userInfo?.invoiceData?.address} placeholder={getPlaceholder()} required />
                <label >{t('components.formInvoice.address')}</label>
                <div className="invalid-feedback">{t('components.formInvoice.letAddress')}</div>
            </div>
            <div className="form-check">
                <div className="row align-items-center">
                    <div className="col-auto">
                        <input className="form-check-input" type="checkbox" value="" id="legalCheck" required />
                    </div>
                    <div className="col-auto m-0 p-0">
                        <label className="form-check-label">
                            {t('components.formInvoice.confirmInfo')}
                        </label>
                    </div>
                </div>
                <div className="invalid-feedback">{t('components.formInvoice.checkCompanyInfo')}</div>
            </div>
            <div className="ms-auto">
                <button type="submit" className="btn btn-primary">{t('components.formInvoice.save')}</button>
            </div>
        </form>
    )

}

export default FormInvoice;