import { Button, Form } from "react-bootstrap";
import WalletsData from "../components/walletsData";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BetaUser, ConfigInfo, USER_TYPE, selectCollapsed, selectJWT, selectUserInfo, selectUsers, setError, setSuccess } from "../features/user/user";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../services/admin.service";
import { useAppDispatch } from "../app/hooks";
import { useTranslation } from "react-i18next";


function AdminCondiguration() {


    const userInfo = useSelector(selectUserInfo);
    const users = useSelector(selectUsers);
    const navigate = useNavigate();
    const jwt = useSelector(selectJWT);
    const [config, setConfig] = useState<ConfigInfo>();
    const [betaUsers, setBetaUsers] = useState<BetaUser[]>([]);
    const [betaWallet, setBetaWallet] = useState<string>('');
    const dispatch = useAppDispatch();
    const { t } = useTranslation('common');
	const collapsed = useSelector(selectCollapsed);

    const handleFormBetaUsers = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        const target = event.target as typeof event.target & {
            userWallet: { value: string };
            checkSure: { checked: boolean }
        };

        const data: any = {
            wallet: target.userWallet.value
        }

        const userListed = betaUsers?.some( (user : BetaUser ) => user.wallet === data.wallet);

        if(userListed){
            dispatch(setError({
                title: t('messagesFail.betaUsers.title'),
                desc: t('messagesFail.betaUsers.userAlready')
            }));
            setBetaWallet('');
            return;
        }

        try {

            const myConfig: ConfigInfo = await AdminService.saveConfiguration({ betaUser: target.userWallet.value }, jwt);
            dispatch(setSuccess({ title: "Datos guardados correctamente", desc: "Wallet añadida correctamente" }));
            setBetaUsers(betaUsers.concat({wallet : data.wallet , isBeta : true}));
            setConfig(myConfig);
            setBetaWallet('');

        } catch (error) {
            console.log("ERROR");
        }


    };

    const handleFormUpdateBetaUsers = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        const target = event.target as typeof event.target & {
            confirmUpdateBetaUsers: { checked: boolean }
        };

        const confirmed = target.confirmUpdateBetaUsers.checked;

        try {

            if (!confirmed) throw new Error('confirm');

            const newConfig: ConfigInfo = await AdminService.saveConfiguration({ betaUsers: config?.betaUsers }, jwt);
            // console.log("NEW INFO", newConfig);
            dispatch(setSuccess({ title: t('messagesSucces.betaUsers.title'), desc: t('messagesSucces.betaUsers.description') }));
            // setBetaUsers(newConfig.betaUsers);
            // setConfig(newConfig);

        } catch (error) {
            console.log("ERROR");
            const msgDetail = error?.toString().includes('confirm') ? t('messagesFail.betaUsers.confirm') : t('messagesFail.betaUsers.description');
            dispatch(setError({
                title: t('messagesFail.config.title'),
                desc: msgDetail
            }));
        }


    };

    const handleFormConfig = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        const target = event.target as typeof event.target & {
            credits: { value: number };
            checkCredits: { checked: boolean }
            priceCredit: { value: number };
            checkPriceCredit: { checked: boolean }
            priceCertificate: { value: number };
            checkPriceCertificate: { checked: boolean };
            betaChecked : {checked : boolean};
            betaCheckedClear : {checked : boolean};
        };

        const data: any = {
            creditsDefault: target.checkCredits.checked ? target.credits.value : undefined,
            priceCertificate: target.checkPriceCertificate.checked ? target.priceCertificate.value : undefined,
            priceCredits: target.checkPriceCredit.checked ? target.priceCredit.value : undefined,
            betaPhase : target.betaCheckedClear.checked ? target.betaChecked.checked : undefined,
        }

        console.log("data", data);
        try {

            if (!data.creditsDefault && !data.priceCertificate && !data.priceCredits && data.betaPhase === undefined) throw new Error('noData');

            const myConfig: ConfigInfo = await AdminService.saveConfiguration(data, jwt);
            dispatch(setSuccess({ title: t('messagesSucces.config.title'), desc: t('messagesSucces.config.description') }));
            console.log("NEW INFO", myConfig);
            // setBetaUsers(config.betaUsers);
            setConfig(myConfig);

        } catch (error) {
            const msgDetail = error?.toString().includes('noData') ? t('messagesFail.config.form') : t('messagesFail.config.description');
            dispatch(setError({
                title: t('messagesFail.config.title'),
                desc: msgDetail
            }));
        }


    };

    const handleCheckWallet = (userWallet: any, event: any) => {

        const isChecked = event.target.checked;


        betaUsers?.forEach((beta: BetaUser) => {
            if (beta.wallet.toUpperCase() === userWallet.toUpperCase()) {
                beta.isBeta = isChecked;
            }
        })
        setConfig(config);
    }


    const getAlias = (wallet : string) => {
        const user = users?.find( user => user.wallets?.some( wall => wall?.wallet?.toUpperCase() === wallet?.toUpperCase() ));
        return user?.wallets?.find( wall => wall?.wallet?.toUpperCase() === wallet?.toUpperCase() ).alias ? user.wallets?.find( wall => wall?.wallet?.toUpperCase() === wallet?.toUpperCase() ).alias + ' - ' + wallet  : wallet;
    }

    useEffect(() => {

        const getCurrentConfiguration = async () => {
            const currentConfig: ConfigInfo = await AdminService.currentConfiguration(jwt);
            setConfig(currentConfig);
            setBetaUsers(currentConfig.betaUsers);
            console.log("currentConfig : ", currentConfig);
        }

        if (userInfo.userType !== USER_TYPE.ADMIN) navigate("/balance");

        if (!config) getCurrentConfiguration();


    }, [userInfo, betaUsers, config])
    return (
        <>
            <div id="dashboard" className={collapsed ? " d-grid align-content-start vh-100 pt-4 px-4 pb-1 overflow-auto main-content-collapsed" : " d-grid align-content-start vh-100 pt-4 px-4 pb-1 overflow-auto main-content-expanded"  }>
                <WalletsData setInquiryId={() => { }} />
                {config ? (
                    <div className="row m-2">
                        <div className="col-4 m-5">
                            <Form onSubmit={handleFormConfig}>

                                <h4>Etapa lanzamiento aplicación:</h4>
                                <br />
                                <Form.Group className="mb-3" controlId="betaChecked">
                                    <Form.Check type="checkbox" defaultChecked={config.betaPhase} style={{ fontWeight: 'bold' }} label="Phase Beta activada" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="betaCheckedClear">
                                    <Form.Check type="checkbox" style={{ fontWeight: 'bold' }} label="Estoy seguro que quiero modificar la fase del proyecto" />
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Guardar
                                </Button>
                                <br /><br /><br />
                                <Form.Group className="mb-3" controlId="credits">
                                    <Form.Label><b>Creditos por defecto</b> </Form.Label>
                                    <Form.Control type="number" defaultValue={config.creditsDefault} placeholder="Créditos por defecto iniciales al crear un usuario" />
                                    <Form.Text className="text-muted">
                                        Créditos por defecto iniciales al crear un usuario
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="checkCredits">
                                    <Form.Check type="checkbox" style={{ fontWeight: 'bold' }} label="Estoy seguro que quiero modificar este dato" />
                                </Form.Group>
                                <br /><br />
                                <Form.Group className="mb-3" controlId="priceCredit">
                                    <Form.Label><b>Coste créditos</b></Form.Label>
                                    <Form.Control type="number" defaultValue={config.priceCredits} placeholder="Coste de cada crédito al consumirse por unidad" />
                                    <Form.Text className="text-muted">
                                        Coste de cada crédito al consumirse por unidad
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="checkPriceCredit">
                                    <Form.Check type="checkbox" style={{ fontWeight: 'bold' }} label="Estoy seguro que quiero modificar este dato" />
                                </Form.Group>
                                <br /><br />
                                <Form.Group className="mb-3" controlId="priceCertificate">
                                    <Form.Label><b>Créditos consumidos por emisión de certificado</b></Form.Label>
                                    <Form.Control type="number" defaultValue={config.priceCertificate} placeholder="Coste de cada certificado" />
                                    <Form.Text className="text-muted">
                                        Coste de cada certificado
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="checkPriceCertificate">
                                    <Form.Check type="checkbox" style={{ fontWeight: 'bold' }} label="Estoy seguro que quiero modificar este dato" />
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Guardar
                                </Button>
                                <br /><br /><br />
                                
                            </Form>
                        </div>
                        <div className="col-4 m-5">

                            <Form onSubmit={handleFormBetaUsers}>
                                <Form.Group className="mb-3" controlId="userWallet">
                                    <Form.Label>Añadir usuario Beta </Form.Label>
                                    <Form.Control type="text" value={betaWallet} placeholder="Créditos por defecto iniciales al crear un usuario" onChange={ e => { setBetaWallet(e.target.value)}} />
                                    <Form.Text className="text-muted">
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="checkSure" >
                                    <Form.Check type="checkbox" required style={{ fontWeight: 'bold' }} label="Estoy seguro que quiero añadir este usuario" />
                                </Form.Group>

                                <Button variant="primary" type="submit">
                                    Guardar
                                </Button>
                            </Form>
                            <br /><br />
                            <h2>Usuarios Beta</h2>
                            {betaUsers && betaUsers?.length > 0 && (
                                <Form onSubmit={handleFormUpdateBetaUsers}>
                                    {betaUsers?.map((user: BetaUser, index: number) => (
                                        <>
                                            <br />
                                            <Form.Group  key={index} className="mb-3 pointer" controlId={user.wallet} onChange={(e) => handleCheckWallet(user.wallet, e)} >
                                                <Form.Check  type="checkbox"  defaultChecked={user.isBeta} label={getAlias(user.wallet)} data-toggle="tooltip" data-placement="top" title={user.wallet}/>
                                            </Form.Group>

                                        </>
                                    ))}
                                    <>
                                        <Form.Group className="mb-3" controlId="confirmUpdateBetaUsers">
                                            <Form.Check type="checkbox" style={{ fontWeight: 'bold' }} label="Estoy seguro que quiero modificar los usuarios beta testers" />
                                        </Form.Group>
                                        <Button variant="primary" type="submit">
                                            Guardar
                                        </Button>
                                    </>
                                </Form>
                            )}

                        </div>
                    </div>
                ) : (
                    <>Error al consultar la configuración</>
                )}

            </div>

        </>
    );

}


export default AdminCondiguration;