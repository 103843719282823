import { useEffect, useState } from "react";
import { ResponseWalletAdded, USER_TYPE, WalletInfo, addWalletToUser, selectCurrency, selectJWT, selectUserInfo, selectWallet, setError, setSuccess, setUserInfo, setCurrency, CURRENCY, selectCurrencySymbol, setAliasWallet, addCreditsAvailable, setNotification, cleanNotification, addWalletAssociatedToUser, cleanSuccess, selectFiat, selectFiatEur } from "../features/user/user";
import { useSelector } from "react-redux";
import { ethers } from "ethers";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import { useAppDispatch } from "../app/hooks";
import { UserService } from "../services/user.service";
import { useNavigate } from "react-router-dom";
import AddAssetForm from "./AddAssetForm";
import { useTranslation } from "react-i18next";
import Spinner from "./Spinner";
/* import './../components/styles/walletsData.scss' */

function WalletsData({ setInquiryId }: any) {

    const [dropDownWallet, setDropdownWallet] = useState(false);
    const [walletCopied, setWalletCopied] = useState(-1);
    const userInfo = useSelector(selectUserInfo);
    const jwt = useSelector(selectJWT);
    const wallet = useSelector(selectWallet);
    const currency = useSelector(selectCurrency);
    const currencySymbol = useSelector(selectCurrencySymbol);
    const [jwtWalletCopied, setJwtWalletCopied] = useState(false);
    const [showWalletModal, setShowWalletModal] = useState(false);
    const [jwtWallet, setJWTWallet] = useState();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [showAddToken, setShowAddToken] = useState(false);
    const [showModalAlias, setShowModalAlias] = useState(false);
    const [loading, setLoading] = useState(false);
    const [walletChoosen, setWalletChoosen] = useState(wallet);
    const { t } = useTranslation('common');
    const balanceFiat = useSelector(selectFiat);
    const balanceFiatEur = useSelector(selectFiatEur);


    const getUrlAccount = async () => {
        const jwtAddAccount = await UserService.getUrlForVinculate(jwt);
        setJWTWallet(jwtAddAccount.jwtUrl);
    }

    const copyWallet = (index: number) => {
        navigator.clipboard.writeText(userInfo.wallets ? userInfo.wallets[index] : '');
        setWalletCopied(index);
        setTimeout(() => setWalletCopied(-1), 3000);
    };

    const addAccount = async () => {

        try {

            const provider = new ethers.BrowserProvider((window as any).ethereum);
            const resp: any = await provider.send('wallet_requestPermissions', [{
                eth_accounts: {}
            }]);
            const walletsConnected: string[] = [];
            const walletsAlreadyConnected: string[] = [];
            resp[0]?.caveats[0]?.value.forEach((walletConnected: string) => {
                if (!userInfo.wallets?.includes(walletConnected)) {
                    walletsConnected.push(walletConnected);
                }
                else walletsAlreadyConnected.push(walletConnected);
            });

            if (walletsAlreadyConnected.length) {
                dispatch(setNotification({ title: 'Wallets ya conectadas', desc: !walletsConnected.length ? 'Todas las wallets seleccionadas, ya fueron conectadas.' : 'Algunas de las wallets seleccionadas, ya fueron conectadas.', "moreInfo": walletsAlreadyConnected.toString() }))
                setTimeout(() => {
                    dispatch(cleanNotification())
                }, 3000);
                if (!walletsConnected.length) {
                    setShowWalletModal(false);
                }
            }
            let saved: WalletInfo[];

            if (walletsConnected.length) {
                saved = await UserService.saveWalletsToUser(walletsConnected, jwt);
                const walletsAsociated: string[] = [];
                const walletsErrors: string[] = [];
                const walletsDone: string[] = [];
                if (saved) {
                    saved.forEach((userWallet: WalletInfo) => {
                        if (userWallet.wallet) {
                            if (userWallet.error) {
                                walletsErrors.push(userWallet.wallet)
                            }
                            else if (userWallet.status) {
                                walletsAsociated.push(userWallet.wallet);
                                dispatch(addWalletToUser(userWallet.wallet));
                                dispatch(addWalletAssociatedToUser(userWallet.wallet));
                            }
                            else {
                                walletsDone.push(userWallet.wallet);
                                dispatch(addWalletToUser(userWallet.wallet))
                            }
                        }
                    });
                    dispatch(addCreditsAvailable(walletsDone.length * 3));

                    let desc = walletsDone.length ? 'Tu wallet fue finculada con exito.' : 'No se ha vinculado ninguna wallet.';
                    let moreInfo = walletsErrors.length ? 'Algunas wallets no fueron vinculadas debido a errores :' + walletsErrors.toString() + '. ' : '';
                    moreInfo += walletsAsociated.length ? 'Algunas wallets fueron asociadas a tu cuenta y pertenecen a otro usuario: ' + walletsAsociated.toString() + '.' : '';

                    setTimeout(() => {
                        setShowWalletModal(false);
                        dispatch(setSuccess({ title: walletsDone.length ? "Wallet vinculada!" : 'Wallet no vinculada', desc, moreInfo: moreInfo !== '' ? moreInfo : walletsDone.toString() }));
                    }, 3000);
                    setTimeout(() => {
                        dispatch(cleanSuccess());
                    }, 6000);

                }
                else {
                    setTimeout(() => {
                        setShowWalletModal(false);
                        dispatch(setError({ title: "Error DApp", desc: "Error when trying to add wallets" }));
                    }, 2000);
                }
            }

        } catch (error) {
            setTimeout(() => {
                setShowWalletModal(false);
                dispatch(setError({ title: "Error DApp", desc: "Error when trying to add wallets" }));
            }, 2000);
        }

    }

    const saveAliasWallet = async (event: React.SyntheticEvent) => {

        event.preventDefault();

        try {

            const target = event.target as typeof event.target & {
                alias: { value: string }
            };

            const body = { wallet: walletChoosen, alias: target.alias.value };
            const user = await UserService.saveAliasToUser(body, jwt);
            dispatch(setAliasWallet(body));
            dispatch(setSuccess({ title: "Datos guardados", desc: "Alias guardado correctamente" }));
            setShowModalAlias(false);
            return;


        } catch (error) {
            setLoading(false);
            console.error('Error save alias wallet', error);
            dispatch(setError({ title: 'User error', desc: 'Error save alias wallet' }))
            setShowModalAlias(false);
        }
    }

    const copyJWTWallet = () => {
        navigator.clipboard.writeText(jwtWallet ? jwtWallet : '');
        setJwtWalletCopied(true);

        setTimeout(() => {
            setJwtWalletCopied(false);
        }, 1000);

    };

    const handleForm = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        const target = event.target as typeof event.target & {
            jwtWallet: { value: string };
        };

        const data: any = {
            jwt: target.jwtWallet.value
        }

        const saved: ResponseWalletAdded = await UserService.saveWalletWithJWT(data, jwt);

        if (saved?.user?._id) {
            setShowWalletModal(false);
            dispatch(setSuccess({ title: "Wallet linked!", desc: "Your wallet was vinculated to existent user" }));
            dispatch(setUserInfo({ _id: saved.user._id, userType: saved.user.userType, wallets: saved.wallets }));
            dispatch(addCreditsAvailable(saved.wallets.length * 3));
        }
        else {
            setShowWalletModal(false);
            dispatch(setError({ title: "Error DApp", desc: "Error when trying connect to other account" }));
        }
    };

    const getLiteral = (pathLiteral: string): string => {
        return t(pathLiteral) ? t(pathLiteral) : '';
    }

    useEffect(() => {
    }, [userInfo]);

    return (
        <>
            <div className="row row-gap-3">
                {/* <h1 className="h3 me-4 col-12 col-lg-auto">{t('components.walletsData.accounts')}</h1> */}
                {userInfo.userType !== USER_TYPE.ADMIN && (
                    <div className="col-auto">
                        <div className="text-nowrap">
                            {userInfo.creditsAvailable !== undefined && userInfo.creditsAvailable > 0 && (
                                <button className="m-1 btn btn-outline-primary pe-none text-nowrap ">
                                <i className="bi bi-coin"></i>
                                <span className="ms-2">{t('components.walletsData.credits')}: {userInfo?.creditsAvailable && userInfo.creditsAvailable >= 0 ? userInfo.creditsAvailable : 0}</span>
                            </button>)}
                            {userInfo.creditsAvailable !== undefined && userInfo.creditsAvailable <= 0 && (
                                <button className="m-1 btn btn-outline-primary text-nowrap" onClick={() => { navigate('/plans') }}>
                                    <i className="bi bi-cart"></i>
                                    <span className="ms-2">{t('components.walletsData.buyCredits')}</span>
                                </button>)}
                        </div>
                    </div>
                )}
                {userInfo.userType === USER_TYPE.ADMIN && (
                    <div className="col-auto m-1">
                        <button className="ml-2 btn btn-outline-primary pe-none text-nowrap text-valido-ranking">
                            <i className="bi bi-gear-fill"></i>
                            <span className="ms-2">{t('components.walletsData.admin')}</span>
                        </button>
                    </div>
                )}
                <div className="col-auto">
                    <div className="text-nowrap">
                        <div className="btn-group btn-group-sm me-1 m-1">

                            <Dropdown show={dropDownWallet} data-bs-theme="dark" >
                                <Dropdown.Toggle id="dropdown-basic" onClick={() => { setDropdownWallet(!dropDownWallet) }} style={{ minWidth: '300px' }}>
                                    <span className="ms-2 align-middle-md" data-toggle="tooltip" data-placement="top" title={wallet}>
                                        <small>{userInfo?.walletsData?.find(wall => wall.wallet === wallet && wall.alias) ? userInfo?.walletsData?.find(wall => wall.wallet === wallet && wall.alias)?.alias + ` -  ${wallet?.slice(0, 4)}. . .${wallet?.slice(-4)}` : `${wallet?.slice(0, 10)}. . .${wallet?.slice(-10)}`}</small>
                                    </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{ width: '300px' }} >

                                    {userInfo?.wallets && userInfo.wallets.map((wallet: string, index: number) => (
                                        (wallet !== null && wallet !== undefined && (
                                        <Dropdown.Item key={index} className="row" style={{ width: '280px' }} onClick={() => { }}>
                                            <td className=" text-nowrap" style={{ minWidth: '210px' , maxWidth : '210px' }}>
                                                <span className="btn-image-md"><i className="bi bi-wallet"></i></span>&nbsp;&nbsp;
                                                {userInfo?.walletsData?.find(wall => wall.wallet === wallet && wall.alias) ? userInfo?.walletsData?.find(wall => wall.wallet === wallet && wall.alias)?.alias + ` -  ${wallet?.slice(0, 4)}. . .${wallet?.slice(-4)}` : `${wallet?.slice(0, 10)}. . .${wallet?.slice(-10)}`}
                                            </td>
                                            <td className=" text-nowrap" style={{ minWidth: '10' }}>
                                                <i onClick={() => { copyWallet(index) }} className={walletCopied === index ? "col bi bi-clipboard-check-fill" : " col bi bi-clipboard-plus"} data-toggle="tooltip" data-placement="top" title={getLiteral('views.balance.linkButton')}></i>
                                            </td>
                                            <td className="text-nowrap" style={{ minWidth: '10px' }}>
                                                {!userInfo?.walletsAssociated?.includes(wallet) ? (
                                                    <i onClick={() => { setWalletChoosen(userInfo.wallets ? userInfo.wallets[index] : wallet); setShowModalAlias(true) }} className="col bi bi-pencil-square" data-toggle="tooltip" data-placement="top" title={getLiteral('views.balance.copyButton')}></i>
                                                ) : (
                                                    <i className="col bi bi-pen" data-toggle="tooltip" data-placement="top" title={getLiteral('views.balance.copyButton')}></i>
                                                )}
                                            </td>
                                            
                                            {/* <span className="row col-12 btn-image-md"><i className="bi bi-wallet"></i>
                                                <span className="col-auto ms-2 align-middle-md" data-toggle="tooltip" data-placement="top" title={wallet}>
                                                    <small>{userInfo?.walletsData?.find( wall => wall.wallet === wallet && wall.alias) ? userInfo?.walletsData?.find( wall => wall.wallet === wallet && wall.alias)?.alias + ` -  ${wallet?.slice(0, 6)}. . .${wallet?.slice(-4)}` : `${wallet?.slice(0, 6)}. . .${wallet?.slice(-4)}`}</small>
                                                </span>
                                                <div className="col-auto text-nowrap pointer" onClick={() => { copyWallet(index) }}>
                                                    <i className={walletCopied === index ? "col bi bi-clipboard-check-fill" : " col bi bi-clipboard-plus"} data-toggle="tooltip" data-placement="top" title={getLiteral('views.balance.linkButton')}></i>
                                                </div>
                                                {!userInfo?.walletsAssociated?.includes(wallet) && ( 
                                                    <div className="col-auto text-nowrap pointer" onClick={() => {setWalletChoosen(userInfo.wallets ? userInfo.wallets[index] : wallet); setShowModalAlias(true)}} ><i className="col bi bi-pencil-square"  data-toggle="tooltip" data-placement="top" title={getLiteral('views.balance.copyButton')}></i></div>
                                                )}
                                            </span> */}
                                            {/* <Form.Check type="checkbox" className={"text-nowrap text-" + classTrust[4] + "-ranking"} label={t('components.filters.trust.VALID')} checked={filterValues.trustLevels.find(t => t.trustLevel === TRUST_LEVEL_TOKEN.VALID)?.active} /> */}
                                        </Dropdown.Item>
                                        ))
                                    ))}
                                    <Dropdown.Item>

                                    <button className="btn btn-light w-100 my-1 text-start" aria-current="page" onClick={() => { setShowWalletModal(true); getUrlAccount() }}>
                                        <i className="bi bi-plus-circle"></i><span className="ms-3">{t('components.walletsData.addWallet')}</span>
                                    </button>
                                    </Dropdown.Item>


                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        {/* <div className="btn-group btn-group-sm me-1">
                            <button type="button" className="btn btn-outline-primary pe-none text-nowrap btn-image-sm border-end-none">
                                <img className="rounded-circle" src="./assets/img/imagen_02.png" alt="Imagen" /><img className="rounded-circle ms--1" src="./assets/img/imagen_03.png" alt="Imagen" /><img className="rounded-circle ms--1" src="./assets/img/imagen_04.png" alt="Imagen" /><span className="ms-2">3 Networks</span>
                            </button>
                            <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split pe-2 border-start-none" data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">
                                <span className="visually-hidden">Toggle Dropdown</span>
                            </button>
                            <div className="dropdown-menu overflow-hidden" style={{ minWidth: "18rem" }} data-bs-theme="light">
                                
                                <div className="row text-bg-primary px-3 py-2 justify-content-between">
                                    <div className="col-auto">
                                        <span>Active Networks</span>
                                    </div>
                                    <div className="col-auto">
                                        <div className="form-check form-switch form-check-reverse text-nowrap mb-0">
                                            <input className="form-check-input border-white" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                                            <label className="form-check-label">Deselect All</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row text-dark px-3 py-1" style={{ marginBottom: "-8px", backgroundColor: "var(--bs-white)" }}>
                                    <table className="table table-sm table-striped table-hover table-borderless align-middle m-0">
                                        <tbody>
                                            <tr>
                                                <td className="text-nowrap ps-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    </div>
                                                </td>
                                                <td className="text-nowrap"><span className="btn-image-md"><img src="./assets/img/imagen_05.png" alt="Imagen" /><span className="ms-2 align-middle-md"><small>0x412b...28803a</small></span></span></td>
                                                <td className="text-nowrap fw-lighter fst-italic px-2">€0.00</td>
                                            </tr>
                                            <tr>
                                                <td className="text-nowrap ps-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    </div>
                                                </td>
                                                <td className="text-nowrap"><span className="btn-image-md"><img src="./assets/img/imagen_05.png" alt="Imagen" /><span className="ms-2 align-middle-md"><small>0x412b...28803a</small></span></span></td>
                                                <td className="text-nowrap fw-lighter fst-italic px-2">€0.00</td>
                                            </tr>
                                            <tr>
                                                <td className="text-nowrap ps-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    </div>
                                                </td>
                                                <td className="text-nowrap"><span className="btn-image-md"><img src="./assets/img/imagen_05.png" alt="Imagen" /><span className="ms-2 align-middle-md"><small>0x412b...28803a</small></span></span></td>
                                                <td className="text-nowrap fw-lighter fst-italic px-2">€9999990.00</td>
                                            </tr>
                                            <tr>
                                                <td className="text-nowrap ps-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    </div>
                                                </td>
                                                <td className="text-nowrap"><span className="btn-image-md"><img src="./assets/img/imagen_05.png" alt="Imagen" /><span className="ms-2 align-middle-md"><small>0x412b...28803a</small></span></span></td>
                                                <td className="text-nowrap fw-lighter fst-italic px-2">€0.00</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> */}
                    </div>

                </div>

                <div className="col-auto ms-xl-auto">
                    <div className="d-flex text-nowrap">
                        <h3 className="h2 e-4 pt-2 col-12 col-lg-auto">{balanceFiat && currency === CURRENCY.USD ? balanceFiat.toFixed(2) : balanceFiatEur && currency === CURRENCY.EUR ? balanceFiatEur.toFixed(2) : '0.0'}&nbsp;{currencySymbol}</h3>&nbsp;&nbsp;&nbsp;
                        {/* <button className="btn btn-primary btn-sm px-3 me-1 text-nowrap" aria-current="page">
                            <i className="bi bi-save "></i><span className="ms-3">Exportar</span>
                        </button> */}
                        {/* <button className="btn btn-primary btn-sm px-3 me-1 text-nowrap" aria-current="page">
                            <i className="bi bi-file-earmark-check "></i><span className="ms-3">Generar Certificado</span>
                        </button>  */}
                        <button className={currency === CURRENCY.EUR ? "m-2 btn btn-outline-primary text-nowrap active" : "m-2 btn btn-outline-primary text-nowrap"} onClick={() => { dispatch(setCurrency(CURRENCY.EUR)) }}>{'EUR €'}</button>&nbsp;
                        <button className={currency === CURRENCY.USD ? "m-2 btn btn-outline-primary text-nowrap active" : "m-2 btn btn-outline-primary text-nowrap"} onClick={() => { dispatch(setCurrency(CURRENCY.USD)) }}>{'USD $'}</button>&nbsp;
                        {/* <button className="ml-2 btn btn-outline-primary text-nowrap" onClick={() => {dispatch(setCurrency( currency === CURRENCY.EUR ? CURRENCY.USD : CURRENCY.EUR ))}}>{currency === CURRENCY.EUR ? 'EUR ' : 'USD ' } {currencySymbol}</button>&nbsp;&nbsp; */}
                        {userInfo.userType === USER_TYPE.FREE && (
                        <button className="btn btn-primary btn-sm px-3 me-1 text-nowrap m-2" aria-current="page" onClick={() => setShowAddToken(true)}>
                            <i className="bi bi-plus-square "></i><span className="ms-3">{t('components.walletsData.addAsset')}</span>
                        </button>)}
                        <button className="btn btn-primary btn-sm px-3 m-2 text-nowrap"
                            onClick={() => { if (window.location.hash.includes('inquiry')) { setInquiryId(undefined) } else navigate('/inquiry') }} aria-current="page">
                            <i className="bi bi-file-earmark-check "></i><span className="ms-3">{t('components.walletsData.newInquiry')}</span>
                        </button>

                    </div>
                </div>
            </div>
            <Modal
                onHide={() => { setShowWalletModal(false) }}
                show={showWalletModal}
                size='lg'
                centered
            >
                <button type="button" className="btn-close position-absolute top-0 end-0 me-3 mt-3" onClick={() => { setShowWalletModal(false) }} aria-label="Close"></button>
                <div className="container">
                    <div className="p-3 my-4 text-center">
                        <div className="mb-4">
                            <i className="display-5 text-info-ranking bi bi-wallet2"></i>
                        </div>
                        <h1 className="h2">{t('components.walletsData.connectWallet')}</h1>
                        <p className="lead">{t('components.walletsData.connectMoreThanOne')} 💡</p>
                        <div className="mt-4 d-grid gap-3 text-start">
                            <div className="row">
                                <div className="col d-grid">
                                    <button type="button" className="btn btn-primary" onClick={addAccount}>
                                        <img src="./assets/img/metamask.png" style={{ maxHeight: "32px" }} alt="img-metamask" /> <span className="ms-2">{t('components.walletsData.connectMetamask')}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {jwtWallet && (
                            <>
                                <div className="row position-relative my-4">
                                    <span className="position-absolute top-50 start-50 translate-middle lead"></span>
                                    <div className="col-6"><hr /></div>
                                    <div className="col-6"><hr /></div>
                                </div>
                                <p className="text-start ms-2 text-center">{t('components.walletsData.codeJWT')}</p>
                                <form className="input-group mb-3 needs-validation" >
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id="floatingInputGroup1" placeholder="Username" aria-describedby="button-addon2" disabled />
                                        <label>{jwtWallet}</label>
                                    </div>
                                    <button className="btn btn-primary" type="submit" id="button-addon2" disabled={jwtWalletCopied} onClick={copyJWTWallet}>{jwtWalletCopied ? 'Copied!' : 'Copy'}</button>
                                </form>
                            </>
                        )}
                        {userInfo?._id && (
                            <>
                                <div className="row position-relative my-4">
                                    <span className="position-absolute top-50 start-50 translate-middle lead"></span>
                                    <div className="col-6"><hr /></div>
                                    <div className="col-6"><hr /></div>
                                </div>
                                <p className="text-start ms-2 text-center">{t('components.walletsData.connectAccountExistent')}</p>
                                <Form className="input-group mb-3 needs-validation" onSubmit={handleForm}>
                                    <Form.Group className="form-floating" controlId="jwtWallet">
                                        <Form.Control type="text" placeholder={"eyJ0eXAiO...."} required />
                                        <Form.Label>{t('components.walletsData.codeJWT')}</Form.Label>
                                        <Form.Text className="text-muted">
                                        </Form.Text>
                                    </Form.Group>
                                    <button className="btn btn-primary" type="submit" id="button-addon2">{t('components.walletsData.vinculate')}</button>
                                </Form>
                                {/* <form className="input-group mb-3 needs-validation" >
                                <div className="form-floating">
                                    <input type="text" className="form-control" id="floatingInputGroup1" placeholder="Username" aria-describedby="button-addon2" required />
                                        <label>Código único de cuenta BalanceShot</label>
                                </div>
                            </form> */}
                            </>
                        )}
                    </div>
                </div>
            </Modal>
            <Modal
                onHide={() => { setShowAddToken(false) }}
                show={showAddToken}
                size="lg"
            >
                <AddAssetForm setShowAddToken={setShowAddToken} />
            </Modal>
            <Modal
                onHide={() => { setShowModalAlias(false) }}
                show={showModalAlias}
                size="sm"
                centered
            >
                <Modal.Header >
                    <Modal.Title >{`Alias:  ${walletChoosen?.slice(0, 6)}. . .${walletChoosen?.slice(-4)}`}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form className="row d-flex justify-content-center m-2" onSubmit={saveAliasWallet}>
                        {userInfo?.walletsData?.some(w => walletChoosen === w.wallet) && (
                            <div className="col-12 form-floating">
                                <input type="text" className="form-control rounded-top-2" id="alias" defaultValue={userInfo?.walletsData?.find(w => walletChoosen === w.wallet) ? userInfo?.walletsData?.find(w => walletChoosen === w.wallet)?.alias : 'pruebas'} required />
                                <label>Alias</label>
                            </div>
                        )}
                        <Button className="mt-4" variant="primary" type="submit">
                            {t('components.walletsData.saveAlias')}
                        </Button>
                        {loading && (
                            <div className='col-lg-12 m-2'>
                                <Spinner />
                            </div>
                        )}
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default WalletsData;