import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import FormBalance from "../components/FormBalance";
import BalanceTable from "../components/BalanceTable";
import { BLOCKCHAIN, ResponseApi } from "../models/models";
import { logout, selectCollapsed, selectJWT, selectWallet } from '../features/user/user';
import AnalyticsService from '../services/analytics.service';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../app/hooks';
import './../App.css'
import WalletsData from "../components/walletsData";
import PaymentMethod from "../components/PaymentMethod";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { useLocation } from "react-router-dom";



function Inquiry() {


    const [apiResponse, setApiResponse] = useState<any>();
    const [loading, setLoading] = useState<Boolean>(false);
    const [showModal, setShowModal] = useState(false);
    const [inquiryData, setInquiryData] = useState<ResponseApi>();
    const [modalPayment, setModalPayment] = useState<boolean>(false);
    const jwt = useSelector(selectJWT);
    const wallet = useSelector(selectWallet);
    const dispatch = useAppDispatch();
    const [inquiryId, setInquiryId] = useState<string>();
    const [correctPayment, setCorrectPayment] = useState<boolean>(false);
    const { t } = useTranslation('common');
	const collapsed = useSelector(selectCollapsed);
    const [inquiryIdRecover, setInquiryIdRecover] = useState<string>();
    const location = useLocation();


    useEffect(() => {

        const queryParams = new URLSearchParams(location.search);
        const inqId = queryParams.get('inquiryId') ? queryParams.get('inquiryId') : '';

        if(inqId) setInquiryIdRecover(inqId);

        //console.log("WALLET : ", wallet);
        try {
            (window as any).ethereum.on('accountsChanged', function (accounts: any) {
                if (wallet !== '' && window.location.pathname !== '/login' && wallet !== accounts[0]) {
                    AnalyticsService.click("Change Account Medamask");
                    dispatch(logout());
                    //console.log("LOGOUT");
                    // navigate('/');
                }
            })
        } catch (error) {
            console.error(error);
        }


    }, [wallet, jwt, dispatch]);

    const iconBlockchain = (blockchain?: BLOCKCHAIN) => {
        return blockchain === BLOCKCHAIN.ETHEREUM ? './assets/img/blockchains/ethereum.png' :
            blockchain === BLOCKCHAIN.POLYGON ? './assets/img/blockchains/polygon.png' : './assets/img/blockchains/binance.png'
    }

    return (
        <>
            <div id="dashboard" className={collapsed ? " d-grid align-content-start vh-100 pt-4 px-4 pb-1 overflow-auto main-content-collapsed" : " d-grid align-content-start vh-100 pt-4 px-4 pb-1 overflow-auto main-content-expanded"  }>
                <WalletsData setInquiryId={setInquiryId} />
                {!inquiryId && (<FormBalance setApiResponse={setApiResponse} setLoading={setLoading} setInquiryId={setInquiryId} wallet={wallet} setInquiryData={setInquiryData} setModalPayment={setModalPayment} inquiryIdRecover={inquiryIdRecover} />)}
                {!loading && inquiryId && (
                    <>
                        <BalanceTable inquiryId={inquiryId} iconBlockchain={iconBlockchain} />
                    </>
                )}
            </div>
            <Modal
                onHide={() => { setShowModal(false) }}
                show={showModal}
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>{t('views.inquiry.information')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>{t('views.inquiry.noTokens')}<strong>{apiResponse?.block}</strong></p>
                    <p>{t('views.inquiry.nativeBalance')}{apiResponse?.cryptoBalance.toFixed(2)}</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setShowModal(false) }}>{t('views.inquiry.close')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                onHide={() => { setModalPayment(false) }}
                show={modalPayment}
                size="lg"
            >
                <Modal.Body>
                    <PaymentMethod paymentData={inquiryData?.paymentData} inquiryId={inquiryData?.inquiry?._id} credits={inquiryData?.inquiry?.credits ? inquiryData?.inquiry?.credits : 0} setInquiryId={setInquiryId} setModalPayment={setModalPayment} setCorrectPayment={setCorrectPayment} isPayingInquiry={false}/>
                </Modal.Body>
            </Modal>
            <Modal
                onHide={() => { setCorrectPayment(false); setLoading(false); }}
                show={correctPayment}
                size="sm"
                centered
            >
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="pagoRealizadoModalLabel">{t('views.inquiry.realizedPayment')}</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body mb-3 me-4">
                    {t('views.inquiry.donePayment')}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={() => { setCorrectPayment(false); setLoading(false); }}>{t('views.inquiry.close')}</button>
                </div>
            </Modal>
        </>
    );

}

export default Inquiry;