import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { selectJWT, setError, setNewCertificate, setSuccess } from "../features/user/user";
import { Certificate, CertificateResponse } from "../models/models";
import BalanceService from "../services/balance.service";
import { useAppDispatch } from "../app/hooks";
import PaymentMethod from "./PaymentMethod";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

type CertificateProps = {
    inquiry?: string;
    inquiryRegenerate?: string;
    setGenerated : any
}


function CertificateGenerator({ setGenerated, inquiry, inquiryRegenerate }: CertificateProps) {

    const jwt = useSelector(selectJWT);
    const [inquiryId, setInquiryId] = useState<any>(inquiry);
    const [loadingCertificate, setLoadingCertificate] = useState<boolean>(false);
    const [certificateData, setCertificateData] = useState<Certificate>();
    const [modalPayment, setModalPayment] = useState<boolean>(false);
    const [correctPayment, setCorrectPayment] = useState<boolean>(false);
    const [certificate, setCertificate] = useState<Certificate>();
    const [generateCertificate, setGenerateCertificate] = useState<boolean>(true);
    const { t } = useTranslation('common');

    const dispatch = useAppDispatch();

    const getCertificate = async (inquiryId: string) => {

        try {
            setGenerateCertificate(false);
            setLoadingCertificate(true);
            const resp: CertificateResponse = await BalanceService.certificate(inquiryId, jwt);
            setLoadingCertificate(false);

            if (resp?.paid === false) {
                setCertificateData(resp.certificate);
                setModalPayment(true);
                return;
            }
            if (resp?.certificate.consumed) {
                setCorrectPayment(false);
                setModalPayment(false);
                setCertificate(resp.certificate);
                dispatch(setNewCertificate(resp.certificate));
                try {
                    setGenerated(resp.certificate);
                } catch (error) {
                    
                }
                return;
            }
            dispatch(setError({ title: "Certificate error", desc: "Error trying generate your certificate" }));
        } catch (error: any) {
            dispatch(setError({ title: "Certificate error", desc: error?.response?.data?.error?.message ? error.response.data.error.message : "Error trying generate your certificate" }));
        }
    }

    const refreshCertificate = async (inquiryId: string) => {

        try {
            setGenerateCertificate(false);
            setLoadingCertificate(true);
            const resp: CertificateResponse = await BalanceService.refreshCertificate(inquiryId, jwt);
            console.log("resp,", resp)
            setLoadingCertificate(false);

            if (resp?.certificate.regenerated) {
                setCorrectPayment(false);
                setModalPayment(false);
                setCertificate(resp.certificate);
                dispatch(setNewCertificate(resp.certificate));
                // dispatch(setSuccess({ title: "Certificado", desc: "Certificado regenerado correctamente" }));
                try {
                    setGenerated(resp.certificate);
                } catch (error) {
                    
                }
                return;
            }
            else{

                dispatch(setError({ title: "Certificate error", desc: "Error trying generate your certificate" }));
            }
        } catch (error: any) {
            dispatch(setError({ title: "Certificate error", desc: error?.response?.data?.error?.message ? error.response.data.error.message : "Error trying generate your certificate" }));
        }
    }

    return (
        <>

            <Modal
                onHide={() => setGenerateCertificate(false)}
                show={generateCertificate}
                size="lg"
            >
                <Modal.Body>
                    <button type="button" className="btn-close position-absolute top-0 end-0 me-3 mt-3"
                        onClick={() => setGenerateCertificate(false)} data-bs-dismiss="modal" aria-label="Close"></button>
                    <div className="container">
                        <div className="p-3 my-4 text-center">
                            <div className="mb-4">
                                <i className="display-5 text-info-ranking bi bi-file-earmark-check"></i>
                            </div>

                            {inquiry && (
                                <>
                                    <h1 className="h2">{t('components.certificate.export')}</h1>
                                    <h3 className="h4">{t('components.certificate.consumeCredit')}</h3>
                                    <p className="lead">{t('components.certificate.transactionCertificate')}</p>
                                </>
                            )}
                            {inquiryRegenerate && (<h3 className="h4">{t('components.certificate.refresh')}</h3>)}
                            
                            <p>{t('components.certificate.verifiableProof')}</p>
                            {false ? (<div className="alert alert-danger text-start" role="alert">
                                {t('components.certificate.incompleteDate')}
                            </div>)
                                : (<><br /><br /></>)}

                            <div className="form-check">
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <input className="form-check-input" type="checkbox" value="" id="legalDosCheck" required checked onChange={() => { }} />
                                    </div>
                                    <div className="col-auto m-0 p-0">
                                        <label className="form-check-label" id="legalDosCheck">
                                            {t('components.certificate.acceptPolicies')}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {inquiry && (<div className="mt-4 d-grid gap-3 text-start">
                                <div className="row justify-content-center">
                                    <div className="col d-grid">
                                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                                            onClick={() => getCertificate(inquiryId)}>
                                            {t('components.certificate.request')}
                                        </button>
                                    </div>
                                </div>
                            </div>)}
                            {inquiryRegenerate && (
                                <div className="row justify-content-center">
                                <div className="col-4 d-grid">
                                    <br/>
                                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                                        onClick={() => refreshCertificate(inquiryRegenerate)}>
                                        {t('components.certificate.buttonRefresh')}
                                    </button>
                                </div>
                            </div>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                onHide={() => { setModalPayment(false) }}
                show={modalPayment}
                size="lg"
            >
                <Modal.Body>
                    <PaymentMethod certificateId={certificateData?._id} credits={certificateData ? certificateData?.credits : 0} setInquiryId={setInquiryId} setModalPayment={setModalPayment} setCorrectPayment={() => { setCorrectPayment(true); getCertificate(inquiryId) }} isPayingInquiry={false} />
                </Modal.Body>
            </Modal>
            <Modal
                onHide={() => setLoadingCertificate(false)}
                show={loadingCertificate}
                size="sm"
            >
                <Modal.Header>
                    <Modal.Title>{t('components.certificate.generatingCertificate')}</Modal.Title>
                    <button type="button" className="btn-close position-absolute top-0 end-0 me-3 mt-3" onClick={() => { setLoadingCertificate(false) }} aria-label="Close"></button>
                </Modal.Header>

                <Modal.Body className="row justify-content-center">
                    <Spinner />
                </Modal.Body>
            </Modal>
            <Modal
                onHide={() => { setCorrectPayment(false); }}
                show={correctPayment}
                size="sm"
                centered
            >
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="pagoRealizadoModalLabel">{t('components.certificate.paymentDone')}</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body mb-3 me-4">
                    {t('components.certificate.correctPayment')}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={() => { setCorrectPayment(false); }}>{t('components.certificate.closeButton')}</button>
                </div>
            </Modal>
            <Modal
                onHide={() => setCertificate(undefined)}
                show={certificate !== undefined}
                size="sm"
                centered
            >
                <Modal.Header>
                    <Modal.Title>{t('components.certificate.downloadCertificate')}</Modal.Title>
                    <button type="button" className="btn-close position-absolute top-0 end-0 me-3 mt-3" onClick={() => { setCertificate(undefined) }} aria-label="Close"></button>
                </Modal.Header>
                <div className="container">
                    <div className="p-3 my-4 text-center">
                        <div className="p-3 my-4 text-center">
                            <div className="mb-4">
                                <i className="display-5 text-info-ranking bi bi-check2-square"></i>
                            </div>
                            <h1 className="h2">{t('components.certificate.correctGeneration')}</h1>
                        </div>
                        <br />
                        {certificate && (<button type="button" className="btn btn-primary"
                            onClick={() => { window.open(certificate.fileUnsigned, '_blank'); }}>{t('components.certificate.downloadButton')}</button>)}
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default CertificateGenerator;