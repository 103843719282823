import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Balance, BLOCKCHAIN, Certificate, Inquiry, Token, TRUST_LEVEL_TOKEN } from "../models/models";
import { addCreditsAvailable, selectHistoric, selectUserInfo, setError, setInquiryConfirmed, selectCurrencySymbol, selectCurrency, CURRENCY, setSuccess, setInquiryUpdated } from "../features/user/user";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { downloadExcel } from 'react-export-table-to-excel';
import { selectJWT } from "../features/user/user";
import './../App.css'
import { JsonViewer } from "@textea/json-viewer";
import { useAppDispatch } from "../app/hooks";
import AddAssetForm from "./AddAssetForm";
import Spinner from "./Spinner";
import BalanceLoader from "../loaders/BalanceLoader";
import Filters from "./Filters";
import BalanceService from "../services/balance.service";
import TypeTokenRow from "./TypeTokenRow";
import CertificateGenerator from "./Certificate";
import { useTranslation } from "react-i18next";

export type BalanceTableProps = {
    inquiryId: string;
    iconBlockchain: any
    historic?: boolean;
    setShow?: any,
}

const bcs = ['Ethereum', 'Polygon', 'BSC'];
const trusts = ['No definido', 'Pendiente', 'Sospechoso', 'Scam', 'Válido'];


function BalanceTable({ inquiryId, historic, setShow, iconBlockchain }: BalanceTableProps) {

    const [showModal, setShowModal] = useState<boolean>(false);
    const [infoSelected, setInfoSelected] = useState<number>(0);
    const userInfo = useSelector(selectUserInfo);
    const inquiries = useSelector(selectHistoric);
    const tableRef = useRef(null);
    const jwt = useSelector(selectJWT);
    const currency = useSelector(selectCurrency);
    const currencySymbol = useSelector(selectCurrencySymbol);
    const [tokens, setTokens] = useState<Token[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showAddToken, setShowAddToken] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const [inquiryData, setInquiryData] = useState<Inquiry>();
    const [balances, setBalances] = useState<Balance[]>();
    const [filtered, setFiltered] = useState<Balance[]>([]);
    const [inquiryIdCertificate, setInqIdCertificate] = useState<string>();
    const [inquiryRegenerate, setInquiryRegenerate] = useState<string>();
    const { t } = useTranslation('common');
    const [enableAllFilters, setEnableAllFilters] = useState(false);
    const [generated, setGenerated ] = useState<Certificate>();

    const getNumberParsed = ( num? : number) => {
        return num?.toLocaleString('es-ES', {
            minimumFractionDigits: 6,
            maximumFractionDigits: 6
        })
    }

    const parseTokens = () => {
        return tokens.map( tok => { return {...tok, 
            blockchain : tok.blockchain ? bcs[tok.blockchain] : '',
            trustLevel : tok.trustLevel ? trusts[tok.trustLevel] : '',
            balance : getNumberParsed(tok.balance) , 
            eur : getNumberParsed(Number(tok.eur ? tok.eur : "0")),
            usd : getNumberParsed(Number(tok.usd ? tok.usd : "0")), 
            balanceFiat :  getNumberParsed(tok.usd ? tok.usd * tok.balance : 0), 
            balanceFiatEur :  getNumberParsed(tok.eur ? tok.eur * tok.balance : 0)  
        }} );
    }

    const handleDownloadExcel = async (event: React.SyntheticEvent) => {

        event.preventDefault();
        const header = ["Blockchain", "Symbol", "Name", "address", "Balance","eur", "usd", "balanceFiat", "balanceFiatEur", "trustLevel"];
        const toks : any[] = tokens.map( tok => { return {
                Blockchain : tok.blockchain !== undefined ? bcs[tok.blockchain] : 'Ethereum',
                Symbol : tok.symbol,
                Name : tok.name, 
                address : tok.address,
                Balance : getNumberParsed(tok.balance) , 
                eur : getNumberParsed(Number(tok.eur ? tok.eur : "0")),
                usd : getNumberParsed(Number(tok.usd ? tok.usd : "0")), 
                balanceFiat :  getNumberParsed(tok.usd ? tok.usd * tok.balance : 0), 
                balanceFiatEur :  getNumberParsed(tok.eur ? tok.eur * tok.balance : 0),
                trustLevel : tok.trustLevel !== undefined ? trusts[tok.trustLevel] : ''
            }} 
        );
        downloadExcel({
            fileName: 'consulta-' + (inquiryData?.alias ? inquiryData?.alias : inquiryData?.configuration.date),
            sheet: "react-export-table-to-excel",
            tablePayload: {
                header,
                // accept two different data structures
                body: toks
            },
        });
    }

    const getUrl = (token: Token) => {

        return token.address ?
            token.blockchain === BLOCKCHAIN.ETHEREUM ? 'https://etherscan.io/address/' + token.address :
                token.blockchain === BLOCKCHAIN.POLYGON ? 'https://polygonscan.com/address/' + token.address :
                    'https://bscscan.com/address/' + token.address : '';

    }

    const getNewCertificate = (certificate : Certificate) => {
        // console.log("getNewCertificate : ", certificate);
        if(certificate && inquiryData){
            inquiryData.certificate = certificate;
            setInquiryData(inquiryData);
        }
    }

    const regenerateInq = async () => {

        try {
            setLoading(true);
            const response  = await BalanceService.regenerateBalance({inquiryId}, jwt);
            const balanceList: Balance[] = response.balances;
            let tokensTotal: any[] = [];
            const tokensOld = [...tokens];


            balanceList.forEach((balance: Balance) => {
                const tokensEdited: any[] = [];
                if (balance !== undefined && balance !== null) {
                    balance?.tokens.forEach( (tok) => {
                        const tokOld = tokensOld.find( t => t._id?.toString() === tok._id?.toString());
                        tokensEdited.push({...tok, margin : tokOld?.margin, usd : tokOld?.usd, eur : tokOld?.eur});
                    })
                    }
                tokensTotal = tokensTotal.concat(tokensEdited);
            })
            setBalances(balanceList);
            setTokens(tokensTotal); // TODO controlar nuevos trustlevel 
            dispatch(setSuccess({ title: "Balance regenerado correctamente", desc: 'Se han actualizado los tokens con nuevos valores de confianza' }));
            dispatch(setInquiryUpdated({id : inquiryId,updated : response.updatedAt, balances : response.balances }))
            setLoading(false);
            return;
        } catch (error) {
            dispatch(setError({ title: "Inquiry without tokens", desc: 'Error trying regenerate inquiry' }));
            return;
        }

        

    }



    useEffect(() => {

        const callBalance = async (body: any) => {

            try {
                const response = await BalanceService.balance(body, jwt);
                const balanceList: Balance[] = response.balances;
                let tokensTotal: any[] = [];
                if (balanceList.every((bal: Balance) => bal === undefined || bal === null)) {
                    setLoading(false);
                    if (setShow) setShow(false);
                    // dispatch(setError({ title: "Inquiry without tokens", desc: "Your inquiry doesn't have any tokens or ocurred some error" }));
                }
                balanceList.forEach((balance: Balance) => {
                    if (balance !== undefined && balance !== null) {
                        tokensTotal = tokensTotal.concat(balance?.tokens);
                    }
                })

                if (tokensTotal.length === 0) {
                    setTimeout(() => {
                        setLoading(false);
                        if (setShow) setShow(false);
                        // dispatch(setError({ title: "Inquiry without tokens", desc: "Your inquiry doesn't have any tokens or ocurred some error" }));
                    }, 2000);
                    return;
                }
                else {
                    setBalances(balanceList);
                    setLoading(false);
                    setTokens(tokensTotal);
                    dispatch(setInquiryConfirmed(inquiryId));
                    // console.log('Credits available BalanceTable Before: ', addCreditsAvailable(response.total));

                    // if (response.credistRemaining) dispatch(addCreditsAvailable(response.credistRemaining));

                    // console.log('Credits available BalanceTable After: ', addCreditsAvailable(response.total));
                    const valuesTokens = tokensTotal.filter(token => token.trustLevel === TRUST_LEVEL_TOKEN.VALID);
                    const dateSelected = inquiries.find((inq: Inquiry) => inq._id === inquiryId)?.configuration.dateSelected;
                    // console.log("Tokens values", valuesTokens, 'Date: ', new Date(dateSelected ? dateSelected : 0).getTime());

                    try {
                        const time = new Date(dateSelected ? dateSelected : 0).getTime() / 1000;
                        const respQuotes = await BalanceService.quotes({
                            inquiryId,
                            time,
                            tokens: Array.from(new Set(valuesTokens.map((token: Token) => { return token.symbol }))).toString(),
                            names: Array.from(new Set(valuesTokens.map((token: Token) => { return token.name }))).toString()
                        }, jwt);

                        // console.log("Resp : ", respQuotes);

                        if (respQuotes) {
                            tokensTotal.forEach((token: Token) => {
                                const bal: any = token.trustLevel === TRUST_LEVEL_TOKEN.VALID ? respQuotes.find((quote: any) => quote.token === token.symbol.toUpperCase()) : undefined;
                                if (bal) {
                                    // console.log('Bal found', bal);
                                    token.margin = bal.errorMargin;
                                    token.usd = bal.priceMid;
                                    token.eur = bal.priceMidEur;
                                }
                            })
                            setTokens(tokensTotal);
                        }


                    } catch (error: any) {
                        // const errDetail = error.response?.data?.error?.message ? error.response?.data?.error?.message : "There is an error when try recover fiat balances"
                        // dispatch(setError({ title: "Error trying recover fiat balances ", desc: errDetail }));
                        // setShow(false);
                        return;
                    }

                }

                return;
            } catch (error: any) {
                const errDetail = error.response?.data?.error?.message ? error.response?.data?.error?.message : "Your inquiry doesn't have any tokens or ocurred some error"
                if(error.code !== "ERR_BAD_REQUEST") dispatch(setError({ title: "Inquiry without tokens", desc: errDetail }));
                // setShow(false);
                return;
            }

        }

        const filteredBalances = (filteredBalances: Balance[]) => {
            let tokensTotal: Token[] = [];
            filteredBalances.forEach((balance: Balance) => {
                tokensTotal = tokensTotal.concat(balance?.tokens);
            })
            // console.log("FILTERED: " , tokensTotal.length);
            setTokens(tokensTotal);
            // if(tokensTotal.length !== 0){  
            // }
        }

        if (!balances) {
            callBalance({ inquiryId, confirmed: true });
            if (inquiries?.length > 0) {
                setInquiryData(inquiries.find((inq: Inquiry) => inq._id === inquiryId))
            }
        }
        if (filtered) {
            filteredBalances(filtered);
        }

       

        // console.log('INQUIRY DATA: ', inquiryData);
        // El fallo puede estar en cómo se está almacenando la wallet del usuario 



    }, [inquiryId,filtered]);

    return (
        <>
            <div className="row px-1 py-2 rounded-top-4 text-light-emphasis bg-light-subtle">
                <div className="row row-gap-3 py-3 text-start">
                    <div className="col-6">
                        <h3 className="h6">{t('components.balanceTable.wallet')}</h3>
                        {Array.isArray(inquiryData?.configuration.wallets) ? (inquiryData?.configuration.wallets.map((wallet, index) => (<p key={index} className="text-muted">{wallet}</p>))) : (<p className="text-muted">{inquiryData?.configuration.wallets}</p>)}
                    </div>
                    <div className="col-6">
                        <h3 className="h6">{t('components.balanceTable.consultedDate')}</h3>
                        <p className="text-muted">{inquiryData?.configuration.dateSelected}</p>
                    </div>
                    <div className="col-6">
                        {inquiryData?.configuration.blockchains.map((bc: BLOCKCHAIN, index: number) => (
                            <span key={index} className="btn-image-md m-2"><img src={iconBlockchain(bc)} alt="Imagen" />
                            </span>
                        ))}
                    </div>
                    <div className="col-6">
                        <h3 className="h6">{t('components.balanceTable.consumedCredits')}</h3>
                        <p className="text-muted">{inquiryData?.credits}</p>
                    </div>
                </div>
            </div>
            <Filters balances={balances} iconBlockchain={iconBlockchain} setFiltered={setFiltered} enableAllFilters={enableAllFilters} setEnableAllFilters={setEnableAllFilters} />
            <form className="m-4 list-group-radio">
                <div className="row row-gap-3">
                    <div className="col-auto">
                        <button className="btn btn-primary btn-sm px-3 me-1 text-nowrap" aria-current="page" onClick={(event) => { event.preventDefault(); setShowAddToken(true) }}>
                            <i className="bi bi-plus-square "></i><span className="ms-3">{t('components.balanceTable.addToken')}</span>
                        </button>
                        <button className="btn btn-primary btn-sm px-3 me-1 text-nowrap" aria-current="page" onClick={(event) => { event.preventDefault(); regenerateInq() }}>
                            <i className="bi bi-arrow-clockwise "></i><span className="ms-3">{t('components.balanceTable.regenerateInq')}</span>
                        </button>
                    </div>
                    <div className="col-auto ms-auto">
                        <div className="text-nowrap">
                            <button className="btn btn-primary btn-sm px-3 me-1 text-nowrap" aria-current="page">
                                <i className="bi bi-save "></i><span className="ms-3">
                                    <CSVLink data={parseTokens()} separator={";"}>{t('components.balanceTable.csvExport')}</CSVLink>
                                </span>
                            </button>
                            <button className="btn btn-primary btn-sm px-3 me-1 text-nowrap" aria-current="page" onClick={handleDownloadExcel}>
                                <i className="bi bi-save "></i><span className="ms-3">{t('components.balanceTable.excelExport')}</span>
                            </button>
                            {inquiryData?.certificate  ? (
                                <>
                                <button type="button" onClick={() => window.open(inquiryData.certificate?.fileSigned ? inquiryData.certificate?.fileSigned : inquiryData.certificate?.fileUnsigned, '_blank')}
                                    className="btn btn-primary border-start-none text-start">{inquiryData.certificate?.fileSigned ? t('components.balanceTable.viewSignedCertificate') : t('components.balanceTable.viewCertificate')}</button>
                                &nbsp;<button type="button" onClick={() => {setInquiryRegenerate(inquiryId)}}
                                className="btn btn-primary border-start-none text-start">{t('components.balanceTable.refreshCertificate')}</button>
                                </>
                            ) :
                                (<button type="button" onClick={() => { setInqIdCertificate(inquiryId) }} disabled={inquiryData?.certificate?.consumed}
                                    className="btn btn-primary border-start-none text-start">{t('components.balanceTable.requestCertificate')}</button>)}
                        </div>
                    </div>
                </div>
            </form>
            {loading && (
                <>
                    <div className='mt-5 mb-5'>
                        <Spinner />
                        <BalanceLoader />
                    </div>
                </>
            )}
            {tokens.length > 0 ? (
                <>
                    <div className="row rounded-bottom-4 table-responsive z-0 px-1 text-light-emphasis bg-light-subtle">
                        <table className="table table-striped table-hover table-borderless align-middle" ref={tableRef}>
                            <thead className="sticky-top">
                                <tr className="text-light-emphasis">
                                    <th scope="col">{t('components.balanceTable.blockchain')}</th>
                                    <th scope="col">{t('components.balanceTable.token')}</th>
                                    <th scope="col">{t('components.balanceTable.contractAddress')}</th>
                                    <th scope="col" className="balances-amounts">{t('components.balanceTable.balance')}</th>
                                    {/* <th scope="col">Is main Token</th> */}
                                    {/* <th scope="col">Multichain</th> */}
                                    <th scope="col" className="balances-amounts">{t('components.balanceTable.fiatValue')}</th>
                                    <th scope="col">{t('components.balanceTable.tokenType')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tokens.map((token: Token, index: number) => (
                                    <tr key={index}>
                                        <td className="text-nowrap">
                                            {/* <p>{index}</p> */}
                                            <span className="btn-image-md"><img src={iconBlockchain(token?.blockchain)} alt="Imagen" /><span className="ms-2"></span></span>
                                        </td>
                                        {token?.img && (
                                            <td className="text-nowrap" style={{ maxWidth: '100px' }}>
                                                <span className="btn-image-md"><img className="col-6 " src={token?.img} alt="token-img" /></span>
                                                &nbsp; {token.symbol?.substring(0, 5)} {token.symbol.length > 5 ? '...' : ''}
                                            </td>)}
                                        {!token?.img && (<td className="text-nowrap" style={{ maxWidth: '100px' }}> {token.symbol?.substring(0, 5)} {token.symbol.length > 5 ? '...' : ''}</td>)}
                                        {/* <td className="text-nowrap">{token.symbol}</td>
                                    <td className="text-nowrap">{token.name}</td> */}
                                        <td className="text-nowrap">
                                            {token.address && token.address.length > 20
                                                ? `${token.address?.slice(0, 6)}. . .${token.address?.slice(-4)}`
                                                : 'Native Asset'} &nbsp;&nbsp;
                                            {token.address && (<i className="bi bi-box-arrow-up-right pointer" onClick={() => window.open(getUrl(token), '_blank')}></i>)}&nbsp;&nbsp;
                                            {token.address && (<i className="bi bi-clipboard-check-fill pointer" onClick={() => navigator.clipboard.writeText(getUrl(token))}></i>)}
                                        </td>
                                        <td className="text-nowrap balances-amounts">{token.balance.toLocaleString('es-ES', {
                                            minimumFractionDigits: 6,
                                            maximumFractionDigits: 6
                                        })}</td>
                                        {/* <td className="text-nowrap">{token.isParentToken ? 'SI' : 'NO'}</td> */}
                                        {/* {token.parentTokenData && (<td className="text-nowrap text-center"> */}
                                        {/* <Button onClick={() => { setInfoSelected(index); setShowModal(true) }}>+ Info</Button>&nbsp;&nbsp;  */}
                                        {/* {token.parentToken && (<i className="bi bi-box-arrow-up-right pointer" onClick={ () => {setInfoSelected(index); window.open(getUrl(tokens[infoSelected].parentTokenData),'_blank')}}></i>)} */}
                                        {/* </td>)} */}
                                        {/* {!token.parentTokenData && (<td className="text-nowrap"></td>)} */}
                                        {currency === CURRENCY.USD && token.usd && (<td className="text-nowrap balances-amounts">{(token.usd > 0 ? token.usd * token.balance : 0).toLocaleString('es-ES', {
                                            minimumFractionDigits: 6,
                                            maximumFractionDigits: 6
                                        })} {currencySymbol}</td>)}
                                        {currency === CURRENCY.EUR && token.eur && (<td className="text-nowrap balances-amounts">{(token.eur > 0 ? token.eur * token.balance : 0).toLocaleString('es-ES', {
                                            minimumFractionDigits: 6,
                                            maximumFractionDigits: 6
                                        })} {currencySymbol}</td>)}
                                        {token.trustLevel === TRUST_LEVEL_TOKEN.VALID && token.usd === undefined && (<td className="text-nowrap balances-amounts"> {t('components.balanceTable.loading')}</td>)}
                                        {!token.usd && token.trustLevel !== TRUST_LEVEL_TOKEN.VALID && (<td className="text-nowrap balances-amounts"> --- </td>)}
                                        {/* <td className="text-nowrap">
                                        {token._id && userInfo.userType === USER_TYPE.ADMIN && (
                                            <Button onClick={() => { setIdToken(token?._id ? token?._id : ''); setShowTokenModal(true) }}>Edit</Button>
                                        )}
                                        &nbsp; &nbsp; {trustLevels[token.trustLevel ? token.trustLevel : 0]} 
                                    </td> */}
                                        <TypeTokenRow id={token._id} showEdit={false} trustLevel={token.trustLevel} userType={userInfo.userType}></TypeTokenRow>
                                    </tr>
                                ))}
                                {/* <tr>
                        <th scope="row" className="text-center fw-light px-2">1</th>
                        <td className="text-nowrap"><span className="btn-image-md"><img src="./assets/img/imagen_05.png" alt="Imagen" /><span className="ms-2">Binance-Peg BUSD Token</span></span></td>
                        <td className="text-nowrap">Oxd35f9ab96d04adb02fd549ef6a576ce4e2c1d935</td>
                        <td className="text-nowrap">0.0000003123</td>
                        <td className="">
                            <div className="d-grid">
                                <div className="btn-group" role="group" aria-label="Generar Certificado">
                                    <button type="button" className="btn btn-outline-primary pe-none border-end-none"><i className="bi bi-info-circle"></i></button>
                                    <button type="button" className="btn btn-primary border-start-none text-start">Más información</button>
                                </div>
                            </div>
                        </td>
                    </tr> */}
                            </tbody>
                        </table>
                    </div>
                    <Modal
                        onHide={() => { setShowModal(false) }}
                        show={showModal}
                        size="xl"
                    >
                        <Modal.Header>
                            <Modal.Title>{t('components.balanceTable.parentInfo')}</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <JsonViewer value={tokens[infoSelected].parentTokenData} />
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => { setShowModal(false) }}>{t('components.balanceTable.closeButton')}</Button>
                        </Modal.Footer>
                    </Modal>
                </>
            ) :
                <div className="my-5 text-center">
                    <h4 className="h6">{t('views.historicView.noInquiries')}</h4>
                    {/* <p className="text-muted">Don't see a token?</p> */}
                    <button onClick={() => setEnableAllFilters(true)} className="btn btn-primary px-3 text-nowrap" aria-current="page">
                        <i className="bi bi-arrow-repeat"></i><span className="ms-3">{t('views.historicView.selectAllFilters')}</span>
                    </button>
                </div>}



            <Modal
                onHide={() => { setShowAddToken(false) }}
                show={showAddToken}
                size="lg"
            >
                <AddAssetForm setShowAddToken={setShowAddToken} />
            </Modal>
            {inquiryIdCertificate && (
                <CertificateGenerator inquiry={inquiryIdCertificate} setGenerated={setGenerated}/>
                
            )}
            {inquiryRegenerate && (
                <CertificateGenerator inquiryRegenerate={inquiryRegenerate} setGenerated={getNewCertificate}/>
                
            )}
        </>
    );
}

export default BalanceTable;