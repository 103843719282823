import { UserInfo } from "../features/user/user"

export type Token = {
    name : string,
    symbol : string, 
    balance: number,
    usd?: number,
    eur?: number,
    margin?: number,
    address? : string,
    blockchain? : BLOCKCHAIN,
    timesConsulted? : number, 
    isParentToken?: boolean,
    trustLevel? : TRUST_LEVEL_TOKEN,
    _id? : string,
    parentToken?: string,
    img? : string, 
    urlScan?: string,
    parentTokenData?: any,
    isContract?: boolean,
    balanceFiat : number,
    balanceFiatEur : number,
    priceEur: number,
    price : number,
    wallet? : string, 
    versions? : Token[] | any[];
    edited?: boolean;
    updatedAt? : Date;
}

export type ModifyToken = {
    _id : string,
    trustLevel? : TRUST_LEVEL_TOKEN
}

export enum TRUST_LEVEL_TOKEN {
    NOT_DEFINED, PENDING, SUSPICIOUS, SCAM, VALID
}

export enum BLOCKCHAIN {
    ETHEREUM,
    POLYGON,
    BSC
}

export enum INQUIRY_TYPE {
    BALANCE,
    CERTIFICATE
}

export type Balance = {
    cryptoBalance : number, 
    block : number,
    tokens : Token[],
    blockchain : number
}

export type CurrentBalance = {
    balance : Balance[],
    wallet : string
}

export type PaymentData = {
    client_secret : string,
    id : string
    amount? : number,
}

export type BlocksData = {
    blockchain : string;
    block : number;
}

export type Plan = {
    _id?: string,
    name: string,
    publicName: string,
    description : string,
    price : number,
    credits : number,
    status : STATUS_PLAN,
    img?: {
        type: String
    }
}

export enum STATUS_PLAN {
    ACTIVE,
    INACTIVE
}



export type BalenceConfig = {
    blockchains: Array<BLOCKCHAIN>,
    dateSelected: string, 
    date: string,
    timeZone: string,
    wallets: string[],
    blocks : Array<BlocksData>
};

export type CertificateResponse = {
    certificate : Certificate,
    paid?: boolean
}

export type Certificate = {
    _id : string,
    inquiryId: string,
    userId: string,
    credits:  number,
    consumed: boolean,
    regenerated: boolean,
    applicationDate: string,
    orderId? : string,
    signDate? : string,
    downloadDate?: string,
    fileUnsigned : string,
    fileSigned? : string,
};

export type Inquiry = {
    createdAt : Date,
    updatedAt : Date,
    credits : number,
    _id : string
    confirmed : boolean,
    inquiryType : INQUIRY_TYPE, 
    configuration : BalenceConfig,
    consumed? : boolean,
    userId : string,
    certificate? : Certificate,
    balanceResponse? : Balance[],
    deleted? : boolean,
    alias ? : string,
}

export enum PAYMENT_TYPE {
    PENDING,
    CARD,
    CRYPTO
}

export enum PAYMENT_TYPE_API {
    CARD,
    CRYPTO
}

export type CryptoPaymentData = {
    redirectUrl : string,
    uuid : string,
    error?: string
}

export type PayPlanBody = {
    paymentType : PAYMENT_TYPE_API,
    packId : string,
    userData?: any,
}

export type PayInquiryBody = {
    paymentType : PAYMENT_TYPE_API,
    inquiryId? : string
    certificateId? : string
    userData?: any,
}

export type ResponseApi = {
    paymentData? : PaymentData,
    inquiry? : Inquiry,
    cryptoPaymentData? : CryptoPaymentData,
    paid? : boolean
}

export type ResponseUpdateTokenApi = {
    updated : boolean
}


export type SignUpResponse = {
    nonce : string, 
    wallet : string,
}

export type SignInResponse = {
    accessToken : string,
    userInfo : UserInfo,
    error? : string,
    message?: string,
    statusCode?: number
}